import { useState } from "react";
import { useSVGPolylineType } from "../Commons/types";
import { SVGPoints } from "../Commons/types";
import produce from "immer";

const useSVGPolyline = (): useSVGPolylineType => {
    let [points, setPoints] = useState<SVGPoints>([]);

    function updateLastSVGPoint(x: number, y: number) {
        setPoints(
            produce((draft) => {
                draft.pop();
                draft.push({ x, y });
            })
        );
    }

    function insertNewSVGPoint(x: number, y: number) {
        setPoints(
            produce((draft) => {
                draft.push({ x, y });
            })
        );
    }

    function deleteLastSVGPoint() {
        setPoints(
            produce((draft) => {
                draft.pop();
            })
        );
    }

    function resetSVGPoint() {
        setPoints([]);
    }

    return {
        updateLastSVGPoint,
        insertNewSVGPoint,
        resetSVGPoint,
        deleteLastSVGPoint,
        points,
    };
};
export default useSVGPolyline;
