import { Howl } from "howler";
const soundFile = require("../Assets/Sound.mp3");

export enum SoundType {
    BINGO = "bingo",
    CLICKBTN = "clickBtn",
    DROPBTNSOUND = "dropBtnsound",
    ERROR = "error",
    GAMESTARTENDSOUND = "gamestartendsound",
    INVALIDWORD = "invalid_word",
    MESSAGE = "message",
    OPPSUBMITS = "opp_submits",
    RECALL = "recall",
    SHUFFLE = "shuffle",
    USERSUBMITS = "user_submits",
    USERWINGAME = "user_wins_game",
}

class SoundManager {
    private sound: Howl | null = null;

    constructor() {
        this.onAppInit();
    }

    onAppInit = (): void => {
        this.createHowlObject();
    };

    createHowlObject() {
        this.sound = new Howl({
            src: [soundFile],
            sprite: {
                [SoundType.BINGO]: [0, 2977.9591836734694],
                [SoundType.CLICKBTN]: [3100, 78.36734693877557],
                [SoundType.DROPBTNSOUND]: [4200, 104.48979591836772],
                [SoundType.ERROR]: [5300.000000000001, 313.4693877551023],
                [SoundType.GAMESTARTENDSOUND]: [
                    6400.000000000001, 626.9387755102036,
                ],
                [SoundType.INVALIDWORD]: [
                    7500.000000000001, 417.95918367346906,
                ],
                [SoundType.MESSAGE]: [8600.000000000002, 261.224489795918],
                [SoundType.OPPSUBMITS]: [9700.000000000002, 1985.3061224489802],
                [SoundType.RECALL]: [11800.000000000002, 208.97959183673544],
                [SoundType.SHUFFLE]: [12900.000000000004, 156.73469387755114],
                [SoundType.USERSUBMITS]: [
                    14000.000000000004, 1567.3469387755094,
                ],
                [SoundType.USERWINGAME]: [
                    16100.000000000002, 2168.163265306124,
                ],
            },
        });
    }

    playSound = (soundname: SoundType) => {
        if (this.sound) {
            this.sound.stop();
            this.sound.play(soundname);
        }
    };
}

export const SndMngr = new SoundManager();
