import axios from "axios";
import { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import {
    titleWordMeaning,
    FETCH_ERR_MSG,
    pathIntro,
    WORDMEANING_ALL_DATA,
    cacheWordMeaningSeconds,
} from "../../Commons/constant";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { PopupUp } from "../../Component/PopUp/PopupUp";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import lclStorage from "../../Utils/LocalStorage";
let qs = require("qs");
declare const window: Window &
    typeof globalThis & {
        _daily_puzzle_game_configuration_: any;
    };
type StoredWord = {
    word: string;
    meaning: Array<{ description: string }>;
    tstmp: number;
};

export const WordMeaning = () => {
    const navigate = useNavigate();
    const [wordmeaning, setWordMeaning] = useState<Array<{ word: string; meaning: string }>>([]);
    const [showHelp, setShowHelp] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [showPopup, setShowPopUp] = useState(false);
    const [popMsg, setPopMsg] = useState("");
    useEffect(() => {
        doPostRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function doPostRequest() {
        try {
            //get all wordmeanings stored till now
            let promise1 = lclStorage.getItem(WORDMEANING_ALL_DATA);
            //get all words made till now
            let promise2 = dailyWordPuzzleDataAPI.getAllWordsPlayed();

            let dbdata = await Promise.all([promise1, promise2]);
            let storedwordmeanings: Array<StoredWord> = JSON.parse(dbdata?.[0] ?? "[]");
            let wordsmade: Array<string> = dbdata?.[1] ?? [];
            wordsmade = wordsmade.filter((wrd, index) => {
                return wordsmade.indexOf(wrd) === index;
            });
            let foundincache: Array<string> = wordsmade.filter((word: string) => {
                return storedwordmeanings.filter((ee: StoredWord) => {
                    return (ee.word === word);
                }).length > 0;
            });
            let notfoundincache: Array<string> = [];
            let fetchwordmeanings: string[] = [];
            if (storedwordmeanings && storedwordmeanings.length) {
                notfoundincache = wordsmade.filter((word: string) => {
                    return !foundincache.includes(word);
                });
                fetchwordmeanings = notfoundincache;
            } else {
                fetchwordmeanings = wordsmade;
            }
            if (fetchwordmeanings.length) {
                let data = qs.stringify({
                    json: JSON.stringify({ word: fetchwordmeanings, mobile: "y", meaning: "y", dic: "sow" }),
                });
                let config = {
                    method: "post",
                    url: window._daily_puzzle_game_configuration_.wordmeaning_url,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: data,
                };
                const wordmeaningrespdata = await axios(config);
                if (wordmeaningrespdata.status === 200 && wordmeaningrespdata.data) {
                    let tempData: any = await wordmeaningrespdata.data;
                    if (tempData) {
                        if (!Array.isArray(tempData)) {
                            let fetchedwords: string[] = Object.keys(tempData);
                            let tstmp: number = Math.floor(Date.now() / 1000);
                            let mm: Array<StoredWord> = fetchedwords.map((word) => {
                                let meaning: Array<{ description: string }> = tempData[word];
                                return {
                                    word,
                                    meaning,
                                    tstmp,
                                };
                            });
                            storedwordmeanings.forEach((ee) => {
                                if (foundincache.includes(ee.word)) {
                                    ee.tstmp = tstmp;
                                }
                            });
                            storedwordmeanings = storedwordmeanings.concat(mm)
                            let updateddata: Array<StoredWord> = storedwordmeanings.filter((ee) => {
                                return ee.tstmp > tstmp - cacheWordMeaningSeconds;
                            });
                            lclStorage.setItem(WORDMEANING_ALL_DATA, JSON.stringify(updateddata));
                        }
                    }
                } 
            }
            ReactGA.event({
                category: "game_start",
                action: "revisited",
                label: "wordmeaning",
                value: 1,
            });
            let todisplay: Array<{ word: string; meaning: string }> = wordsmade.map((word) => {
                let tempmeaning: Array<StoredWord> = storedwordmeanings.filter((ee) => ee.word === word);
                let meaning = "N/A";
                if (tempmeaning && tempmeaning.length > 0 && tempmeaning[0].meaning.length > 0) {
                    meaning = tempmeaning[0].meaning[0].description;
                } else {
                    meaning = "N/A";
                }
                return {
                    word,
                    meaning,
                };
            });
            setWordMeaning(todisplay);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setShowPopUp(true);
            setPopMsg(FETCH_ERR_MSG);
            throw Error("ERR :: fetchWordMeaning");
        }
    }

    const wordList = wordmeaning.map((ele: { word: string; meaning: string }, indx: number) => {
        return (
            <tr key={indx}>
                <td key={indx} className="font-roboto text-[24px] text-secondary-700 pl-10 pb-4 pr-8 font-bold">
                    {ele.word}
                </td>
                <td className="font-roboto text-secondary-700 text-[12px] pb-4 pr-4 leading-4 font-medium">{ele.meaning}</td>
            </tr>
        );
    });

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    const onClosePopup = () => {
        if (popMsg === FETCH_ERR_MSG) {
            window.location.reload();
            navigate(pathIntro);
        }
        setShowPopUp(false);
    };

    return (
        <div className=" h-full flex flex-col">
            <Header
                title={titleWordMeaning}
                onMenuHelpClicked={() => {
                    handleShow();
                }}
            />
            {showHelp && <HelpDeskPopup closeHelp={handleClose} />}

            <div className="flex h-full relative flex-col self-center min-w-[350px] max-w-[600px] overflow-y-auto bg-secondary-150 m-8">
                <div className="bg-warning-400 absolute h-24 w-24 text-transparent ml-14">Blank</div>
                <div className="ml-7 leading-6 mb-20 mt-10 absolute font-roboto text-[19px] text-secondary-700 font-bold">
                    <p>Word</p>
                    <p>Meaning</p>
                </div>
                {showPopup && <PopupUp msg={popMsg} btnText={"OK"} setShowMenu={onClosePopup} />}
                {isLoading ? (
                    <div className="font-robototext-xl  font-roboto text-center self-center font-normal mt-40 ml-2">
                        Loading...
                    </div>
                ) : wordmeaning.length === 0 ? (
                    <div className="text-xl font-roboto text-center font-normal self-center  mt-40 mx-10">
                        <p>You have not made any word yet, Play games and make words.</p>
                    </div>
                ) : (
                    <div className="flex h-full flex-col overflow-y-auto mt-40 ml-4">
                        <table className="table-auto">
                            <tbody>{wordList}</tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};
