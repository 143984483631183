import { useCallback, useEffect, useState } from "react";
import { useTimerType } from "../Commons/types";

const useTimer = (isPause: boolean): useTimerType => {
    let [totalTime, setTotalTime] = useState<number>(-1);
    let [isTimeEnd, setIsTimeEnd] = useState<boolean>(false);

    useEffect(() => {
        if (totalTime === -1) {
            //do nothing
        } else if (totalTime && !isTimeEnd) {
            if (!isPause) {
                setTimeout(() => {
                    setTotalTime(totalTime - 1);
                }, 1000);
            }
        } else {
            setIsTimeEnd(true);
        }
    }, [totalTime, isTimeEnd, isPause]);

    const remainingTime = useCallback(() => {
        let hr = Math.floor(totalTime / 3600); // get hours
        let min = Math.floor((totalTime - hr * 3600) / 60); // get minutes
        let sec = totalTime - hr * 3600 - min * 60; //  get seconds
        return { hr, min, sec };
    }, [totalTime]);

    const startTimer = useCallback((totalTimeInSec: number) => {
        setTotalTime(totalTimeInSec);
    }, []);

    const stopTimer = useCallback(() => {
        let remainingTiming =
            remainingTime().hr * 3600 +
            remainingTime().min * 60 +
            remainingTime().sec;
        if (remainingTiming !== 0) {
            setTotalTime(remainingTiming);
            if (isPause) {
                setIsTimeEnd(true);
            }
        } else {
            setTotalTime(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTime]);

    return {
        startTimer,
        remainingTime: remainingTime(),
        isTimeEnd,
        stopTimer,
    };
};

export default useTimer;
