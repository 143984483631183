import Header from "../../Component/Header/Header";
import FoundWordContainer from "./FoundWordContainer/FoundWordContainer";
import LetterInCircel from "./LettersInCircle/LettersInCircle";
import SelectedWord from "./SelectedWord/SelectedWord";
// import Timer from "../../Component/Timer/Timer";
// import useTimer from "../../Hooks/useTimer";
import GameOver from "../../Component/GameOver/GameOver";
import { useNavigate } from "react-router-dom";
import {
    // RootState,
    // ScoreType,
    // useTimerType,
    HintType,
    WordChainGameCmpltdType,
    // TimerPropType,
    // TempWordChainData,
    ShowTutorialOnceType,
} from "../../Commons/types";
import {
    pathIndexOfGame,
    // pathWordChain,
    // pathWordHunt,
    titleWordChain,
    WORDCHAIN_COMPLETED_DATA,
    // WORDHUNT_COMPLETED_DATA,
    pathFinalScore,
    TEMP_WORDCHAIN_DATA,
    SHOW_TUTORIAL_ONCE,
} from "../../Commons/constant";
import { useEffect, useState } from "react";
import BlupIcon from "../../Component/SVG/BlupIcon";
// import { useSelector } from "react-redux";
// import ProgressBar from "../../Component/ProgressBar/ProgressBar";
import { Tutorial } from "./Tutorial/Tutorial";
import { StatsPopup } from "./GameStats/StatsPopup";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import lclStorage from "../../Utils/LocalStorage";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import ReactGA from "react-ga4";
import  WordsOfLastPlayedIcon from '../../Assets/svg/WordsOfLastPlayedIcon.svg'
import { LastDayWord } from "../../Component/LastDayWord/LastDayWord";

export const WordChain = () => {
    let navigator = useNavigate();
    const [showHelp, setShowHelp] = useState(false);
    // const [timer, setTimer] = useState<number>(0);
    const [isAllGameOver, setIsALlGameOver] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    // const [isPause, setIsPause] = useState<boolean>(false);
    // const { startTimer, remainingTime, isTimeEnd, stopTimer }: useTimerType =
    //     useTimer(isPause);
    const [wordCompleted, setWordCompleted] = useState<boolean>(false);

    const [showHint, setShowHint] = useState<HintType>({
        show: false,
        ntfNumber: 1,
    });
    const [showStats, setShowStats] = useState(false);
    const [GameOverData, setGameOverData] = useState<WordChainGameCmpltdType>({
        isGameOver: false,
        data: {
            word_store: [],
            hintData: showHint,
            // initialTime: getTimeInHHMMSS(timer),
            // cmpltdTime: { hr: 0, min: 0, sec: 0 },
            score: 0,
            tstmp:0
        },
    });

    let [score, setScore] = useState<number>(0);
    const [showGameOverPopup, setShowGameOverPopup] = useState<boolean>(false);
    const [showMostRecentPuzzleAnswers, setShowMostRecentPuzzleAnswers] = useState<boolean>(false)

    useEffect(() => {
        const onInitWordChain = async () => {
            //-----------------------------------------
            let showtutorial: ShowTutorialOnceType = {
                wordChainTutShow: false,
                wordHuntTutShown: false,
                assemblerTutShown: false,
                wordKnitTutShown: false,
                wordspiderTutShown: false,
                wordAlikeTutShown: false,
                wordSearchTutShown: false,
            };
            let tt = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
            if (tt !== null && tt !== undefined) {
                showtutorial = JSON.parse(tt);
            }
            //-----------------------------------------
            // let initialtime = -1;
            let completedgamedata = await lclStorage.getItem(
                WORDCHAIN_COMPLETED_DATA
            );
            if (completedgamedata !== null && completedgamedata !== undefined) {
                setGameOverData(JSON.parse(completedgamedata));
                ReactGA.event({
                    category: "game_over",
                    action: "revisited",
                    label: "wordchain",
                    value: 1,
                });
                setShowGameOverPopup(true)
            } else {
                let resumegame = await lclStorage.getItem(TEMP_WORDCHAIN_DATA);
                if (resumegame !== null && resumegame !== undefined) {
                    // let word_chain_data: TempWordChainData =
                    //     JSON.parse(resumegame);
                    // initialtime = word_chain_data.timer;
                    ReactGA.event({
                        category: "game_start",
                        action: "resume",
                        label: "wordchain",
                        value: 1,
                    });
                } else {
                    let frshdata = await dailyWordPuzzleDataAPI.wordChainData();
                    if (frshdata) {
                        // initialtime = frshdata.duration
                        ReactGA.event({
                            category: "game_start",
                            action: "new_game",
                            label: "wordchain",
                            value: 1,
                        });
                    }
                }
            }
            // if(initialtime !== -1){
            //     setTimer(initialtime);
            //     startTimer(initialtime);
            // }

            //-----------------------------------------
            if (showtutorial.wordChainTutShow === false) {
                showtutorial.wordChainTutShow = true;
                setIsLoading(showtutorial.wordChainTutShow);
                lclStorage.setItem(
                    SHOW_TUTORIAL_ONCE,
                    JSON.stringify(showtutorial)
                );
            } else {
                onStartGame();
            }
            //-----------------------------------------
        };

        onInitWordChain();
    }, []);

    useEffect(() => {
        if (GameOverData.isGameOver) {
            setIsLoading(false);
            setShowHint({
                show: false,
                ntfNumber: GameOverData.data.hintData.ntfNumber,
            });
            // startTimer(getTimeInSec(GameOverData.data.cmpltdTime));
            // setIsPause(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GameOverData.isGameOver]);

    // useEffect(() => {
    //     // if (wordCompleted) {
    //     //     setIsPause(true);
    //     //     // stopTimer();
    //     // }
    //     // if (isPause) {
    //     // stopTimer();
    //     // if (!wordCompleted && !showStats && !GameOverData.isGameOver) {
    //     //     setIsLoading(true);
    //     // }
    //     // }
    // }, [wordCompleted]);

    useEffect(() => {
        setTimeout(() => {
            dailyWordPuzzleDataAPI.isAllGameOver().then((data) => {
                if (data) {
                    setIsALlGameOver(data);
                }
            });
        }, 100);
        if (!GameOverData.isGameOver) {
            //game has completed either by timeover or all words found
            // if (isTimeEnd) {
            //     ReactGA.event({
            //         category: "game_over",
            //         action: "time_over",
            //         label: "wordchain",
            //         value: 1,
            //     });
            // } else
            if (wordCompleted) {
                SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                ReactGA.event({
                    category: "game_over",
                    action: "finished_game",
                    label: "wordchain",
                    value: 1,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordCompleted]);

    const onStartGame = () => {
        setIsLoading(false);
        SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
        // if (isPause) {
        //     // let remainingpauseTime =
        //     //     {
        //     //         hr: remainingTime.hr,
        //     //         min: remainingTime.min,
        //     //         sec: remainingTime.sec + 1,
        //     //     } ?? timer;
        //     // startTimer(getTimeInSec(remainingpauseTime));
        //     setIsPause(false);
        // }
    };

    // function getTimeInSec(time: {
    //     hr: number;
    //     min: number;
    //     sec: number;
    // }): number {
    //     const total_time_in_sec = time.hr * 3600 + time.min * 60 + time.sec;
    //     return total_time_in_sec;
    // }

    // function getTimeInHHMMSS(totalTimeInSec: number): TimerPropType {
    //     let hr = Math.floor(totalTimeInSec / 3600); // get hours
    //     let min = Math.floor((totalTimeInSec - hr * 3600) / 60); // get minutes
    //     let sec = totalTimeInSec - hr * 3600 - min * 60; //  get seconds
    //     return { hr, min, sec };
    // }

    const toggleStatsPopup = () => {
        if (showStats) {
            setShowStats(false);
            // setIsPause(false);
        } else {
            setShowStats(true);
            // setIsPause(true);
        }
    };

    const onHintClicked = () => {
        SndMngr.playSound(SoundType.CLICKBTN);
        setIsLoading(true);
        // setIsPause(true);
        // if (showHint.ntfNumber > 0) {
        //     SndMngr.playSound(SoundType.CLICKBTN);
        //     setShowHint({
        //         show: !showHint.show,
        //         ntfNumber:
        //             showHint.ntfNumber === 0 ? 0 : showHint.ntfNumber - 1,
        //     });
        // }
    };

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    const renderMostRecentPuzzleAnswers = (): JSX.Element | null => {
        const previous_words: string[]  = dailyWordPuzzleDataAPI.getMostRecentPuzzleAnswers()?.wordchain;
        let previousWordJSX: JSX.Element | null = null;
        if (previous_words.length) {
            previousWordJSX = (
                <>
                    <img
                        src={WordsOfLastPlayedIcon}
                        alt="icon"
                        className=" h-10 w-10 text-black cursor-pointer self-end z-10"
                        onClick={() => {
                            SndMngr.playSound(SoundType.DROPBTNSOUND)
                            ReactGA.event({
                                category: "click",
                                action: "history",
                                label: "wordchain",
                                value: 1,
                            });
                            setShowMostRecentPuzzleAnswers(true)
                        }}
                    />

                    {showMostRecentPuzzleAnswers && (
                        <div className="absolute z-20">
                            <LastDayWord
                                words={previous_words}
                                onPress={() => setShowMostRecentPuzzleAnswers(false)}
                            />
                        </div>
                    )}
                </>
            );
        }
        return previousWordJSX;
    };

    const renderGameAfterTutorial = () => {
        if (isLoading) {
            return <Tutorial startGameClicked={onStartGame} />;
        } else {
            return (
                <div className={`flex flex-col items-center h-full pt-2`}>
                    <div
                        className={`flex flex-col h-full items-center justify-center `}
                    >
                        <div className="flex relative w-[300px] md:w-[380px] sm:w-[380px] lg:w-[380px] justify-between mb-4 flex-row-reverse">
                        {/* <Timer
                            hr={remainingTime.hr}
                            min={remainingTime.min}
                            sec={remainingTime.sec}
                        /> */}
                        <BlupIcon
                            style={styles.icon}
                            clicked={() => onHintClicked()}
                            ntfNumber={showHint.ntfNumber}
                        />
                        {renderMostRecentPuzzleAnswers()}
                    </div>
                        <SelectedWord />
                        <LetterInCircel
                            isGameCmpltd={GameOverData.isGameOver}
                        />
                        <FoundWordContainer
                            showHint={showHint}
                            updateScore={(score) =>
                                setScore((scr) => scr + score)
                            }
                            currentScore={score}
                            // initialTime={getTimeInHHMMSS(timer)}
                            // isTimeEnd={isTimeEnd}
                            // remainingTime={remainingTime}
                            onHintClicked={onHintClicked}
                            doGameOver={(isGameOver: boolean) => {
                                if (isGameOver) {
                                    setWordCompleted(isGameOver)
                                    setShowGameOverPopup(true)
                                }
                            }}
                        />
                    </div>
                    {(isAllGameOver || wordCompleted) && showGameOverPopup &&  (
                        <GameOver
                            title={titleWordChain}
                            gameScore={
                                GameOverData.isGameOver
                                    ? GameOverData.data.score
                                    : score
                            }
                            onClose={() =>
                                navigator(
                                    isAllGameOver &&
                                        !dailyWordPuzzleDataAPI.hasVistedFinalPage
                                        ? pathFinalScore
                                        : pathIndexOfGame
                                )
                            }
                            handleGameOverPopup={()=>setShowGameOverPopup(false)}
                        />
                    )}
                </div>
            );
        }
    };

    return (
        <div className={styles.mainContainer}>
            <Header
                title={titleWordChain}
                // setMenuIsLoading={setIsPause}
                // onMenuHintClicked={onHintClicked}
                onMenuStatsClicked={toggleStatsPopup}
                onMenuHelpClicked={() => {
                    handleShow();
                }}
            />
            {showHelp && <HelpDeskPopup closeHelp={handleClose} />}
            {/* <ProgressBar
                p1={isTimeEnd || wordCompleted || GameOverData.isGameOver}
                p2={false}
                p3={false}
                style={` pb-2 md:pb-8`}
            /> */}
            <div className={styles.mainContainer}>
                {showStats && <StatsPopup closeStats={toggleStatsPopup} />}
                {renderGameAfterTutorial()}
            </div>
        </div>
    );
};

const styles = {
    mainContainer: "flex flex-col h-full bg-white select-none ",
    icon: "h-12 w-12 text-black self-center  cursor-pointer rounded-full ",
};
