import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    pathIndexOfGame,
    titleIntro,
    pathFinalScore,
    titleWordHunt,
    titleWordChain,
    titleWordMeaning,
} from "../../Commons/constant";
import ChevronLeftIconWhite from "../../Assets/svg/ChevronLeftIconWhite.svg";
import MenuIcon from "../../Assets/svg/MenuIcon.svg";
import ShareIcon from "../../Assets/svg/ShareIcon.svg";
import { Menu } from "../Menu/Menu";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
// import { dailyWordPuzzleDataAPI } from "../../Pages/Introduction/api/DailyWordPuzzleDataAPI";
import { Toast, TOASTVARIANT } from "../../Component/Toast/Toast";
import { useToast } from "../../Hooks/useToast";
import copy from "copy-to-clipboard";
import {  titleWordKnit } from "../../Pages/WordKnit/Utils/constant";
import { titleWordSpider } from "../../Pages/WordSpider/Utils/Constants";
import { titleWordAlike } from "../../Pages/WordAlike/Utils/constants";
import {titleWordSearch } from "../../Pages/WordSearch/Constants";
import ReactGA from "react-ga4";

declare const window: Window &
    typeof globalThis & {
        _daily_puzzle_game_configuration_: any;
    };

const Header = (props: {
    title: string;
    setMenuIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    onMenuHintClicked?: () => void;
    onMenuStatsClicked?: () => void;
    onMenuHelpClicked?: () => void;
    showOnlyTitle?: boolean;
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let { addToast, toast } = useToast();
    const [showMenu, setShowMenu] = useState(false);
    // const [isAllGameOver, setIsALlGameOver] = useState<boolean>(false);
    // useEffect(() => {
    //     dailyWordPuzzleDataAPI.isAllGameOver().then((data) => {
    //         if (data) {
    //             setIsALlGameOver(data);
    //         }
    //     });
    // }, []);

    const style = {
        mainContainer: `flex flex-row w-full bg-primary-400 p-3 drop-shadow-md ${
            props.showOnlyTitle ? "justify-center" : "justify-between"
        }  ${location.pathname === pathFinalScore ? "z-[300]" : "z-10"}`,
        icon: "w-7 h-7 stroke-white cursor-pointer flex",
        text: `text-white text-center leading-10 tracking-[-0.02em] font-secular font-medium text-[22px] ${
            props.title === titleIntro ||
            props.title === titleWordHunt ||
            props.title === titleWordKnit ||
            props.title === titleWordSpider ||
            props.title === titleWordChain ||
            props.title === titleWordMeaning ||
            props.title === titleWordAlike ||
            props.title === titleWordSearch
                ? ""
                : "xsm:ml-0 ml-[55px]"
        }`,
    };

    const onShareClick = () => {
        SndMngr.playSound(SoundType.CLICKBTN);
        addToast(TOASTVARIANT.NOICON, "Copied to clipboard");
        let ShareLink = window._daily_puzzle_game_configuration_.shareurl;
        copy(ShareLink, { format: "text/plain" });
        // if (isAllGameOver) {
        //     navigate(pathFinalScore);
        // }
    };

    return (
        <>
            <div className={style.mainContainer}>
                {props.title !== titleIntro && (
                    <div className="flex items-center ">
                        <img
                            src={ChevronLeftIconWhite}
                            className={style.icon}
                            onClick={() => {
                                SndMngr.playSound(SoundType.CLICKBTN);
                                navigate(pathIndexOfGame);
                            }}
                            alt="ChevronLeftIconWhite"
                        />
                        <div className={style.icon + " mx-4 "} />
                    </div>
                )}
                <p className={style.text + " flex justify-center"}>
                    {props.title}
                </p>

                {!props.showOnlyTitle && (
                    <div className=" flex items-center justify-end">
                        <img
                            src={ShareIcon}
                            className={style.icon + " mx-4 "}
                            onClick={() => {
                                onShareClick();
                                ReactGA.event({
                                    category: "share",
                                    action: "urlshare",
                                    label: "simplewordgames",
                                    value: 1,
                                });
                            }}
                            alt="ShareIcon"
                        />
                        <img
                            src={MenuIcon}
                            className={style.icon}
                            onClick={() => {
                                SndMngr.playSound(SoundType.CLICKBTN);
                                setShowMenu(true);
                            }}
                            alt="MenuIcon"
                        />
                    </div>
                )}
            </div>
            {showMenu && (
                <Menu
                    setShowMenu={setShowMenu}
                    setMenuIsLoading={props.setMenuIsLoading}
                    onMenuHintClicked={props.onMenuHintClicked}
                    onMenuStatsClicked={props.onMenuStatsClicked}
                    onMenuHelpClicked={props.onMenuHelpClicked}
                />
            )}
            <Toast toast={[...toast]} />
        </>
    );
};

export default Header;
