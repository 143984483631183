import BackSpaceIcon from "../../../Assets/svg/BackSpaceIcon.svg";
import { KEYBOARDLETTER } from "../../../Commons/constant";
import { BoardType } from "../../../Commons/types";
import {BGCOLORS } from "../LetterInSquares/LetterInSquares";

export const KeyBoard = (props: {
    clicked: (keyStroke: string) => void;
    boardData: BoardType;
}) => {
    const renderKeyBoardLetter = () => {
        let keyboard_jsx: Array<JSX.Element> = [];
        let temp_jsx: Array<JSX.Element> = [];

        const styles = {
            keyBoardbtn: ` font-bold font-roboto text-[24px] text-md rounded-sm p-2 m-0.5 xl:p-3 lg:p-3 focus:outline-none `,
        };

        const btnBgColorEditor = (btnLetter: string): string => {
            let required_color: string = "";
            let letterColors: string[] = [];
            props.boardData.forEach((row) => {
                row.filter((square) => square.letter === btnLetter).forEach(
                    (square) => letterColors.push(square.color)
                );
            });

            const alpha_colors: string | undefined = [
                BGCOLORS.GREEN,
                BGCOLORS.RED,
                BGCOLORS.GRAY,
            ].find((color) =>
                letterColors.find((colors) => colors.includes(color))
            );
            if (alpha_colors) {
                required_color = alpha_colors;
            }

            return required_color;
        };

        KEYBOARDLETTER?.forEach((letter, index) => {
            let updatedBgColor ="bg-primary-700 text-white ";
            if (btnBgColorEditor(letter).includes("bg-")) {
                updatedBgColor = btnBgColorEditor(letter)
            }
            temp_jsx.push(
                <button
                    className={updatedBgColor + styles.keyBoardbtn}
                    key={index}
                    onClick={() => props.clicked(letter)}
                >
                    {letter === "BACKSPACE" ? (
                        <img
                            src={BackSpaceIcon}
                            alt="BackSpaceIcon"
                            className={`self-center min-w-[20px] `}
                        />
                    ) : (
                        letter
                    )}
                </button>
            );
            if (index !== 0 && index % 9 === 0) {
                keyboard_jsx.push(
                    <div className="flex flex-row" key={index}>
                        {temp_jsx}
                    </div>
                );
                temp_jsx = [];
            }
        });
        return (
            <div className="flex flex-col place-items-center py-0">
                {keyboard_jsx}
            </div>
        );
    };

    return renderKeyBoardLetter();
};
