import { Introduction } from "./Pages/Introduction/Introduction";
import { WordChain } from "./Pages/WordChain/WordChain";
import { WordHunt } from "./Pages/WordHunt/WordHunt";
import { Route, Routes } from "react-router-dom";
import { pathIndexOfGame, pathIntro, pathAssembler, pathWordChain, pathWordHunt, pathFinalScore, pathWordMeaning } from "./Commons/constant";
import { pathWordSpider } from "./Pages/WordSpider/Utils/Constants";
import { Assembler } from "./Pages/Assembler/Assembler";
import WordSpider from "./Pages/WordSpider/WordSpider";
import { FinalScore } from "./Pages/FinalScore/FinalScore";
import { IndexOfGame } from "./Pages/IndexOfGame/IndexOfGame";
import ReactGA from "react-ga4";
import { WordKnit } from "./Pages/WordKnit/WordKnit";
import { pathWordKnit } from "./Pages/WordKnit/Utils/constant";
import { WordMeaning } from "./Pages/WordsWithMeaning/WordMeaning";
import { WordAlike } from "./Pages/WordAlike/WordAlike";
import { pathWordAlike } from "./Pages/WordAlike/Utils/constants";
import { WordSearch } from "./Pages/WordSearch/WordSearch";
import {pathWordSearch} from "./Pages/WordSearch/Constants";

function App() {
    ReactGA.initialize("G-S9P1EVJQ09");
    return (
        <Routes>
            <Route path={pathIntro} element={<Introduction />} />
            <Route path={pathIndexOfGame} element={<IndexOfGame />} />
            <Route path={pathWordChain} element={<WordChain />} />
            <Route path={pathWordHunt} element={<WordHunt />} />
            <Route path={pathAssembler} element={<Assembler />} />
            <Route path={pathWordSpider} element={<WordSpider />} />
            <Route path={pathWordKnit} element={<WordKnit />} />
            <Route path={pathWordAlike} element={<WordAlike />} />
            <Route path={pathWordSearch} element={<WordSearch/>} />
            <Route path={pathFinalScore} element={<FinalScore />} />
            <Route path={pathWordMeaning} element={<WordMeaning />} />
            <Route path="*" element={<Introduction />} />
        </Routes>
    );
}

export default App;
