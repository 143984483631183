import { useDrag } from "@use-gesture/react";
import { useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { TILE_EVENTS, TILE_BG_COLOR, TILE_LETTER_COLOR } from "../Utils/constant";
import { tileCmd, TilePropType } from "../Utils/type";

export const Tile = (props: TilePropType) => {
    const [springStyle, springAPI] = useSpring(() => ({
        x: 0,
        y: 0,
        opacity: 1,
    }));

    useEffect(() => {
        props.cmdHandler({
            action: TILE_EVENTS.EVT_ON_INIT,
            tileId: props.tileID,
            uiHandlerFun: uiUdateHandler,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uiUdateHandler = (cmdRelay: tileCmd) => {
        let { action, tileId, offset } = cmdRelay;
        if (tileId === props.tileID) {
            switch (action) {
                case TILE_EVENTS.EVT_ON_DRAG:
                    if (offset) {
                        startTileMovement(offset[0], offset[1]);
                    }
                    break;
            }
        }
    };

    const startTileMovement = (x: number, y: number) => {
        springAPI.start({
            to: async (next, cancel) => {
                await next({ x, y, opacity: 0.8, immediate: !x && !y ? false : true });
                await next({ opacity: 1 });
            },
        });
    };

    const getCurrentTilePosition = () => {
        let position: { x: number; y: number } = { x: 0, y: 0 };
        if (springStyle) {
            position.x = springStyle?.x?.get();
            position.y = springStyle?.y?.get();
        }
        return position;
    };

    const onTileDrag = useDrag(
        ({ down, offset, movement }:{down:boolean,offset?:number[],movement?:number[]}) => {
            if (!props.isOrgPos) {
                if (down) {
                    props.cmdHandler({
                        action: TILE_EVENTS.EVT_ON_DRAG,
                        tileId: props.tileID,
                        offset,
                    });
                } else {
                    props.cmdHandler({
                        action: TILE_EVENTS.EVT_ON_DRAG_END,
                        tileId: props.tileID,
                        movement,
                    });
                }
            }
        },
        { from: () => [getCurrentTilePosition().x, getCurrentTilePosition().y] }
    );

    function getBorderStyle() {
        let border = Object.keys(props.renderBorder).map((side) => {
            if ((props.renderBorder as any)[side]) {
                return props.isOrgPos
                    ? {
                          [`border${side.charAt(0).toUpperCase() + side.slice(1)}Color`]: "#60761F",
                      }
                    : {
                          [`border${side.charAt(0).toUpperCase() + side.slice(1)}Color`]: "#5D747F",
                      };
            } else {
                return {
                    [`border${side.charAt(0).toUpperCase() + side.slice(1)}Width`]: "0px",
                };
            }
        });
        return border.reduce((obj, item) => ({ ...obj, ...item }), {});
    }

    const getTileBkgColor = (): string => {
        if (props.isOrgPos) {
            return TILE_BG_COLOR.SUCCESS;
        } else {
            return TILE_BG_COLOR.SECONDARY;
        }
    };

    const getTileTextColor = () => {
        if (props.isOrgPos) {
            return TILE_LETTER_COLOR.SUCCESS;
        } else {
            return TILE_LETTER_COLOR.SECONDARY;
        }
    };

    return (
        <animated.div
            className={`flex flex-col aspect-square cursor-grab touch-none items-center justify-center border ${getTileBkgColor()} 
            z-10`}
            {...onTileDrag()}
            style={{ ...springStyle, ...getBorderStyle() }}
        >
            <p
                className={`text-[2vmin] text-center ${getTileTextColor()} font-bold uppercase`}
            >
                {props.letter}
            </p>
        </animated.div>
    );
};
