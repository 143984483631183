import {
    AssemblerFinGameType,
    AssemblerInpGameType,
    DailyWordPuzzleDataType,
    TempWordChainData,
    TempWordHuntData,
    ValidWordsDataType,
    WordChainGameCmpltdType,
    WordHuntGameCmpltdType,
} from "../../../Commons/types";
import { WORDSPIDER_INP_GAME_DATA, WORDSPIDER_FIN_GAME_DATA } from "../../WordSpider/Utils/Constants";
import lclStorage from "../../../Utils/LocalStorage";
import axios from "axios";

import {
    DAILY_WORD_PUZZLE_API_ACTION,
    DAILY_WORD_PUZZLE_DATA,
    WORDCHAIN_COMPLETED_DATA,
    WORDHUNT_COMPLETED_DATA,
    ASSEMBLER_FIN_GAME_DATA,
    FAILURE,
    VALID_WORDS_DATA,
    TEMP_WORDHUNT_DATA,
    TEMP_WORDCHAIN_DATA,
    ASSEMBLER_INP_GAME_DATA,
    DICTIONARY_DATA_URL,
    PRE_DAILY_WORD_PUZZLE_DATA,
} from "../../../Commons/constant";
import { WORDKNIT_FIN_GAME_DATA, WORDKNIT_INP_GAME_DATA } from "../../WordKnit/Utils/constant";
import { Trie, TrieNode } from "@datastructures-js/trie";
import { GameOverWordKnitComplDataType, TempWordKnitDataType } from "../../WordKnit/Utils/type";
import { TempWordSpiderData } from "../../WordSpider/Utils/Types";
import { WORDALIKE_FIN_GAME_DATA, WORDALIKE_INP_GAME_DATA } from "../../WordAlike/Utils/constants";
import { WordAlikeFinGameType, WordAlikeInpGameType } from "../../WordAlike/Utils/type";
import { WORDSEARCH_INP_GAME_DATA } from "../../WordSearch/Constants";
import { gameOverReason, RJWordSearchInpGameType } from "../../WordSearch/Types";
import { getAllWordsToBeMade } from "../../WordKnit/Utils/BlockBuilder";


declare const window: Window &
    typeof globalThis & {
        _daily_puzzle_game_configuration_: any;
    };

class DailyWordPuzzleDataAPI {
    private data_stored: DailyWordPuzzleDataType | undefined;
    private valid_words: ValidWordsDataType | undefined;
    hasVistedFinalPage: boolean = false;
    private trieRoot: TrieNode | null = null;
    private previous_data_store: DailyWordPuzzleDataType | null = null;

    async onAppInit() {
        try {
            let data_fetched = await Promise.all([
                this.fetchDailyWordPuzzleData(),
                this.fetchValidWords(),
                this.isAllGameOver(),
            ]);

            this.data_stored = data_fetched[0]?.present_data;
            this.previous_data_store = data_fetched[0]?.previous_data ?? null;
            this.valid_words = data_fetched[1];
            this.trieRoot = Trie.fromArray(this.valid_words?.dictionary ?? [])
                .find("AB")
                .getParent()
                .getParent();
            let isAllGameOver = data_fetched[2];
            if (isAllGameOver !== null && isAllGameOver !== undefined) {
                this.hasVistedFinalPage = isAllGameOver;
            }
        } catch (err) {
            console.error(err);
        }
    }

    getAllWords = (node: TrieNode, rack: Array<string>, wordarr: Array<string>, currentword = ""): void => {
        if (node == null || node === undefined || rack.length === 0) {
            return;
        }
        for (let i = 0; i < rack.length; i++) {
            let ch = rack[i];
            if (node.hasChild(ch)) {
                let anode = node.getChild(ch);
                let arack = [...rack.slice(0, i), ...rack.slice(i + 1)];
                let nword = currentword + ch;
                if (anode.isEndOfWord()) {
                    wordarr.push(nword);
                }
                this.getAllWords(anode, arack, wordarr, nword);
            }
        }
    };

    getWordsFormed = (rack: Array<string>): Array<string> => {
        let allWord: string[] = [];
        if (this.trieRoot) {
            this.getAllWords(this.trieRoot, rack, allWord);
        }
        // console.log("founded words", allWord);
        return allWord;
    };

    hasDateChanged(dData: string) {
        if (dData !== undefined) {
            let puzzedDate = new Date(Number(dData) * 1000);
            let currentDate = new Date(Date.now());

            let isDaySame = puzzedDate.getDate() === currentDate.getDate();
            let isMonthSame = puzzedDate.getMonth() === currentDate.getMonth();
            let isYearSame = puzzedDate.getFullYear() === currentDate.getFullYear();

            return !(isYearSame && isMonthSame && isDaySame);
        }
        return true;
    }

    private async fetchDailyWordPuzzleData() {
        try {
            let savedGameData: string | null = await lclStorage.getItem(DAILY_WORD_PUZZLE_DATA);
            let previous_data: DailyWordPuzzleDataType | string | null = null;
            if (savedGameData) {
                let gameData: DailyWordPuzzleDataType = JSON.parse(savedGameData);
                let dateHasChanged: boolean = this.hasDateChanged(gameData.puzzle_dt);
                if (!dateHasChanged && gameData.check !== FAILURE && gameData.data) {
                    let previous_saved_words: string | null = await lclStorage.getItem(PRE_DAILY_WORD_PUZZLE_DATA);
                    if (previous_saved_words) {
                        let prev_parse_data: DailyWordPuzzleDataType = JSON.parse(previous_saved_words);
                        if (prev_parse_data.puzzle_dt !== gameData.puzzle_dt) {
                            previous_data = prev_parse_data;
                        }
                    }
                    return { present_data: gameData, previous_data };
                } else {
                    if (gameData.check !== FAILURE && gameData.data) {
                        previous_data = gameData;
                        await lclStorage.setItem(PRE_DAILY_WORD_PUZZLE_DATA, JSON.stringify(previous_data));
                    }
                    await lclStorage.resetItem(DAILY_WORD_PUZZLE_DATA);
                }
            }
            let url =
                window._daily_puzzle_game_configuration_.daily_puzzle_data_base_url +
                DAILY_WORD_PUZZLE_API_ACTION +
                this.getTstmpQueryModifier();

            const gameRespData = await axios.get<DailyWordPuzzleDataType>(url);

            if (gameRespData.status === 200 && gameRespData?.data?.data) {
                //save this data local storage
                let lcl_res_data: string = JSON.stringify(gameRespData.data);
                await lclStorage.setItem(DAILY_WORD_PUZZLE_DATA, lcl_res_data);
            } else {
                throw Error("ERR :: fetchDailyWordPuzzleData");
            }

            //delete other previous played game data from local storage
            await lclStorage.resetItem(TEMP_WORDCHAIN_DATA);
            await lclStorage.resetItem(TEMP_WORDHUNT_DATA);
            await lclStorage.resetItem(WORDSPIDER_INP_GAME_DATA);
            await lclStorage.resetItem(ASSEMBLER_INP_GAME_DATA);
            await lclStorage.resetItem(WORDKNIT_INP_GAME_DATA);
            await lclStorage.resetItem(WORDCHAIN_COMPLETED_DATA);
            await lclStorage.resetItem(WORDHUNT_COMPLETED_DATA);
            await lclStorage.resetItem(ASSEMBLER_FIN_GAME_DATA);
            await lclStorage.resetItem(WORDKNIT_FIN_GAME_DATA);
            await lclStorage.resetItem(WORDSPIDER_FIN_GAME_DATA);
            await lclStorage.resetItem(WORDALIKE_FIN_GAME_DATA);
            await lclStorage.resetItem(WORDALIKE_INP_GAME_DATA);
            await lclStorage.resetItem(WORDSEARCH_INP_GAME_DATA);

            return { present_data: gameRespData.data, previous_data };
        } catch (err) {
            await lclStorage.setItem(DAILY_WORD_PUZZLE_DATA, JSON.stringify({ check: FAILURE }));
        }
    }

    getTstmpQueryModifier = (): string => {
        let dt = Math.floor(Date.now() / 1000);
        return "&tstmp=" + dt;
    };

    private async fetchValidWords(): Promise<ValidWordsDataType | undefined> {
        let dicRspData: ValidWordsDataType = { check: FAILURE, dictionary: [] };
        try {
            let savedGameData: string | null = await lclStorage.getItem(VALID_WORDS_DATA);
            if (savedGameData) {
                dicRspData = JSON.parse(savedGameData);
                if (dicRspData.check !== FAILURE && dicRspData.dictionary.length > 0) {
                    return dicRspData;
                } else {
                    lclStorage.resetItem(VALID_WORDS_DATA);
                }
            }
            const gameRespData = await axios.get<any>(DICTIONARY_DATA_URL);
            if (gameRespData.status === 200 && gameRespData.data) {
                dicRspData = {
                    check: "success",
                    dictionary: gameRespData.data.trim().split(/\r\n|\n|\r/),
                };
            }
            await lclStorage.setItem(VALID_WORDS_DATA, JSON.stringify(dicRspData));
        } catch (err) {
            await lclStorage.setItem(VALID_WORDS_DATA, JSON.stringify(dicRspData));
        }
        return dicRspData;
    }

    async wordChainData() {
        return this.data_stored?.data.wordchain;
    }

    async wordHuntData() {
        return this.data_stored?.data.wordhunt;
    }

    async assemblerData() {
        return this.data_stored?.data.assembler;
    }

    async wordspiderData() {
        return this.data_stored?.data.wordspider;
    }

    async getValidWords() {
        return this.valid_words;
    }

    async wordAlikeData() {
        return this.data_stored?.data.wordalike;
    }

    isWordValidate(word: string): boolean {
        let is_word_validate: boolean = false;
        if (this.valid_words?.dictionary.includes(word.trim().toUpperCase())) {
            is_word_validate = true;
        }
        return is_word_validate;
    }

    getWordKnitData() {
        return this.data_stored?.data.wordknit.blocks;
    }

    getWordSearchData() {
        return this.data_stored?.data.wordsearch;
    }

    async isAllGameOver() {
        let a = await lclStorage.getItem(WORDCHAIN_COMPLETED_DATA);
        let b = await lclStorage.getItem(WORDHUNT_COMPLETED_DATA);
        let c = await lclStorage.getItem(ASSEMBLER_FIN_GAME_DATA);
        let d = await lclStorage.getItem(WORDSPIDER_FIN_GAME_DATA);
        let e = await lclStorage.getItem(WORDKNIT_FIN_GAME_DATA);
        let f = await lclStorage.getItem(WORDALIKE_FIN_GAME_DATA);
        let g = await lclStorage.getItem(WORDSEARCH_INP_GAME_DATA);

        let wordChainData = false;
        let wordHuntData = false;
        let assemblerData = false;
        let wordKnitData = false;
        let wordSpiderData = false;
        let wordAlikeData = false;
        let wordSearchData = false

        if (a) {
            wordChainData = JSON.parse(a).isGameOver;
        }
        if (b) {
            wordHuntData = JSON.parse(b).isGameOver;
        }
        if (c) {
            assemblerData = JSON.parse(c).isGameOver;
        }
        if (d !== null && d !== undefined) {
            wordSpiderData = JSON.parse(d).isGameOver;
        }
        if (e) {
            wordKnitData = JSON.parse(e).isGameOver;
        }
        if (f) {
            wordAlikeData = JSON.parse(f).isGameOver;
        }
        if (g) {
            wordSearchData = JSON.parse(g).gameOverReason === gameOverReason.NORMAL;
        }
        return wordChainData && wordHuntData && assemblerData && wordSpiderData && wordKnitData && wordAlikeData && wordSearchData;
    }

    hasVistedFinalScore() {
        this.hasVistedFinalPage = true;
    }

    getAllWordsPlayed = async (): Promise<string[]> => {
        let all_words_palyed: { words_played: string[]; tstmp: number }[] = [];

        //for assembler game
        let game_type: string[] = [
            WORDKNIT_FIN_GAME_DATA,
            WORDKNIT_INP_GAME_DATA,
            WORDHUNT_COMPLETED_DATA,
            TEMP_WORDHUNT_DATA,
            WORDCHAIN_COMPLETED_DATA,
            TEMP_WORDCHAIN_DATA,
            ASSEMBLER_FIN_GAME_DATA,
            ASSEMBLER_INP_GAME_DATA,
            WORDSPIDER_FIN_GAME_DATA,
            WORDSPIDER_INP_GAME_DATA,
            WORDALIKE_FIN_GAME_DATA,
            WORDALIKE_INP_GAME_DATA,
            WORDSEARCH_INP_GAME_DATA,
        ];

        for (let type of game_type) {
            //for wordKnit game
            if (type.includes(WORDKNIT_FIN_GAME_DATA) || type.includes(WORDKNIT_INP_GAME_DATA)) {
                const cmp_data_word_knit: string | null = await lclStorage.getItem(type);
                if (cmp_data_word_knit) {
                    let cmp_parse_data: GameOverWordKnitComplDataType | TempWordKnitDataType = JSON.parse(cmp_data_word_knit);
                    all_words_palyed.push({
                        words_played: [...cmp_parse_data.wordsFound].reverse(),
                        tstmp: cmp_parse_data.tstmp,
                    });
                }
            }

            //for wordHunt game
            if (type.includes(WORDHUNT_COMPLETED_DATA) || type.includes(TEMP_WORDHUNT_DATA)) {
                const cmp_data_word_hunt: string | null = await lclStorage.getItem(type);
                if (cmp_data_word_hunt) {
                    let cmp_parse_data: WordHuntGameCmpltdType | TempWordHuntData = JSON.parse(cmp_data_word_hunt);
                    all_words_palyed.push({
                        words_played: cmp_parse_data.data.boardData
                            .map((square) => square.map(({ letter }) => letter).join(""))
                            .reverse(),
                        tstmp: cmp_parse_data.data.tstmp,
                    });
                }
            }

            //for wordchain game
            if (type.includes(WORDCHAIN_COMPLETED_DATA) || type.includes(TEMP_WORDCHAIN_DATA)) {
                const cmp_data_word_chain: string | null = await lclStorage.getItem(type);
                if (cmp_data_word_chain) {
                    let cmp_parse_data: WordChainGameCmpltdType | TempWordChainData = JSON.parse(cmp_data_word_chain);
                    let words_cmp: string[] = [];
                    let curr_tstmp: number = 0;
                    if ("data" in cmp_parse_data) {
                        curr_tstmp = cmp_parse_data.data.tstmp;
                        cmp_parse_data.data.word_store.forEach(({ words }) => words_cmp.push(...words));
                    } else {
                        curr_tstmp = cmp_parse_data.tstmp;
                        cmp_parse_data.word_comb.forEach(({ words }) => words_cmp.push(...words));
                    }
                    all_words_palyed.push({ words_played: words_cmp.reverse(), tstmp: curr_tstmp });
                }
            }

            //for assembler game
            if (type.includes(ASSEMBLER_FIN_GAME_DATA) || type.includes(ASSEMBLER_INP_GAME_DATA)) {
                const cmp_data_assembler: string | null = await lclStorage.getItem(type);
                if (cmp_data_assembler) {
                    let cmp_parse_data: AssemblerFinGameType | AssemblerInpGameType = JSON.parse(cmp_data_assembler);
                    let words_cmp: string[] = [];
                    let curr_tstmp: number = 0;
                    if ("data" in cmp_parse_data) {
                        curr_tstmp = cmp_parse_data.data.tstmp;
                        words_cmp = [...cmp_parse_data.data.wordsFound];
                    } else {
                        curr_tstmp = cmp_parse_data.tstmp;
                        words_cmp = [...cmp_parse_data.wordsFound];
                    }
                    all_words_palyed.push({ words_played: words_cmp.reverse(), tstmp: curr_tstmp });
                }
            }

            //for wordSpider game
            if (type.includes(WORDSPIDER_FIN_GAME_DATA) || type.includes(WORDSPIDER_INP_GAME_DATA)) {
                const cmp_data_wordSpider: string | null = await lclStorage.getItem(type);
                if (cmp_data_wordSpider) {
                    let cmp_parse_data: TempWordSpiderData = JSON.parse(cmp_data_wordSpider);
                    all_words_palyed.push({
                        words_played: [...cmp_parse_data.wordsFound].reverse(),
                        tstmp: cmp_parse_data.tstmp!,
                    });
                }
            }

            //for wordAlike game
            if (type.includes(WORDALIKE_FIN_GAME_DATA) || type.includes(WORDALIKE_INP_GAME_DATA)) {
                const cmp_data_wordAlike: string | null = await lclStorage.getItem(type);
                if (cmp_data_wordAlike) {
                    let cmp_parse_data: WordAlikeInpGameType | WordAlikeFinGameType = JSON.parse(cmp_data_wordAlike);
                    all_words_palyed.push({
                        words_played: [...cmp_parse_data.wordsFound].reverse(),
                        tstmp: cmp_parse_data.tstmp!,
                    });
                }
            }

            //for wordSearch game
            if (type.includes(WORDSEARCH_INP_GAME_DATA)) {
                const wordSearch_data: string | null = await lclStorage.getItem(WORDSEARCH_INP_GAME_DATA);
                if (wordSearch_data) {
                    let parse_wordSearch_data: RJWordSearchInpGameType = JSON.parse(wordSearch_data);
                    all_words_palyed.push({
                        words_played: [...parse_wordSearch_data.wordsFound].reverse(),
                        tstmp: parse_wordSearch_data.tstmp,
                    });
                }
            }
        }

        return all_words_palyed
            .sort(function (a, b) {
                return b.tstmp - a.tstmp;
            })
            .flatMap(({ words_played }) => words_played);
    };

    private sortWords(words: string[]) {
        let required_words: string[] = [];
        let words_split_with_length: { [length: number]: string[] } = {};
        words.forEach((word) => {
            const word_length: number = word.split("").length;
            if (words_split_with_length[word_length]) {
                words_split_with_length[word_length].push(word);
            } else {
                words_split_with_length[word.split("").length] = [word];
            }
        });
        Object.keys(words_split_with_length)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .forEach((key) => {
                required_words.push(...words_split_with_length[key as unknown as number].sort());
            });
        return required_words;
    }

    getMostRecentPuzzleAnswers = () => {
        let wordhunt_data: string | undefined = this.previous_data_store?.data.wordhunt.finword;

        let assembler_data: string[] | undefined = this.previous_data_store?.data.assembler.wordcolumns;
        if (assembler_data) {
            assembler_data = this.sortWords(assembler_data);
        }

        let wordknit_data: string[] = getAllWordsToBeMade(this.previous_data_store?.data.wordknit.blocks);
        wordknit_data = this.sortWords(wordknit_data);

        let wordchain_data: string[] = this.getWordsFormed(this.previous_data_store?.data?.wordchain?.alphabets ?? []);
        wordchain_data = this.sortWords(wordchain_data);

        let wordalike_data: string[] | undefined = this.previous_data_store?.data.wordalike?.synonym;
        if (wordalike_data) {
            wordalike_data = this.sortWords(wordalike_data);
        }

        let wordsearch_data: string[] | undefined = this.previous_data_store?.data.wordsearch?.valword.split(',')
        if (wordsearch_data) {
            wordsearch_data = this.sortWords(wordsearch_data)
        }

        return {
            wordhunt: wordhunt_data,
            assembler: assembler_data,
            wordknit: wordknit_data,
            wordchain: wordchain_data,
            wordalike: wordalike_data,
            wordsearch: wordsearch_data
        };
    };
}
export const dailyWordPuzzleDataAPI = new DailyWordPuzzleDataAPI();
