import React, { useState } from "react";
import { Modal } from "../../../Component/Modal/Modal";
import ChevronLeftIcon from "../../../Assets/svg/ChevronLeftIcon.svg";
import ChevronRightIcon from "../../../Assets/svg/ChevronRightIcon.svg";
import CrossIcon from "../../../Assets/svg/CrossIcon.svg";
import WordKnitFirstTut from "../../../Assets/svg/WordKnitFirstTut.svg";
import WordKnitSecTut from "../../../Assets/svg/WordKnitSecTut.svg";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import { titleWordKnit, WORD_KNIT_FIRST_DESC_TUTORIAL, WORD_KNIT_SEC_DESC_TUTORIAL } from "../Utils/constant";

export const Tutorial = (props: { onTutorialClose: () => void }) => {
    const [displayPage, setDisplayPage] = useState<number>(1);

    const clickableButtonWithImage = (svgFile: string, clickHandler: () => void): JSX.Element => {
        return <img src={svgFile} className={style.icon} onClick={clickHandler} alt="..." />;
    };

    const getLeftIcon = (page: number): JSX.Element | null => {
        switch (page) {
            case 1:
                return <div className="w-9 h-9"></div>;
            default:
                return clickableButtonWithImage(ChevronLeftIcon, () => {
                    if (displayPage > 1) {
                        SndMngr.playSound(SoundType.RECALL);
                        setDisplayPage(displayPage - 1);
                    }
                });
        }
    };

    const getTitle = (): JSX.Element => <p className={style.title}>{titleWordKnit}</p>;

    const getRightIcon = (page: number): JSX.Element | null => {
        switch (page) {
            case 1:
                return clickableButtonWithImage(ChevronRightIcon, () => {
                    if (displayPage < 2) {
                        SndMngr.playSound(SoundType.RECALL);
                        setDisplayPage(displayPage + 1);
                    }
                });
            case 2:
                return clickableButtonWithImage(CrossIcon, () => {
                    SndMngr.playSound(SoundType.RECALL);
                    props.onTutorialClose();
                });
            default:
                return null;
        }
    };

    const getDescriptionText = (page: number): string => {
        switch (page) {
            case 1:
                return WORD_KNIT_FIRST_DESC_TUTORIAL;
            case 2:
                return WORD_KNIT_SEC_DESC_TUTORIAL;
            default:
                return "";
        }
    };

    const getImageDescription = (page: number) => {
        switch (page) {
            case 1:
                return { imgsrc: WordKnitFirstTut, style: `w-[225px] h-[225px]` };
            case 2:
                return {
                    imgsrc: WordKnitSecTut,
                    style: `w-[300px] h-[200px]`,
                };
            default:
                return {};
        }
    };

    const renderTutorialDescription = (displayPage: number): JSX.Element | null => {
        return <p className={style.text + `py-6`}>{getDescriptionText(displayPage)}</p>;
    };

    const renderTutorialImage = (displayPage: number): JSX.Element | null => {
        let description = getImageDescription(displayPage);
        return <img src={description.imgsrc} className={style.Svg + description.style} alt="" />;
    };

    return (
        <Modal>
            <div className={style.mainContainer}>
                <div className={`flex flex-row pt-8 w-[280px] justify-between`}>
                    {getLeftIcon(displayPage)}
                    {getTitle()}
                    {getRightIcon(displayPage)}
                </div>
                {renderTutorialDescription(displayPage)}
                {renderTutorialImage(displayPage)}
            </div>
        </Modal>
    );
};

const style = {
    mainContainer: "w-[350px] h-[500px] flex flex-col  rounded-3xl items-center bg-secondary-150",
    title: "font-medium text-3xl font-secular",
    text: "font-roboto text-[18px] font-medium px-8 ",
    icon: "w-9 h-9 stroke-secondary-600 cursor-pointer ",
    Svg: "flex self-center place-content-center ",
};
