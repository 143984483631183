import { ReactNode } from "react";

export const WarningButton = (props: {
    clicked: () => void;
    title: string;
    icon?: ReactNode;
    style?: string;
}) => {
    return (
        <button
            onClick={props.clicked}
            className={
                `flex self-center p-2 text-xl gap-1 bg-warning-400 hover:bg-primary-700  text-white font-roboto font-medium  rounded-[8px]` +
                props.style
            }
        >
            {props.title}
            {props.icon}
        </button>
    );
};
