import { useState, useEffect } from "react";
import WordChainLogo from "../../Assets/svg/WordChainLogo.svg";
import WordHuntLogo from "../../Assets/svg/WordHuntLogo.svg";
import AssemblerLogo from "../../Assets/svg/AssemblerLogo.svg";
import WordSpiderLogo from "../../Assets/svg/WordSpiderLogo.svg";
import WordKnitLogo from "../../Assets/svg/WordKnitLogo.svg";
import WordMeaningIcon from "../../Assets/svg/WordMeaningIcon.svg";
import WordAlikeLogo from "../../Assets/svg/WordAlikeLogo.svg";
import WordSearchLogo from "../../Assets/svg/WordSearchLogo.svg";
import Header from "../../Component/Header/Header";
import {
    kPlay,
    pathWordChain,
    titleIntro,
    // kPlayMgs,
    pathIntro,
    pathWordHunt,
    pathAssembler,
    WORDCHAIN_COMPLETED_DATA,
    WORDHUNT_COMPLETED_DATA,
    ASSEMBLER_FIN_GAME_DATA,
    titleWordChain,
    titleWordHunt,
    titleAssembler,
    DAILY_WORD_PUZZLE_DATA,
    FETCH_ERR_MSG,
    FAILURE,
    kPlayAgain,
    pathWordMeaning,
    titleWordMeaning,
    kView
} from "../../Commons/constant";
import { titleWordSpider, pathWordSpider } from "../WordSpider/Utils/Constants";
import { WORDSPIDER_FIN_GAME_DATA } from "../WordSpider/Utils/Constants";
import { SuccessButton } from "../../Component/Buttons/SuccessButton";
// import LockClosedIcon from "../../Assets/svg/LockClosedIcon.svg";
import RightIcon from "../../Assets/svg/RightIcon.svg";
import { useNavigate } from "react-router-dom";
import { PopupUp } from "../../Component/PopUp/PopupUp";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import lclStorage from "../../Utils/LocalStorage";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import useTimer from "../../Hooks/useTimer";
// import Timer from "../../Component/Timer/Timer";
import { useTimerType } from "../../Commons/types";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import { WORDKNIT_FIN_GAME_DATA, pathWordKnit, titleWordKnit } from "../WordKnit/Utils/constant";
import { pathWordAlike, titleWordAlike, WORDALIKE_FIN_GAME_DATA } from "../WordAlike/Utils/constants";
import { titleWordSearch, pathWordSearch, WORDSEARCH_INP_GAME_DATA } from "../WordSearch/Constants";
import { gameOverReason } from "../WordSearch/Types";

export const IndexOfGame = () => {
    const navigate = useNavigate();
    const [showHelp, setShowHelp] = useState(false);
    const [showPopup, setShowPopUp] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [popMsg, setPopMsg] = useState("");
    const [GameOverData, setGameOverData] = useState<{
        [key: string]: boolean;
    }>({
        wordChain: false,
        WordHunt: false,
        assembler: false,
        wordKnit: false,
        wordSpider: false,
        wordAlike: false,
        wordSsearch:false
    });
    const { startTimer, remainingTime, isTimeEnd }: useTimerType = useTimer(false);

    useEffect(() => {
        let promise1 = lclStorage.getItem(WORDCHAIN_COMPLETED_DATA);
        let promise2 = lclStorage.getItem(WORDHUNT_COMPLETED_DATA);
        let promise3 = lclStorage.getItem(ASSEMBLER_FIN_GAME_DATA);
        let promise4 = lclStorage.getItem(DAILY_WORD_PUZZLE_DATA);
        let promise5 = dailyWordPuzzleDataAPI.getValidWords();
        let promise6 = lclStorage.getItem(WORDSPIDER_FIN_GAME_DATA);
        let promise7 = lclStorage.getItem(WORDKNIT_FIN_GAME_DATA);
        let promise8 = lclStorage.getItem(WORDALIKE_FIN_GAME_DATA)
        let promise9 = lclStorage.getItem(WORDSEARCH_INP_GAME_DATA)
        let wordChainData = false;
        let WordHuntData = false;
        let assemblerData = false;
        let wordSpiderData = false;
        let wordKnitData = false;
        let wordAlikeData = false
        let wordSearchData = false

        Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9]).then(
            async ([a, b, c, d, e, f, g, h,i ]) => {
                if (a !== null && a !== undefined) {
                    wordChainData = JSON.parse(a).isGameOver;
                }
                if (b !== null && b !== undefined) {
                    WordHuntData = JSON.parse(b).isGameOver;
                }
                if (c !== null && c !== undefined) {
                    assemblerData = JSON.parse(c).isGameOver;
                }
                if (f !== null && f !== undefined) {
                    wordSpiderData = JSON.parse(f).isGameOver;
                }
                if (g) {
                    wordKnitData = JSON.parse(g).isGameOver;
                }

                if (h) {
                    wordAlikeData = JSON.parse(h).isGameOver
                }

                if (i) {
                    wordSearchData = JSON.parse(i).gameOverReason === gameOverReason.NORMAL;
                }


                if (d !== null && d !== undefined && e !== null && e !== undefined) {
                    let gameData = JSON.parse(d);
                    let wordData = e;
                    let isErrInFetch =
                        gameData.check === FAILURE || wordData.check === FAILURE || !gameData.data || !wordData.dictionary;

                    if (isErrInFetch || (!isErrInFetch && gameData.data.length < 1)) {
                        setShowPopUp(true);
                        setPopMsg(FETCH_ERR_MSG);
                    }

                    setGameOverData({
                        wordChain: wordChainData,
                        WordHunt: WordHuntData,
                        assembler: assemblerData,
                        wordSpider: wordSpiderData,
                        wordKnit: wordKnitData,
                        wordAlike: wordAlikeData,
                        wordSearch: wordSearchData
                    });
                }
            }
        );
        dailyWordPuzzleDataAPI.isAllGameOver().then((data) => {
            if (data) {
                setShowTimer(true);
                getNextGameAvailTime();
            } else {
                setShowTimer(false);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isTimeEnd) {
            navigate(pathIntro);
            setShowTimer(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTimeEnd]);

    const getNextGameAvailTime = () => {
        const convertTZ = (date: any, tzString: string) => {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
        };

        let timeResult = convertTZ(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone);

        var d = timeResult;
        var h = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        var secondsUntilEndOfDate = 24 * 60 * 60 - h * 60 * 60 - m * 60 - s;

        startTimer(secondsUntilEndOfDate);
    };

    const onClosePopup = () => {
        if (popMsg === FETCH_ERR_MSG) {
            window.location.reload();
            navigate(pathIntro);
        }
        setShowPopUp(false);
    };

    const renderPlayButton = (pageName: string) => {
        return (
            <SuccessButton
                title={kPlay}
                style={`absolute top-[-20px]  text-[27px] font-medium  font-secular tracking-[0.155em]`}
                clicked={() => {
                    SndMngr.playSound(SoundType.CLICKBTN);
                    navigate(pageName);
                }}
            />
        );
    };

    // const renderLockModal = () => {
    //     return (
    //         <div
    //             onClick={() => {
    //                 SndMngr.playSound(SoundType.CLICKBTN);
    //                 setShowPopUp(true);
    //                 setPopMsg(kPlayMgs);
    //             }}
    //             className={`absolute cursor-pointer w-[155px] h-[146px] rounded-xl bg-overlay-100 ${styles.lockStyle}`}
    //         >
    //             <img src={LockClosedIcon} className={styles.lockIconStyle} alt="lockClosedIcon" />
    //         </div>
    //     );
    // };
    const renderViewButton = (pageName: string) => {
        return (
            <SuccessButton
                title={kView}
                style={`absolute top-[-20px] w-[120px] text-[27px] font-medium  font-secular tracking-[0.155em]`}
                clicked={() => {
                    SndMngr.playSound(SoundType.CLICKBTN);
                    navigate(pageName);
                }}
            />
        );
    };

    const renderCompletedModal = (pageName: string) => {
        return (
            <div
                onClick={() => {
                    SndMngr.playSound(SoundType.CLICKBTN);
                    navigate(pageName);
                }}
                className={`absolute cursor-pointer rounded-xl bg-success-300/50 top-[4px] left-[5px] ${styles.successStyle}`}
            >
                <img src={RightIcon} className={styles.rightIconStyle} alt ="righticon"/>
            </div>
        );
    };

    const renderPlayOrModal = (data: string) => {
        // if (data === titleWordChain && GameOverData.wordChain) {
        //     return renderCompletedModal(pathWordChain);
        // } else if (data === titleWordHunt) {
        //     if (GameOverData.WordHunt) {
        //         return renderCompletedModal(pathWordHunt);
        //     } else if (GameOverData.wordChain) {
        //         return renderPlayButton(pathWordHunt);
        //     } else {
        //         return renderLockModal();
        //     }
        // } else if (data === titleAssembler) {
        //     if (GameOverData.assembler) {
        //         return renderCompletedModal(pathAssembler);
        //     } else if (GameOverData.WordHunt) {
        //         return renderPlayButton(pathAssembler);
        //     } else {
        //         return renderLockModal();
        //     }
        // } else {
        //     return renderPlayButton(pathWordChain);
        // }

        if (data === titleWordChain) {
            if (GameOverData.wordChain) {
                return renderCompletedModal(pathWordChain);
            } else {
                return renderPlayButton(pathWordChain);
            }
        } else if (data === titleWordHunt) {
            if (GameOverData.WordHunt) {
                return renderCompletedModal(pathWordHunt);
            } else {
                return renderPlayButton(pathWordHunt);
            }
        } else if (data === titleAssembler) {
            if (GameOverData.assembler) {
                return renderCompletedModal(pathAssembler);
            } else {
                return renderPlayButton(pathAssembler);
            }
        } else if (data === titleWordSpider) {
            if (GameOverData.wordSpider) {
                return renderCompletedModal(pathWordSpider);
            } else {
                return renderPlayButton(pathWordSpider);
            }
        } else if (data === titleWordKnit) {
            if (GameOverData.wordKnit) {
                return renderCompletedModal(pathWordKnit);
            } else {
                return renderPlayButton(pathWordKnit);
            }
        } else if (data === titleWordAlike) {
            if (GameOverData.wordAlike) {
                return renderCompletedModal(pathWordAlike);
            } else {
                return renderPlayButton(pathWordAlike);
            }
        } else if (data === titleWordSearch) {
            if (GameOverData.wordSearch) {
                return renderCompletedModal(pathWordSearch);
            } else {
                return renderPlayButton(pathWordSearch);
            }
        }
    };

    const renderGameAvailTimer = () => {
        return showTimer ? (
            <div className="flex flex-row justify-center items-center mt-4">
                <p className="font-roboto text-secondary-600 font-normal self-center m-2 text-[21px]">{kPlayAgain}</p>
                <div className="flex flex-row font-roboto font-extrabold h-10 place-items-center self-center p-3">
                    <p className={styles.text}>{remainingTime.hr.toString().padStart(2, "0")}h</p>
                    <p className={styles.text}>{remainingTime.min.toString().padStart(2, "0")}m</p>
                </div>
            </div>
        ) : null;
    };

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    return (
        <div className="flex flex-col h-full">
            <Header
                title={titleIntro}
                setMenuIsLoading={onClosePopup}
                onMenuHintClicked={() => {}}
                onMenuStatsClicked={() => {}}
                onMenuHelpClicked={() => {
                    handleShow();
                }}
            />
            {showHelp && <HelpDeskPopup closeHelp={handleClose} />}
            {showPopup && <PopupUp msg={popMsg} btnText={"OK"} setShowMenu={onClosePopup} />}
            {renderGameAvailTimer()}

            <div className="flex flex-col  h-full justify-center items-center overflow-y-auto  mobile:scrollbar">
                <div className="flex flex-col self-center h-[90%] py-10">
                    <div className="flex flex-row pb-8 ">
                        <div className="flex flex-col mr-8 relative cursor-pointer">
                            {renderPlayOrModal(titleAssembler)}
                            <img
                                src={AssemblerLogo}
                                alt="AssemblerLogo"
                                className={styles.svgStyle}
                                onClick={() => {
                                    SndMngr.playSound(SoundType.CLICKBTN);
                                    navigate(pathAssembler);
                                }}
                            />
                            <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                {titleAssembler}
                            </p>
                        </div>
                        <div className="flex flex-col relative cursor-pointer">
                            {renderPlayOrModal(titleWordHunt)}
                            <img
                                src={WordHuntLogo}
                                alt="WordHuntLogo"
                                className={styles.svgStyle}
                                onClick={() => {
                                    SndMngr.playSound(SoundType.CLICKBTN);
                                    navigate(pathWordHunt);
                                }}
                            />
                            <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                {titleWordHunt}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row pb-8 ">
                        <div className="flex flex-row mr-8">
                            <div className="flex flex-col relative cursor-pointer">
                                {renderPlayOrModal(titleWordSpider)}
                                <img
                                    src={WordSpiderLogo}
                                    alt="WordSpiderLogo"
                                    className={styles.svgStyle}
                                    onClick={() => {
                                        SndMngr.playSound(SoundType.CLICKBTN);
                                        navigate(pathWordSpider);
                                    }}
                                />
                                <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                    {titleWordSpider}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-col relative cursor-pointer">
                                {renderPlayOrModal(titleWordKnit)}
                                <img
                                    src={WordKnitLogo}
                                    alt="WordKnitLogo"
                                    className={styles.svgStyle}
                                    onClick={() => {
                                        SndMngr.playSound(SoundType.CLICKBTN);
                                        navigate(pathWordKnit);
                                    }}
                                />
                                <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                    {titleWordKnit}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row pb-8 ">
                        <div className="flex flex-row mr-8">
                            <div className="flex flex-col relative cursor-pointer">
                                {renderPlayOrModal(titleWordAlike)}
                                <img
                                    src={WordAlikeLogo}
                                    alt="WordAlikeLogo"
                                    className={styles.svgStyle}
                                    onClick={() => {
                                        SndMngr.playSound(SoundType.CLICKBTN);
                                        navigate(pathWordAlike);
                                    }}
                                />
                                <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                    {titleWordAlike}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-col relative cursor-pointer">
                                {renderPlayOrModal(titleWordSearch)}
                                <img
                                    src={WordSearchLogo}
                                    alt="WordSearchLogo"
                                    className={styles.svgStyle}
                                    onClick={() => {
                                        SndMngr.playSound(SoundType.CLICKBTN);
                                        navigate(pathWordSearch);
                                    }}
                                />
                                <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                    {titleWordSearch}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row pb-8 ">
                        <div className="flex flex-row mr-8">
                            <div className="flex flex-col relative cursor-pointer">
                                {renderPlayOrModal(titleWordChain)}
                                <img
                                    src={WordChainLogo}
                                    alt="WordChainLogo"
                                    className={styles.svgStyle}
                                    onClick={() => {
                                        SndMngr.playSound(SoundType.CLICKBTN);
                                        navigate(pathWordChain);
                                    }}
                                />
                                <p className="font-secular text-info-400 font-medium text-center m-2 text-[21px]">
                                    {titleWordChain}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col mr-8 relative cursor-pointer">
                            {renderViewButton(pathWordMeaning)}
                            <img
                                src={WordMeaningIcon}
                                alt="WordMeaning"
                                className={styles.svgStyle}
                                onClick={() => {
                                    SndMngr.playSound(SoundType.CLICKBTN);
                                    navigate(pathWordMeaning);
                                }}
                            />
                            <p className="font-secular text-info-400 font-medium  text-center m-2 text-[20px]">
                                {titleWordMeaning}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    svgStyle: "w-[155px] h-[146px] lg:w-[180px] lg:h-[175px]",
    lockStyle: "w-[155px] h-[146px] lg:w-[179px] lg:h-[169px] lg:top-[4px]",
    successStyle: "w-[145px] h-[137px] lg:w-[170px] lg:h-[161px] lg:top-[7px]",
    rightIconStyle: " absolute stroke-white top-9 left-12 lg:left-13  w-[60px] lg:w-[80px]  lg:top-10 ",
    lockIconStyle: "absolute w-[70px] h-[70px] lg:w-[90px] lg:h-[90px] top-10 left-12 lg:left-13 ",
    text: "text-black text-[18px] p-1",
};
