import React from "react";
import { SndMngr, SoundType } from "../../Utils/SoundManager";

const height=window.innerHeight;

class WordSpiderTile extends React.Component <any> {
  state = {};

  onTilePress = () => {
    if (this.props.isEndTile) {
      SndMngr.playSound(SoundType.CLICKBTN);
      if (this.isTileSelected()) {
        this.props.onTileSelect(
          this.props.tileData.cindex,
          this.props.tileData.index,
          false,
        );
      } else {
        SndMngr.playSound(SoundType.CLICKBTN);
        this.props.onTileSelect(
          this.props.tileData.cindex,
          this.props.tileData.index,
          true,
        );
      }
    }
  };

  isTileSelected = () =>
    this.props.selectedTiles.filter((item : number ) => item === this.props.tileData.cindex)
      .length > 0 && this.props.isEndTile;

  render = () => {
    return this.props.default ? (
      <div
      className={`
          ${styles.tileContainer}
          ${`w-${height * 0.06}px h-${height * 0.06}px `}
        `}
      />
    ) : (
    <div
        className={`
        ${this.props.isEndTile ? 'bg-primary-700' : 'bg-secondary-300'} 
          ${styles.tileContainer} 
        ${this.isTileSelected() ? ' bg-info-400' : ''}
        `}
        key={this.props.tileData.letter + '-' + this.props.tileData.index}
        onClick={this.onTilePress}>
      <div className='flex'>
          <p className={`text-[${height * 0.02}px] ${this.props.isEndTile ? 'text-white' : 'text-overlay-200'}  font-roboto font-light md:font-medium sm:font-normal text-base md:text-xl sm:text-lg md:leading-[40px] sm:leading-[36px] leading-[30px] ml-1 sm:ml-1 md:ml-2`}>
          {this.props.tileData.letter}
          </p>
         <div className={styles.tileScore}>
           <p className={`text-[${height * 0.02}px] ${this.props.isEndTile ? 'text-white' : 'text-overlay-200'} mt-3 font-roboto text-sm`}>
            {this.props.tileData.score}
           </p>
         </div>
       </div>
      </div >
      );
  };
}

const styles = {
  tileContainer: "pl-2 pt-1 md:h-[52px] md:w-[52px] sm:h-[44px] sm:w-[44px] h-[40px] w-[40px] rounded mb-1 cursor-pointer ",
  tileScore: "absolute md:ml-5 md:mt-2 sm:ml-4 sm:mt-1 ml-4",
}

export default WordSpiderTile;


