import { useState } from "react";
import { ToastType, TOASTVARIANT } from "../Component/Toast/Toast";
import produce from "immer";

export interface UseToastType {
    addToast: () => void;
    toast: Array<ToastType>;
}

const TIMER = 1500;
export const useToast = () => {
    let [toast, setToast] = useState<Array<ToastType>>([]);

    const removeToast = (priority: number) => {
        setToast(
            produce((toast) => {
                const index = toast.findIndex(
                    (toastEle) => toastEle.priority === priority
                );
                if (index !== -1) toast.splice(index, 1);
            })
        );
    };

    const addToast = (variant: TOASTVARIANT, message: string) => {
        const priority = toast.length;
        const set_timeout = setTimeout(() => {
            removeToast(priority);
        }, TIMER);

        setToast(
            produce((toast) => {
                toast.push({
                    variant,
                    message,
                    priority,
                    clicked: () => {
                        removeToast(priority);
                        clearTimeout(set_timeout);
                    },
                });
            })
        );
    };

    return { addToast, toast };
};
