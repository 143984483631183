import { ConvertStyle } from "../../Utils/ConvertStyle";
import Fade from "../Animation/Fade";
import RightIcon from "../../Assets/svg/RightIcon.svg";
export interface ToastType {
    variant: TOASTVARIANT;
    message: string;
    priority?: number;
    clicked?: () => void;
}
export enum TOASTVARIANT {
    SUCCESS = "fill-success-300",
    DANGER = "fill-danger-600",
    WARNING = "fill-danger-600",
    NOICON = "",
}

export const Toast = (props: { toast: Array<ToastType> }) => {
    const renderVariantLogo = (variant: TOASTVARIANT) => {
        let variant_jsx: JSX.Element = <></>;
        switch (variant) {
            case TOASTVARIANT.SUCCESS:
                variant_jsx = (
                    <img
                        src={RightIcon}
                        className={ConvertStyle([
                            styles.logo,
                            TOASTVARIANT.SUCCESS,
                        ])}
                        alt="right icon"
                    />
                );
                break;
            default:
                break;
        }
        return variant_jsx;
    };

    const renderToast = () => {
        let toast_jsx: Array<JSX.Element> = props.toast.map((toast, index) => {
            return (
                <Fade key={index}>
                    <div className={styles.subContainer}>
                        <p className={styles.text}>{toast.message}</p>
                        {/* <CrossIcon style={styles.crossIcon} clicked={toast.clicked} /> */}
                        {renderVariantLogo(toast.variant)}
                    </div>
                </Fade>
            );
        });
        return toast_jsx;
    };
    return (
        <>
            <div className={styles.mainContainer}>{renderToast()}</div>
        </>
    );
};

const styles = {
    mainContainer: "flex flex-col w-full items-center absolute top-40 z-[999]",
    subContainer: " rounded-xl  bg-warning-400 p-[10px]",
    crossIcon:
        "w-10 h-10 stroke-black rounded-lg hover:bg-gray-800 hover:stroke-gray-100  cursor-pointer",
    text: "self-center font-roboto font-extrabold text-black tracking-[1px] text-[20px] px-4 py-2 rounded bg-white",
    logo: `w-8 h-8  rounded-lg  stroke-white`,
};
