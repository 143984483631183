export const DAILY_WORD_PUZZLE_API_ACTION = "action=getpuzzle";

export const titleIntro = "SimpleWordGames.com";
export const titleWordChain = "Word Chain";
export const titleWordHunt = "Word Hunt";
export const titleAssembler = "Assembler";
export const titleWordMeaning = "Word Meaning";
export const cacheWordMeaningSeconds = 259200 


export const KEYBOARDLETTER = [
    "Q",
    "W",
    "E",
    "R",
    "T",
    "Y",
    "U",
    "I",
    "O",
    "P",
    "A",
    "S",
    "D",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "ENTER",
    "Z",
    "X",
    "C",
    "V",
    "B",
    "N",
    "M",
    "BACKSPACE",
];

export const kHome = "Home";
export const kInformation = "Information";
export const kHelpDesk = "Help Desk";
export const kStatistic = "Game Statistics";
export const kHint = "Hint";
export const kStart = "Start";
export const kGameOver = "Game Over !!";
export const kScore = " Score : ";
export const kShare = "SHARE";
export const kNextGame = "Next Game";
export const kPreviousGame = "Previous Game";
export const kPlayGame = "Play Game";
export const kPlay = "Play";
export const kView = "View";
export const kPlayMgs = "Finish the previous puzzle to\nactivate this one.";
export const kWordsLeft = "words left";
export const kCompleted = "Completed!";
export const kFinalScore = "FinalScore";
export const kContinue = "Continue";
export const kCongrats = "Congratulations!";
export const kPlayAgain = "New puzzle after";
export const kHelpDeskMsg =
    "Hope you are enjoying these games. Please share your feedback with us so we can do better.";
export const kHelpDeskChatLink = "https://wa.me/+919874566001";
export const kSumbit = "Submit"
export const kWordsFound = "Words"
export const kPreDayWord = "Words in Last Day"

//-------------------------- PATHS ----------------------------
export const pathIntro = "/";
export const pathIndexOfGame = "/Pages/IndexOfGame";
export const pathWordChain = "/Pages/WordChain";
export const pathWordHunt = "/Pages/WordHunt";
export const pathAssembler = "/Pages/Assembler";
export const pathFinalScore = "/Pages/FinalScore";
export const pathWordMeaning = "/Pages/WordMeaning";


//---------------------------TUTORIAL ------------------------
export const wrdChnTutorial1 =
    "Swipe across letters to create as many words as you can";
export const wrdChnTutorial2 = "Longer words get you more points!";
export const wrdleTutorial1 = "You have 6 tries to find the hidden word.";
export const wrdleTutorial2 = "After you type out your guess, ";
export const wrdleTutorial3 = "Color guide";
export const wrdleSvgText1 = "Word contains letter in this position";
export const wrdleSvgText2 = "Word contains this letter, different position";
export const wrdleSvgText3 = "Word does not contain this letter";

export const assemblertTutTxt1 = "Slide letter columns up/down";
export const assemblertTutTxt2 = "Form a word between the two grey bars";
export const assemblertTutTxt3 =
    "Change the colour of each letter to complete the puzzle";

export const DAILY_WORD_PUZZLE_DATA = "DAILY_WORD_PUZZLE_DATA";
export const VALID_WORDS_DATA = "VALID_WORDS_DATA";
export const PRE_DAILY_WORD_PUZZLE_DATA = "pre_daily_word_puzzle_data"

export const TEMP_WORDCHAIN_DATA = "TEMP_WORDCHAIN_DATA";
export const WORDCHAIN_COMPLETED_DATA = "WORDCHAIN_COMPLETED_DATA";

export const TEMP_WORDHUNT_DATA = "Temp_WordHunt_Data";
export const WORDHUNT_COMPLETED_DATA = "WordHunt_Completed_Data";

export const ASSEMBLER_INP_GAME_DATA = "assembler_inp_game_data";
export const ASSEMBLER_FIN_GAME_DATA = "assembler_fin_game_data";

export const WORDMEANING_ALL_DATA = "WordMeaning_all_data";

export const SHOW_TUTORIAL_ONCE = "SHOW_TUTORIAL_ONCE";

export const FETCH_ERR_MSG =
    "Error While Fetching Data \n Please Retry After Sometime";
export const FAILURE = "failure";
export const COMPLETED_GAME_MSG = "Today's challenge completed";

export const DICTIONARY_DATA_URL = "./assets/sow_6.txt"

export const Emoji = {
    PR_NPL: "🤔",
    NPR_NPL: "⛔",
    PR_PL: "😎",
};