export enum LINK_DIR {
    LEFT = "lt",
    TOP = "tp",
    RIGHT = "rt",
    BOTTOM = "bt",
}
export enum TILE_BG_COLOR {
    SUCCESS = "bg-success-300",
    SECONDARY = "bg-secondary-300",
}

export enum TILE_LETTER_COLOR {
    SUCCESS = "text-white",
    SECONDARY = "text-secondary-600",
}

export enum TILE_BORDER_COLOR {
    SUCCESS = "success-370",
    SECONDARY = "secondary-450",
}

export const WORDKNIT_INP_GAME_DATA = "wordknit_inp_game_data";
export const WORDKNIT_FIN_GAME_DATA = "wordknit_fin_game_data";

export const titleWordKnit = "Word Knit";
export const pathWordKnit = "/Pages/WordKnit";

export const SCRAMBLED_BOARD_SIZE = {
    minWidth: "min-w-[100px]",
    minHeight: "min-h-[100px]",
    maxWidth: "max-w-[500px]",
    maxHeight: " max-h-[500px]",
};

export enum TILE_EVENTS {
    EVT_ON_INIT = "EVT_ON_INIT",
    EVT_ON_DRAG = "EVT_ON_DRAG",
    EVT_ON_DRAG_END = "EVT_ON_DRAG_END",
}

export enum DIRECTION {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    TOP = "TOP",
    BUTTON = "BUTTON",
}

export const WORD_KNIT_FIRST_DESC_TUTORIAL = "Green tiles are fixed on the board and in the correct position.";
export const WORD_KNIT_SEC_DESC_TUTORIAL = "Move the grey tiles across the board to form meaningful word ";
