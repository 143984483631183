import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    RootState,
    SelectedWordType,
    useSVGPolylineType,
} from "../../../Commons/types";
import {
    insertSelectedLetter,
    updateSelection,
    removeLastLetter,
} from "../SelectedWord/selectedWord-slice";
import { useDrag } from "@use-gesture/react";
import useSVGPolyline from "../../../Hooks/useSVGpolyline";
import SVGPloyLine from "../../../Component/SVG/PloyLine";
import { ConvertStyle } from "../../../Utils/ConvertStyle";
import { dailyWordPuzzleDataAPI } from "../../Introduction/api/DailyWordPuzzleDataAPI";
import { animated, useSprings } from "react-spring";
import ShuffleIcon from "../../../Assets/svg/ShuffleIcon.svg";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import ReactTooltip from "react-tooltip";

enum STRUCTURE {
    TRIANGLE = 3,
    DIAMOND = 4,
    PENTAGON = 5,
    HEXAGON = 6,
}

enum GRIDARRAYSIZE {
    SIZE = 25,
}
const EACH_COLUMN_SIZE = 5;
let TRACE_HOP: string[] = [];

const LetterInCircel = (props: { isGameCmpltd: boolean }) => {
    const handleStartPoint = () => {};

    const dispatch = useDispatch();

    let [alphabets, setAlphabets] = useState<string[]>([]);
    const [shuffleAnimation, shuffleAnimationAPI] = useSprings(
        alphabets.length,
        (index) => ({
            x: 0,
            y: 0,
        })
    );
    const circleBloardRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        dailyWordPuzzleDataAPI.wordChainData().then((wordChainData) => {
            if (wordChainData) {
                setAlphabets(wordChainData.alphabets);
            }
        });
    }, []);

    const selected_word: SelectedWordType = useSelector(
        (state: RootState) => state.selectedWord
    );

    let {
        insertNewSVGPoint,
        updateLastSVGPoint,
        resetSVGPoint,
        deleteLastSVGPoint,
        points,
    }: useSVGPolylineType = useSVGPolyline();

    const renderLetterInCircel = () => {
        let letter_jsx = [];

        for (let i = 0; i < GRIDARRAYSIZE.SIZE; i++) {
            letter_jsx.push(
                <div
                    className={ConvertStyle([
                        "h-10 sm:h-10 lg:h-12",
                        styles.responsive,
                    ])}
                    key={i}
                ></div>
            );
        }

        //injecting our letter position
        const position = getPositionOfLetter();
        const btn_jsx = getLetterInButtton();
        position?.forEach((pos, index) => {
            letter_jsx[pos] = btn_jsx[index];
        });

        //add shuffle icon in middle
        const middle_index: number = Math.floor(GRIDARRAYSIZE.SIZE / 2);
        letter_jsx[middle_index] = getShuffleIcon(middle_index);

        return (
            <div
                className="grid grid-cols-5 grid-flow-row"
                ref={circleBloardRef}
            >
                {letter_jsx.map((letter) => letter)}
            </div>
        );
    };

    const getLetterInButtton = (): Array<JSX.Element> => {
        handleStartPoint();
        let btn_jsx: Array<JSX.Element>;
        btn_jsx = alphabets.map((letter, index) => {
            return (
                <animated.button
                    {...onLineDraw()}
                    className={ConvertStyle([
                        styles.letterInCircel,
                        styles.responsive,
                    ])}
                    style={shuffleAnimation[index]}
                    key={` ${index}`}
                    id={`btn-${letter}-${index}`}
                    value={letter}
                >
                    {letter}
                </animated.button>
            );
        });
        return btn_jsx;
    };

    const getShuffleIcon = (middle_index: number): JSX.Element => {
        return (
            <img
                src={ShuffleIcon}
                data-tip={"Shuffle"}
                className={`w-10 h-10 cursor-pointer ` + styles.responsive}
                onClick={onShuffleClick()}
                alt="shuffle"
                key={middle_index}
            />
        );
    };

    const onShuffleClick = () => {
        let timer: NodeJS.Timeout;
        let shuffle_alphabets: string[] = [];
        return () => {
            clearTimeout(timer);
            SndMngr.playSound(SoundType.SHUFFLE);
            let curr_tile_pos: number[] | null = getPositionOfLetter();
            if (curr_tile_pos) {
                //calculate tile size
                const tile_size: number = (
                    circleBloardRef?.current?.childNodes[0] as HTMLDivElement
                ).getBoundingClientRect().width;
                //begin shuffle
                let next_tile_pos: number[] = shuffleArray(curr_tile_pos);
                const new_shuffle_pos = curr_tile_pos.map(
                    (pre_pos: number, index: number) => {
                        let displacement_y: number = 0;
                        let displacement_x: number = 0;
                        let next_idx: number = next_tile_pos[index];
                        if (pre_pos !== next_idx) {
                            let x_curr = pre_pos % EACH_COLUMN_SIZE;
                            let y_curr = Math.floor(pre_pos / EACH_COLUMN_SIZE);

                            let x_next = next_idx % EACH_COLUMN_SIZE;
                            let y_next = Math.floor(
                                next_idx / EACH_COLUMN_SIZE
                            );

                            displacement_x = x_next - x_curr;
                            displacement_y = y_next - y_curr;
                        }
                        return {
                            displacement_x: displacement_x * tile_size,
                            displacement_y: displacement_y * tile_size,
                        };
                    }
                );
                //end shuffle
                shuffleAnimationAPI.start((index) => {
                    let { displacement_x, displacement_y } =
                        new_shuffle_pos[index];
                    return {
                        x: displacement_x,
                        y: displacement_y,
                    };
                });

                //update alphabet state
                shuffle_alphabets = curr_tile_pos.map((curr_pos) => {
                    const idx: number = next_tile_pos?.findIndex(
                        (pos) => pos === curr_pos
                    )!;
                    return alphabets[idx];
                });
            }
            timer = setTimeout(() => {
                setAlphabets(shuffle_alphabets);
                shuffleAnimationAPI.start({ x: 0, y: 0, immediate: true });
            }, 500);
        };
    };

    const getPositionOfLetter = (): Array<number> | null => {
        let position: Array<number> | null = null;
        let letters_arr = alphabets;
        switch (letters_arr.length) {
            case STRUCTURE.TRIANGLE:
                position = [];
                position.push(2, 15, 19);
                break;
            case STRUCTURE.DIAMOND:
                position = [];
                position.push(2, 10, 14, 22);
                break;
            case STRUCTURE.PENTAGON:
                position = [];
                position.push(2, 10, 14, 21, 23);
                break;
            case STRUCTURE.HEXAGON:
                position = [];
                position.push(2, 5, 9, 15, 19, 22);
                break;
            default:
        }
        return position;
    };

    const getCurentTargetRef = (x: number, y: number): Element | null => {
        const html_element: Element | null = document.elementFromPoint(x, y);
        if (html_element?.id.includes("btn-")) {
            return html_element;
        } else {
            return null;
        }
    };

    const shuffleArray = (initial_arr: number[]): number[] => {
        let shuffle_arr: number[] = JSON.parse(JSON.stringify(initial_arr));
        let currentIndex: number = shuffle_arr.length;
        let randomIndex: number | undefined;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [shuffle_arr[currentIndex], shuffle_arr[randomIndex]] = [
                shuffle_arr[randomIndex],
                shuffle_arr[currentIndex],
            ];
        }
        return shuffle_arr;
    };

    const onLineDraw = useDrag((state) => {
        if (state?.down) {
            //user press button
            let x: number = state?.xy[0];
            let y: number = state?.xy[1];
            const target_element = getCurentTargetRef(x, y);
            if (target_element) {
                const cur_element = target_element.innerHTML;
                const { height, width, top, left } =
                    target_element.getBoundingClientRect();
                let clientY = top + height / 2;
                let clientX = left + width / 2;
                if (selected_word.letters.length === 0) {
                    // at starting position
                    insertNewSVGPoint(clientX, clientY);
                    insertNewSVGPoint(x, y);
                    dispatch(insertSelectedLetter(cur_element));
                    TRACE_HOP.push(target_element.id);
                } else {
                    if (TRACE_HOP) {
                        const index_present: number = TRACE_HOP.findIndex(
                            (btn) => btn === target_element.id
                        );
                        //if current letter index is not present in last path covered
                        if (index_present === -1) {
                            deleteLastSVGPoint();
                            insertNewSVGPoint(clientX, clientY);
                            insertNewSVGPoint(x, y);
                            dispatch(insertSelectedLetter(cur_element));
                            TRACE_HOP.push(target_element.id);
                        } else if (index_present === TRACE_HOP.length - 2) {
                            //if current is  equal to previous letter
                            deleteLastSVGPoint();
                            deleteLastSVGPoint();
                            insertNewSVGPoint(x, y);
                            dispatch(removeLastLetter());
                            TRACE_HOP.pop();
                        }
                    }
                }
            }
            updateLastSVGPoint(x, y);
        } else {
            //user release button
            resetSVGPoint();
            dispatch(updateSelection(true));
            TRACE_HOP = [];
        }
    });

    return (
        <div
            className={
                styles.mainContainer +
                ` ${props.isGameCmpltd ? "pointer-events-none" : ""}`
            }
        >
            <SVGPloyLine
                points={points}
                style={`absolute overflow-visible top-0 left-0 text-white`}
            />
            {renderLetterInCircel()}
            <ReactTooltip
                className={`text-black font-roboto font-light text-center`}
                place={"top"}
                effect="solid"
                afterShow={() =>
                    setTimeout(() => {
                        ReactTooltip.hide();
                    }, 1000)
                }
            />
        </div>
    );
};
const styles = {
    mainContainer:
        "flex bg-primary-100 self-center place-items-center p-6 mb-2 md:mb-4 rounded-full",
    letterInCircel:
        "text-center bg-warning-400 text-white font-roboto font-extrabold text-md w-10 h-10 text-2xl sm:text-3xl sm:w-10 sm:h-10 font-medium uppercase rounded touch-none relative",
    responsive: "lg:h-12 lg:w-12 ",
};
export default LetterInCircel;
