import React, { useState } from "react";
import { Modal } from "../../../Component/Modal/Modal";
import ChevronLeftIcon from "../../../Assets/svg/ChevronLeftIcon.svg";
import ChevronRightIcon from "../../../Assets/svg/ChevronRightIcon.svg";
import CrossIcon from "../../../Assets/svg/CrossIcon.svg";
import WordHuntFirstTut from "../../../Assets/svg/WordHuntFirstTut.svg";
import WordHuntSecTut from "../../../Assets/svg/WordHuntSecTut.svg";
import { ThirdTutorialSvg } from "./Svg/WordHuntTutorialSvg";
import {
    wrdleTutorial1,
    wrdleTutorial2,
    wrdleTutorial3,
    // wrdleSvgText1,
    // wrdleSvgText2,
    // wrdleSvgText3,
    titleWordHunt,
} from "../../../Commons/constant";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";

export const Tutorial = (props: {
    setShowTutorial: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [displayPage, setDispklayPage] = useState<number>(1);

    const renderIcons = () =>
        displayPage === 1 ? (
            <div className="flex gap-x-6">
                <p className={style.title}>{titleWordHunt}</p>
                <img
                    src={ChevronRightIcon}
                    className={style.icon}
                    onClick={() => {
                        if (displayPage < 3) {
                            SndMngr.playSound(SoundType.RECALL);
                            setDispklayPage(displayPage + 1);
                        }
                    }}
                    alt ="ChevronRightIcon"
                />
            </div>
        ) : displayPage === 2 ? (
            <>
                <img
                    src={ChevronLeftIcon}
                    className={style.icon}
                    onClick={() => {
                        if (displayPage > 1) {
                            SndMngr.playSound(SoundType.RECALL);
                            setDispklayPage(displayPage - 1);
                        }
                    }}
                    alt ="ChevronLeftIcon"
                />
                <p className={style.title}>{titleWordHunt}</p>
                <img
                    src={ChevronRightIcon}
                    className={style.icon}
                    onClick={() => {
                        if (displayPage < 3) {
                            SndMngr.playSound(SoundType.RECALL);
                            setDispklayPage(displayPage + 1);
                        }
                    }}
                    alt ="ChevronRightIcon"
                />
            </>
        ) : (
            <>
                <img
                    src={ChevronLeftIcon}
                    className={style.icon}
                    onClick={() => {
                        if (displayPage > 1) {
                            SndMngr.playSound(SoundType.RECALL);
                            setDispklayPage(displayPage - 1);
                        }
                    }}
                    alt="ChevronLeftIcon"
                />
                <p className={style.title}>{titleWordHunt}</p>
                <img
                    src={CrossIcon}
                    className={style.icon}
                    onClick={() => {
                        SndMngr.playSound(SoundType.RECALL);
                        props.setShowTutorial(false);
                    }} 
                    alt="CrossIcon"
                />
            </>
        );

    const renderFirstPage = () => (
        <>
            <p className={style.text + `py-6`}>{wrdleTutorial1}</p>
            <img
                src={WordHuntFirstTut}
                className={style.Svg + `w-[250px] h-[250px]`}
                alt="WordHuntFirstTut"
            />
        </>
    );

    const renderSecondPage = () => {
        return (
            <>
                <p className={style.text + ` py-12`}>{wrdleTutorial3}</p>
                <ThirdTutorialSvg />
            </>
        );
    };

    const renderThirdPage = () => (
        <>
            <p className={style.text + `py-6`}>
                {wrdleTutorial2} Hit <span className={`font-bold`}>Enter</span>
            </p>
            <img
                src={WordHuntSecTut}
                className={style.Svg + `w-[290px] h-[150px]`}
                alt="WordHuntSecTut"
            />
        </>
    );

    const renderPage = () => {
        switch (displayPage) {
            case 1:
                return renderFirstPage();
            case 2:
                return renderSecondPage();
            case 3:
                return renderThirdPage();
            default:
                return renderFirstPage();
        }
    };

    return (
        <Modal>
            <div className={style.mainContainer}>
                <div
                    className={`w-[280px] flex flex-row pt-8 ${
                        displayPage === 1 ? "justify-end" : "justify-between"
                    }`}
                >
                    {renderIcons()}
                </div>
                {renderPage()}
            </div>
        </Modal>
    );
};

const style = {
    mainContainer:
        "w-[350px] h-[500px] flex flex-col bg-secondary-150  rounded-3xl items-center",
    title: "font-medium text-3xl font-secular",
    text: "font-roboto text-[20px] font-medium px-4 ",
    icon: "w-9 h-9 stroke-secondary-600 cursor-pointer ",
    Svg: "flex self-center place-content-center ",
};
