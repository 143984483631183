import { animated, useSpring } from "react-spring";
import { useDrag } from "@use-gesture/react";
import { useEffect } from "react";
import { MEASUREMENT } from "./ColumnContainer";
import { AssemblerColumnType } from "../../../Commons/types";

const WIDTH = "w-14"; //56px
const HEIGHT = "h-14"; //56pxs

export const AssemblerColumn = (props: {
    column: AssemblerColumnType;
    columnIndex: number;
    upperBound: number;
    lowerBound: number;
    defaultPosition: number;
    onDragComplete: (column_index: number, current_position: number) => void;
}) => {
    const [springAnimation, springAnimationApi] = useSpring(() => ({
        y: 0,
    }));
    useEffect(() => {
        springAnimationApi.start({ y: props.defaultPosition });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCurrentColumnPosition = (): number => {
        let position: number = 0;
        if (springAnimation) {
            position = springAnimation?.y?.get();
        }
        return position;
    };

    const fixedCurrentPosition = (): number => {
        let currentPosition: number = getCurrentColumnPosition();
        currentPosition =
            Math.round(currentPosition / MEASUREMENT.FIXEDPOSITION) *
            MEASUREMENT.FIXEDPOSITION;
        return currentPosition;
    };

    const onColumnDrag = useDrag(
        ({ down, offset }:{down:boolean,offset?:number[]}) => {
            if (down) {
                springAnimationApi.start({ y:offset?.[1], immediate:true });
            } else {
                const fixed_position = fixedCurrentPosition();
                springAnimationApi.start({ y: fixed_position });
                props.onDragComplete(props.columnIndex, fixed_position);
            }
        },
        {
            bounds: {
                left: 0,
                right: 0,
                top: props.upperBound,
                bottom: props.lowerBound,
            },
            from: () => [0, getCurrentColumnPosition()],
        }
    );

    return (
        <animated.div
            className={`flex flex-col relative touch-none z-10`}
            {...onColumnDrag()}
            style={springAnimation}
        >
            {props.column.map(({ letter, color }, index) => (
                <div
                    className={`flex ${color} place-content-center ${WIDTH} ${HEIGHT} border-2 rounded-lg cursor-grab touch-none`}
                    key={index}
                >
                    <p
                        className={`flex text-white font-bold text-4xl self-center select-none`}
                    >
                        {letter}
                    </p>
                </div>
            ))}
        </animated.div>
    );
};
