import React, { useState, useEffect } from "react";
import EllipseSvg from "../../Assets/svg/EllipseSvg.svg";
import TrophySvg from "../../Assets/svg/TrophySvg.svg";
import { COMPLETED_GAME_MSG } from "../../Commons/constant";
import { SuccessButton } from "../../Component/Buttons/SuccessButton";
import { WarningButton } from "../../Component/Buttons/WarningButton";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import ShareIcon from "../../Assets/svg/ShareIcon.svg";
import { useNavigate } from "react-router-dom";
import {
    pathIndexOfGame,
    kCongrats,
    kShare,
    kContinue,
    titleIntro,
    WORDCHAIN_COMPLETED_DATA,
    WORDHUNT_COMPLETED_DATA,
    ASSEMBLER_FIN_GAME_DATA,
} from "../../Commons/constant";
import { WORDSPIDER_FIN_GAME_DATA } from "../WordSpider/Utils/Constants";
import Header from "../../Component/Header/Header";
import lclStorage from "../../Utils/LocalStorage";
import { TimerPropType } from "../../Commons/types";
import ParticleEffect from "./Effects/ParticleEffect";
import { useSpring, animated } from "react-spring";
import { getFinalResult } from "../../Component/GameOver/ShareTexts";
import { Toast, TOASTVARIANT } from "../../Component/Toast/Toast";
import { useToast } from "../../Hooks/useToast";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import { WORDKNIT_FIN_GAME_DATA } from "../WordKnit/Utils/constant";
import { WORDALIKE_FIN_GAME_DATA } from "../WordAlike/Utils/constants";
import ReactGA from "react-ga4";
import { WORDSEARCH_INP_GAME_DATA } from "../WordSearch/Constants";

export const FinalScore = () => {
    let navigator = useNavigate();
    const [showHelp, setShowHelp] = useState(false);
    let { addToast, toast } = useToast();
    const [totalScore, setTotalScore] = useState<number>(0);
    const [totaltime, setTotalTime] = useState<TimerPropType>({
        hr: 0,
        min: 0,
        sec: 0,
    });

    const styles = useSpring({
        from: { scale: 0 },
        to: [{ scale: 1.1 }],
        config: {
            duration: 500,
        },
    });

    useEffect(() => {
        let promise1 = lclStorage.getItem(WORDCHAIN_COMPLETED_DATA);
        let promise2 = lclStorage.getItem(WORDHUNT_COMPLETED_DATA);
        let promise3 = lclStorage.getItem(ASSEMBLER_FIN_GAME_DATA);
        let promise4 = lclStorage.getItem(WORDSPIDER_FIN_GAME_DATA);
        let promise5 = lclStorage.getItem(WORDKNIT_FIN_GAME_DATA);
        let promise6 = lclStorage.getItem(WORDALIKE_FIN_GAME_DATA);
        let promise7 = lclStorage.getItem(WORDSEARCH_INP_GAME_DATA)

        let TotalScore = 0;
        let totalTimeInSec = 0;

        const getTimeInSec = (issuedTime: TimerPropType, compltedTime: TimerPropType) => {
            if (issuedTime === null || issuedTime === undefined) {
                issuedTime = totaltime;
            }
            if (compltedTime === null || compltedTime === undefined) {
                compltedTime = totaltime;
            }
            return (
                issuedTime.hr * 3600 +
                issuedTime.min * 60 +
                issuedTime.sec -
                (compltedTime.hr * 3600 + compltedTime.min * 60 + compltedTime.sec)
            );
        };

        Promise.all([promise1, promise2, promise3, promise4, promise5 ,promise6,promise7]).then(async ([a, b, c, d, e, f,g]) => {
            if (a !== null && a !== undefined) {
                TotalScore = TotalScore + JSON.parse(a).data.score;
                totalTimeInSec = totalTimeInSec + getTimeInSec(JSON.parse(a).data.initialTime, JSON.parse(a).data.cmpltdTime);
            }
            if (b !== null && b !== undefined) {
                TotalScore = TotalScore + JSON.parse(b).data.score;
            }
            if (c !== null && c !== undefined) {
                TotalScore = TotalScore + JSON.parse(c).data.score;
                totalTimeInSec = totalTimeInSec + getTimeInSec(JSON.parse(c).data.initialTime, JSON.parse(c).data.cmpltdTime);
            }
            if (d !== null && d !== undefined) {
                TotalScore = TotalScore + JSON.parse(d).totalScore;
            }
            if (e) {
                TotalScore = TotalScore + JSON.parse(e).score;
            }
            if (f) {
                TotalScore = TotalScore + JSON.parse(f).score;
            }
            if (g) {
                TotalScore = TotalScore + JSON.parse(g).score;
            }

            setTotalScore(TotalScore);

            let hr = Math.floor(totalTimeInSec / 3600); // get hours
            let min = Math.floor((totalTimeInSec - hr * 3600) / 60); // get minutes
            let sec = totalTimeInSec - hr * 3600 - min * 60; //  get seconds
            setTotalTime({ hr, min, sec });
        });

        dailyWordPuzzleDataAPI.hasVistedFinalScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const renderTotalTime = () => {
    //     return Object.keys(totaltime).map((item: string, index: number) => {
    //         if (Object.values(totaltime)[index] !== 0) {
    //             return (
    //                 <span key={item} className="pl-2">
    //                     {Object.values(totaltime)[index]}
    //                     <span className="font-montserrat text-[10px] font-medium pl-2">{item}</span>
    //                 </span>
    //             );
    //         }
    //     });
    // };

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    return (
        <div className="flex flex-col w-full h-full `} ">
            <Header
                title={titleIntro}
                // setMenuIsLoading={setShowPopUp}
                onMenuHintClicked={() => {}}
                onMenuStatsClicked={() => {}}
                onMenuHelpClicked={() => {
                    handleShow();
                }}
            />
            {showHelp && <HelpDeskPopup closeHelp={handleClose} />}
            <Toast toast={[...toast]} />
            <div className="relative flex h-[350px] min-h-[350px] flex-col items-center ">
                <p className="absolute top-10 font-secular text-[26px] font-semibold text-secondary-500">{kCongrats}</p>
                <img src={EllipseSvg} className={`w-full h-[250px] max-w-[500px]`} alt="EllipseSvg" />
                <animated.div className="absolute top-[130px] " style={styles}>
                    <img src={TrophySvg} alt="TrophySvg" />
                </animated.div>
            </div>
            <div className="flex flex-col justify-center self-center max-w-[500px] ">
                <p className="font-roboto text-[20px] text-secondary-500 font-medium px-8 ">{COMPLETED_GAME_MSG}</p>
                <span className="flex w-3/4 h-[0.5px] bg-secondary-400 self-center mt-8" />
                <div className=" flex w-full self-center px-12 mt-1 justify-around">
                    <p className="flex flex-col font-roboto text-[22px] font-medium text-center">
                        {totalScore}
                        <span className="font-roboto text-[10px] font-medium text-secondary-500">Total Score</span>
                    </p>
                    {/* <span className="w-[0.5px] h-10 bg-secondary-400 self-center " />
                    <p className="flex flex-col font-roboto text-[22px] font-medium text-center">
                        <span>{renderTotalTime()}</span>
                        <span className="font-roboto text-[10px] font-medium text-secondary-500">
                            Total Time
                        </span>
                    </p> */}
                </div>
                <div className="flex flex-col gap-y-3 mt-16">
                    <WarningButton
                        title={kContinue}
                        clicked={() => {
                            SndMngr.playSound(SoundType.CLICKBTN);
                            navigator(pathIndexOfGame);
                        }}
                        style={` text-[20px] font-medium  font-secular tracking-[2px] place-content-center w-[220px]`}
                    />
                    <SuccessButton
                        title={kShare}
                        clicked={() => {
                            SndMngr.playSound(SoundType.CLICKBTN);
                            addToast(TOASTVARIANT.NOICON, "Copied to clipboard");
                            getFinalResult();
                            ReactGA.event({
                                category: "share",
                                action: "scoreshare",
                                label: "simplewordgames",
                                value: 1,
                            });
                        }}
                        icon={<img src={ShareIcon} className={` w-5 stroke-white self-center`} alt="ShareIcon" />}
                        style={` text-[20px] font-medium  font-secular tracking-[2px] w-[220px] `}
                    />
                </div>
            </div>
            <ParticleEffect />
        </div>
    );
};
