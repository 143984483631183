import { useSpring, animated } from "react-spring";

const Fade: React.FC<{ children?: React.ReactNode }> = (props) => {
    const animation = useSpring({
        to: { opacity: 1, zIndex: 11 },
        from: { opacity: 0, zIndex: 11 },
        delay: 100,
    });
    return <animated.div style={animation}>{props.children}</animated.div>;
};

export default Fade;
