import { useEffect, useState, useRef } from "react";
import { kSumbit, kWordsFound } from "../../../Commons/constant";
import { CLUE_TITLE, WORDALIKE_FIN_GAME_DATA, WORDALIKE_INP_GAME_DATA, WORD_ALIKE_BOARD_SIZE } from "../Utils/constants";
import { WordAlikeBoardState, WordAlikeFinGameType, WordAlikeInpGameType } from "../Utils/type";
import { WordAlikeTextSelection } from "./WordAlikeTextSelection";
import { WordAlikeTile } from "./WordAlikeTile";
import WordAlikeShuffleIcon from "../../../Assets/svg/WordAlikeShuffleIcon.svg";
import WordAlikeWordsIcon from "../../../Assets/svg/WordAlikeWordsIcon.svg";
import WordAlikeWordsIconSecond from "../../../Assets/svg/WordAlikeWordsIconSecond.svg";
import lclStorage from "../../../Utils/LocalStorage";
import ReactGA from "react-ga4";
import { useSprings } from "react-spring";
import ReactTooltip from "react-tooltip";
import { dailyWordPuzzleDataAPI } from "../../Introduction/api/DailyWordPuzzleDataAPI";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";

const EACH_WORD_LENGTH_SCORE = 5;

let BOARD_WIDTH = 5;
let CLUE_WORD: string = "";
let SYNONYM: string[] = [];
let MAX_WORD_LENGTH: number = 0;
const TILE_GAP = 4;

export const WordAlikeContainer = (props: { updateGameOverData: (game_over_data: WordAlikeFinGameType) => void }) => {
    const [board, setBoard] = useState<WordAlikeBoardState>([]);
    const [selectLetters, setSelectLetters] = useState<string[]>([]);
    const [wordsFound, setWordsFound] = useState<string[]>([]);
    const [isGameOver, setIsGameOver] = useState<boolean>(false);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);

    const [shuffleAnimation, shuffleAnimationAPI] = useSprings(board.length, (index) => ({ x: 0, y: 0 }));

    const boardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onWordSynoInit = async () => {
            let board_data: WordAlikeBoardState = [];
            let clue_word: string = "";
            let synonym: string[] = [];
            let words_found: string[] = [];

            let cmp_data: string | null = await lclStorage.getItem(WORDALIKE_FIN_GAME_DATA);
            if (cmp_data) {
                let parse_cmp_data: WordAlikeFinGameType = JSON.parse(cmp_data);
                board_data = parse_cmp_data.board;
                clue_word = parse_cmp_data.clueWord;
                synonym = parse_cmp_data.synonym;
                words_found = parse_cmp_data.wordsFound;
                setIsGameOver(true);
                props.updateGameOverData(parse_cmp_data);
                ReactGA.event({
                    category: "game_over",
                    action: "revisited",
                    label: "wordalike",
                    value: 1,
                });
            } else {
                let tmp_data: string | null = await lclStorage.getItem(WORDALIKE_INP_GAME_DATA);
                if (tmp_data) {
                    let parse_tmp_data: WordAlikeFinGameType = JSON.parse(tmp_data);
                    board_data = parse_tmp_data.board;
                    clue_word = parse_tmp_data.clueWord;
                    synonym = parse_tmp_data.synonym;
                    words_found = parse_tmp_data.wordsFound;
                    ReactGA.event({
                        category: "game_start",
                        action: "resume",
                        label: "wordalike",
                        value: 1,
                    });
                } else {
                    //on game load first time
                    const server_data: { word: string; synonym: string[] } | undefined =
                        await dailyWordPuzzleDataAPI.wordAlikeData();
                    if (server_data) {
                        let letters_set: string[] = [];
                        server_data.synonym.forEach((word) => {
                            word.trim()
                                .toUpperCase()
                                .split("")
                                .forEach((letter) => !letters_set.includes(letter) && letters_set.push(letter));
                        });
                        clue_word = server_data.word.trim().toUpperCase();
                        synonym = server_data.synonym.map((word) => word.trim().toUpperCase());
                        const tiles_random_pos: number[] = getRandomTilePosition(letters_set.length);
                        board_data = letters_set.map((letter, index) => ({ letter, indexPos: tiles_random_pos[index] }));
                        ReactGA.event({
                            category: "game_start",
                            action: "new_game",
                            label: "wordalike",
                            value: 1,
                        });
                    }
                }
            }

            CLUE_WORD = clue_word;
            SYNONYM = synonym;
            setBoard(board_data);
            setWordsFound(words_found);

            //claculate length of word which is maximum
            synonym.forEach((word) => {
                let curr_length: number = word.split("").length;
                if (curr_length > MAX_WORD_LENGTH) {
                    MAX_WORD_LENGTH = curr_length;
                }
            });
        };
        onWordSynoInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (wordsFound.length && SYNONYM.length) {
            if (wordsFound.length === SYNONYM.length) {
                //game over condition
                ReactGA.event({
                    category: "game_over",
                    action: "finished_game",
                    label: "wordalike",
                    value: 1,
                });
                const cmp_data: WordAlikeFinGameType = setGameOverDataToLclStg();
                lclStorage.resetItem(WORDALIKE_INP_GAME_DATA);
                props.updateGameOverData(cmp_data);
                setIsGameOver(true);
            } else {
                //temp save data
                setTempDataToLclStg();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [board, wordsFound]);

    useEffect(() => {
        //keyboard listner
        const keyBoardListner = (event: KeyboardEvent) => {
            const letter: string = event.key.toUpperCase().trim();
            if (letter) {
                if (letter === "ENTER") {
                    onSubmitClick();
                } else {
                    onTileClick(letter);
                }
            }
        };
        document.addEventListener("keydown", keyBoardListner);
        //disposal function
        return () => {
            document.removeEventListener("keydown", keyBoardListner);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [board, selectLetters]);

    const getRandomTilePosition = (total_uniq_letters: number): number[] => {
        function calculateRandomPos(random_pos: number[]): number[] {
            //base case
            if (total_uniq_letters === random_pos.length) {
                return random_pos;
            } else {
                const pos: number = Math.floor(Math.random() * total_uniq_letters);
                if (!random_pos.includes(pos)) {
                    random_pos.push(pos);
                }
                return calculateRandomPos(random_pos);
            }
        }
        return calculateRandomPos([]);
    };

    const setGameOverDataToLclStg = () => {
        let cmp_data: WordAlikeFinGameType = {
            board,
            score: getScore(),
            isGameOver: true,
            wordsFound,
            clueWord: CLUE_WORD,
            synonym: SYNONYM,
            tstmp: Math.floor(Date.now() / 1000),
        };
        lclStorage.setItem(WORDALIKE_FIN_GAME_DATA, JSON.stringify(cmp_data));
        return cmp_data;
    };

    const setTempDataToLclStg = () => {
        let tmp_data: WordAlikeInpGameType = {
            board,
            score: getScore(),
            wordsFound,
            clueWord: CLUE_WORD,
            synonym: SYNONYM,
            tstmp: Math.floor(Date.now() / 1000)
        };
        lclStorage.setItem(WORDALIKE_INP_GAME_DATA, JSON.stringify(tmp_data));
    };

    const getScore = (): number => {
        let score: number = 0;
        wordsFound.forEach((word) => (score = score + word.split("").length * EACH_WORD_LENGTH_SCORE));
        return score;
    };

    const startAnimation = (shuffle_tile_pos?: number[]) =>
        new Promise((resolve) => {
            if (shuffle_tile_pos) {
                const tile_size: number =
                    (boardRef?.current?.childNodes[0] as HTMLDivElement).getBoundingClientRect().width + TILE_GAP;
                const new_shuffle_pos = board.map((tile, index: number) => {
                    let displacement_y: number = 0;
                    let displacement_x: number = 0;
                    let next_idx: number = shuffle_tile_pos[index];
                    if (tile.indexPos !== next_idx) {
                        let x_curr = tile.indexPos % BOARD_WIDTH;
                        let y_curr = Math.floor(tile.indexPos / BOARD_WIDTH);

                        let x_next = next_idx % BOARD_WIDTH;
                        let y_next = Math.floor(next_idx / BOARD_WIDTH);

                        displacement_x = x_next - x_curr;
                        displacement_y = y_next - y_curr;
                    }
                    return {
                        displacement_x: displacement_x * tile_size,
                        displacement_y: displacement_y * tile_size,
                    };
                });

                const all_animation = shuffleAnimationAPI.start((index) => {
                    let { displacement_x, displacement_y } = new_shuffle_pos[index];
                    return {
                        to: async (next, cancel) => {
                            await next({ x: displacement_x, y: displacement_y });
                        },
                    };
                });

                Promise.all(all_animation).then((r) => resolve(1));
            } else {
                const all_animation = shuffleAnimationAPI.start({ x: 0, y: 0, immediate: true });
                Promise.all(all_animation).then((r) => resolve(1));
            }
        });

    const onTileClick = (letter: string) => {
        if (getHowManyLetterLeft(letter)) {
            if (!isGameOver && selectLetters.length < MAX_WORD_LENGTH) {
                const isLetterValid = board.find((tile) => tile.letter === letter);
                if (isLetterValid) {
                    const select_letters: string[] = JSON.parse(JSON.stringify(selectLetters));
                    select_letters.push(letter);
                    setSelectLetters(select_letters);
                }
            }
            SndMngr.playSound(SoundType.CLICKBTN);
        }
    };

    const onSubmitClick = () => {
        if (!isGameOver) {
            const words_found: string[] = JSON.parse(JSON.stringify(wordsFound));
            const selected_word: string = selectLetters.join("").trim();
            if (SYNONYM.includes(selected_word) && !words_found.includes(selected_word)) {
                words_found.push(selected_word);
                setWordsFound(words_found);
                SndMngr.playSound(SoundType.USERWINGAME);
            } else {
                SndMngr.playSound(SoundType.USERSUBMITS);
            }
            setSelectLetters([]);
        }
    };

    const onShuffleClick = async () => {
        if (!isAnimating && !isGameOver) {
            SndMngr.playSound(SoundType.SHUFFLE);
            setIsAnimating(true);
            const random_index: number[] = getRandomTilePosition(board.length);
            let temp_board: WordAlikeBoardState = JSON.parse(JSON.stringify(board));
            temp_board.forEach((_, index) => (temp_board[index].indexPos = random_index[index]));
            await startAnimation(random_index);
            setBoard(temp_board);
            await startAnimation();
            setIsAnimating(false);
        }
    };

    const getHowManyLetterLeft = (letter: string): number => {
        let total_count: number = SYNONYM.flatMap((word) => word.split("").filter((lett) => lett === letter)).length;
        let already_cmp_count: number = wordsFound.flatMap((word) => word.split("").filter((lett) => lett === letter)).length;
        return total_count - already_cmp_count;
    };

    const renderTiles = (): JSX.Element[] => {
        let tiles: JSX.Element[] = [];

        board.forEach(
            ({ letter, indexPos }, idx) =>
                (tiles[indexPos] = (
                    <WordAlikeTile
                        letter={letter}
                        isSelected={selectLetters.includes(letter)}
                        key={indexPos}
                        animationValue={shuffleAnimation[idx]}
                        letterLeft={getHowManyLetterLeft(letter)}
                    />
                ))
        );
        // <div key={`${index}`} className="aspect-square "></div>
        return tiles;
    };

    const renderTextSelection = () => {
        return (
            <WordAlikeTextSelection
                selectedLetters={selectLetters}
                isWordAlreadyFound={wordsFound.includes(selectLetters.join("").trim())}
                isValidWord={SYNONYM.includes(selectLetters.join("").trim())}
                wordLeft={SYNONYM.length - wordsFound.length}
            />
        );
    };

    const renderBoard = (): JSX.Element => {
        return (
            <div
                className={`h-full w-full ${WORD_ALIKE_BOARD_SIZE.maxHeight} ${WORD_ALIKE_BOARD_SIZE.minHeight} ${WORD_ALIKE_BOARD_SIZE.maxWidth} ${WORD_ALIKE_BOARD_SIZE.minWidth}`}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${BOARD_WIDTH}, minmax(0, 1fr)`,
                        gridAutoFlow: "row",
                        gap: `${TILE_GAP}px`,
                    }}
                    ref={boardRef}
                    onClick={(event) => onTileClick((event.target as Element).id.trim())}
                >
                    {renderTiles()}
                </div>
            </div>
        );
    };

    const renderShuffle = () => {
        return <img src={WordAlikeShuffleIcon} className={`h-7 w-14 cursor-pointer`} onClick={onShuffleClick} alt="shuffle" />;
    };

    const renderSumbit = () => {
        return (
            <button
                className={`font-roboto text-[16px] font-bold  ${
                    selectLetters.length === 0 ? "bg-secondary-200 text-secondary-350" : "bg-success-350 text-white"
                } rounded-[7px] px-4 py-1`}
                onClick={onSubmitClick}
                disabled={selectLetters.length === 0}
            >
                {kSumbit}
            </button>
        );
    };

    const renderClueHeadline = () => {
        return (
            <div className="flex flex-col items-center">
                <p className="mb-1 text-center font-roboto text-[15px] leading-5 tracking-[-0.02em] text-secondary-650">
                    {CLUE_TITLE}
                </p>
                <div className={` mb-8 flex flex-row self-center rounded-md  bg-warning-400 p-2`}>
                    <p className="rounded bg-white px-8 py-[2px] text-center font-roboto text-[19px] font-bold leading-6 tracking-[.25em] text-secondary-550">{`${CLUE_WORD.toLocaleUpperCase()}`}</p>
                </div>
            </div>
        );
    };

    const rederWordCompleted = () => {
        return (
            <>
                <ReactTooltip className={`text-center font-roboto font-light text-black`} place={"top"} effect="solid" />
                <div className="flex flex-row" data-tip={wordsFound.join(",")}>
                    {wordsFound.length === 0 ? (
                        <img src={WordAlikeWordsIcon} className={`w-15 h-15 mr-1 cursor-pointer`} alt="Words" />
                    ) : (
                        <img src={WordAlikeWordsIconSecond} className={`w-15 h-15 mr-1 cursor-pointer`} alt="Words" />
                    )}
                    <p className="cursor-pointer font-roboto font-normal text-secondary-650">{`${kWordsFound}: ${wordsFound.length}`}</p>
                </div>
            </>
        );
    };

    return (
        <div className="flex flex-col items-center">
            {renderClueHeadline()}
            {renderTextSelection()}
            <div className="my-8 flex flex-row items-center gap-x-28">
                {rederWordCompleted()}
                <div className="flex flex-row">
                    <div className="h-7 w-7 rounded-2xl">{renderShuffle()}</div>
                    <div className="ml-4">{renderSumbit()}</div>
                </div>
            </div>
            {renderBoard()}
        </div>
    );
};
