import React from "react";
import Particles from "react-particles";
import type { Engine } from "tsparticles-engine";
import { loadConfettiPreset } from "tsparticles-preset-confetti";

const ParticleEffect = () => {
    const customInit = async (engine: Engine): Promise<void> => {
        await loadConfettiPreset(engine);
    };

    const options = {
        emitters: [
            {
                life: {
                    count: 0,
                },
                position: {
                    x: 50,
                    y: 8,
                },
                particles: {
                    move: {
                        direction: "top",
                        decay: 0.5,
                    },
                    color: {
                        value: ["#F45009", "#E41C4C", "#3A5254", "#FCA605"],
                    },
                },
            },
        ],
        preset: "confetti",
    };
    return <Particles options={options} init={customInit} />;
};

export default ParticleEffect;
