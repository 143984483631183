
import { Modal } from "../../Component/Modal/Modal";
import CrossIcon from "../../Assets/svg/CrossIcon.svg"
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import { kHelpDesk,kHelpDeskMsg,kHelpDeskChatLink } from "../../Commons/constant";

export const HelpDeskPopup = (props: { closeHelp: () => void }) => {
        const renderIcons = () => (
            <div className="flex gap-x-8">
           <p className={style.title}>{kHelpDesk}</p>
            <img
                src={CrossIcon}
                className={style.icon}
                onClick={() => {
                    SndMngr.playSound(SoundType.RECALL);
                    props.closeHelp();
                }}
                alt ="crossIcon"
            />
            </div>
        );

    const renderHelp = () => {
        return (
            <>
           
            <div>
            <p className={style.text + ` py-12 `}>{kHelpDeskMsg}</p>
            <p className={style.text + ` pb-12 `}>
                <a className={`text-blue-700 text-[18px]`} href={kHelpDeskChatLink} target="_blank" rel="noreferrer">{kHelpDeskChatLink}</a>
            </p>
            </div>
             </>
        );
    };

    return (
        <Modal>
            <div className={style.mainContainer}>
                <div className={`w-[280px] flex flex-row pt-8 justify-end`}>
                    {renderIcons()}
                </div>
                {renderHelp()}
            </div>
        </Modal>
    );
};

const style = {
    mainContainer:
        "w-[350px]  flex flex-col bg-secondary-150  rounded-3xl items-center",
    title: "font-medium text-3xl font-secular",
    text: "font-roboto text-[20px] font-medium px-4 ",
    icon: "w-9 h-9 stroke-secondary-600 cursor-pointer ",
};
