import { kCompleted, kWordsLeft } from "../../../Commons/constant";
import Fade from "../../../Component/Animation/Fade";

export const WordAlikeTextSelection = (props: {
    selectedLetters: string[];
    isWordAlreadyFound: boolean;
    isValidWord: boolean;
    wordLeft: number;
}) => {
    let bgColor = "";

    if (props.selectedLetters.length) {
        if (props.isValidWord && !props.isWordAlreadyFound) {
            bgColor = bgColor + ` bg-success-300`;
        } else {
            bgColor = bgColor + ` bg-warning-350`;
        }
    } else {
        bgColor = bgColor + ` bg-warning-400`;
    }

    let selected_word_jsx: JSX.Element = (
        <div className={` ${bgColor} mb-2 flex  flex-row self-center rounded-md p-1`}>
            <p className="rounded bg-white px-8 py-1 text-center font-roboto text-[17px] font-normal text-secondary-700">
                {props.wordLeft ? `${props.wordLeft} ${kWordsLeft}` : `${kCompleted}`}
            </p>
        </div>
    );

    if (props.selectedLetters.length) {
        selected_word_jsx = (
            <div className={`${bgColor} mb-2 flex flex-row self-center rounded-md p-1`}>
                {props.selectedLetters.map((letter, index) => {
                    return (
                        <Fade key={index}>
                            <p className="mx-[1px] rounded bg-white px-3 py-1 text-center font-roboto text-[17px] font-normal text-secondary-700">
                                {letter}
                            </p>
                        </Fade>
                    );
                })}
            </div>
        );
    }
    return selected_word_jsx;
};
