import { Modal } from "../Modal/Modal";
import CrossIcon from "../../Assets/svg/CrossIcon.svg";
import { kPreDayWord } from "../../Commons/constant";
import { SndMngr, SoundType } from "../../Utils/SoundManager";

export const LastDayWord = (props: { words: string[]; onPress: () => void }) => {
    const renderWordList = (): JSX.Element | null => {
        let wordListJSX: JSX.Element | null = null;
        if (props.words.length) {
            wordListJSX = (
                <div className="flex flex-col gap-y-8 items-center">
                    <p className="text-2xl font-bold font-mono text-center">{kPreDayWord}</p>
                    <div className="overflow-auto max-h-[300px] pr-8 pl-4">
                        <div className={`grid ${props.words.length === 1 ? "grid-cols-1" : "grid-cols-2"} gap-x-6 `}>
                            {props.words.map((word, index) => (
                                <p className="text-xl font-mono font-bold text-start px-2 py-1 text-primary-700" key={index}>
                                    {word}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
        return wordListJSX;
    };
    return (
        <Modal>
            <div className={styles.mainContainer}>
                <img
                    src={CrossIcon}
                    alt="crossicon"
                    className="absolute top-1 right-1 cursor-pointer rounded-2xl hover:shadow-xl"
                    onClick={() => {
                        SndMngr.playSound(SoundType.CLICKBTN);
                        props.onPress();
                    }}
                />
                {renderWordList()}
            </div>
        </Modal>
    );
};

const styles = {
    mainContainer:
        "flex flex-col w-96 bg-secondary-150 rounded-lg p-12 place-items-center  shadow-[4px_5px_6px_0px_rgba(0,0,0,0.5)] relative",
    text: " text-[22px] font-roboto font-medium tracking-[2px] self-center pb-8",
    msgtext: "font-roboto text-[15px] font-normal",
};
