import produce from "immer";
import { useEffect, useRef, useState } from "react";
import {
    SquareType,
    BoardType,
    WordHuntGameCmpltdType,
    ShowTutorialOnceType,
    TempWordHuntData,
} from "../../../Commons/types";
import {
    pathIndexOfGame,
    // pathAssembler,
    TEMP_WORDHUNT_DATA,
    WORDHUNT_COMPLETED_DATA,
    pathFinalScore,
    SHOW_TUTORIAL_ONCE,
} from "../../../Commons/constant";
import { KEYBOARDLETTER } from "../../../Commons/constant";
import { titleWordHunt } from "../../../Commons/constant";
import Header from "../../../Component/Header/Header";
import GameOver from "../../../Component/GameOver/GameOver";
import { KeyBoard } from "../KeyBoard/KeyBoard";
import { useNavigate } from "react-router-dom";
// import ProgressBar from "../../../Component/ProgressBar/ProgressBar";
import { Tutorial } from "../Tutorial/Tutorial";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import { StatsPopup } from "../GameStats/StatsPopup";
import lclStorage from "../../../Utils/LocalStorage";
import { dailyWordPuzzleDataAPI } from "../../Introduction/api/DailyWordPuzzleDataAPI";
import { HelpDeskPopup } from "../../../Component/Menu/HelpDeskPopup";
import BlupIcon from "../../../Component/SVG/BlupIcon";
import WordsOfLastPlayedIcon from "../../../Assets/svg/WordsOfLastPlayedIcon.svg";
import ReactGA from "react-ga4";
import { LastDayWord } from "../../../Component/LastDayWord/LastDayWord";

const MAXROW: number = 6;
const MAXCOLUMN: number = 5;

const EACH_SQUARE_SCORE = 5;

type CurrentWordDescriptorType = {
    [letter: string]: {
        [index: string]: string;
    };
};

type MainWordDescriiptorType = {
    [letter: string]: string[];
};

export enum BGCOLORS {
    GREEN = "bg-success-350 text-white border-none",
    RED = "bg-warning-650 text-white border-none",
    GRAY = "bg-secondary-300 text-overlay-200 border-none",
}

export enum BORDERCOLORS {
    RED = "border-danger-600",
    YELLOW = "border-info-700",
}

export enum COLOR_CODE {
    BG_RED = "PR_NPL",
    BG_GRAY = "NPR_NPL",
    BG_GREEN = "PR_PL",
}

export const LetterInSquares = () => {
    let navigator = useNavigate();
    const [board, setBoard] = useState<BoardType>([]);
    const [showHelp, setShowHelp] = useState(false);

    const [isGameOver, setIsGameOver] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const inputRef = useRef<HTMLInputElement>(null);

    const [showStats, setShowStats] = useState(false);

    const [GameOverData, setGameOverData] = useState<WordHuntGameCmpltdType>({
        isGameOver: false,
        data: {
            boardData: [],
            score: 0,
            tstmp:0
        },
    });

    let [final_word, setFinalWord] = useState<string>("");
    const [isAllGameOver, setIsALlGameOver] = useState<boolean>(false);
    const [showGameOverPopup, setShowGameOverPopup] = useState<boolean>(false);
    const [showMostRecentPuzzleAnswers, setShowMostRecentPuzzleAnswers] = useState<boolean>(false)

    useEffect(() => {
        const onInitWordHunt = async () => {
            let promise1 = lclStorage.getItem(SHOW_TUTORIAL_ONCE);
            let promise2 = lclStorage.getItem(WORDHUNT_COMPLETED_DATA);
            let pmsData = await Promise.all([promise1, promise2]);
            let tt = pmsData[0];
            let wordHuntCmpltData = pmsData[1];

            let showtutorial: ShowTutorialOnceType = {
                wordChainTutShow: false,
                wordHuntTutShown: false,
                assemblerTutShown: false,
                wordKnitTutShown: false,
                wordspiderTutShown: false,
                wordAlikeTutShown: false,
                wordSearchTutShown: false,
            };

            if (tt !== null && tt !== undefined) {
                showtutorial = JSON.parse(tt);
            }
            setIsLoading(!showtutorial.wordHuntTutShown);

            if (showtutorial.wordHuntTutShown === false) {
                showtutorial.wordHuntTutShown = true;
                lclStorage.setItem(
                    SHOW_TUTORIAL_ONCE,
                    JSON.stringify(showtutorial)
                );
            }
            if (wordHuntCmpltData) {
                let gameData = JSON.parse(wordHuntCmpltData);
                if (gameData.isGameOver) {
                    let completed_data: WordHuntGameCmpltdType = {
                        isGameOver: gameData.isGameOver,
                        data: {
                            boardData: gameData.data.boardData,
                            score: gameData.data.score,
                            tstmp: Math.floor(Date.now() / 1000)
                        },
                    };
                    setGameOverData(completed_data);
                    setBoard(gameData.data.boardData);
                    ReactGA.event({
                        category: "game_over",
                        action: "revisited",
                        label: "wordhunt",
                        value: 1,
                    });
                }
            } else {
                let promise3 = lclStorage.getItem(TEMP_WORDHUNT_DATA);
                let promise4 = dailyWordPuzzleDataAPI.wordHuntData();

                let pData = await Promise.all([promise3, promise4]);
                let resumegame = pData[0];
                let frshdata = pData[1];
                if (resumegame) {
                    const resume_data: TempWordHuntData = JSON.parse(resumegame);
                    setBoard(resume_data.data.boardData);
                }

                if (frshdata) {
                    setFinalWord(frshdata.finword.trim());
                }

                if (resumegame) {
                    ReactGA.event({
                        category: "game_start",
                        action: "resume",
                        label: "wordhunt",
                        value: 1,
                    });
                } else {
                    ReactGA.event({
                        category: "game_start",
                        action: "new_game",
                        label: "wordhunt",
                        value: 1,
                    });
                }

                setTimeout(() => {
                    inputRef.current?.focus();
                }, 0);
            }
        };
        onInitWordHunt();
    }, []);

    useEffect(() => {
        if (isLoading === false) {
            SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
        }
    }, [isLoading]);

    useEffect(() => {
        let last_row: SquareType[] | undefined = board[board.length - 1];
        if (last_row && last_row.length === MAXCOLUMN) {
            const is_last_row_filled = last_row.every(
                ({ color }) => !color.includes(BORDERCOLORS.YELLOW) && !color.includes(BORDERCOLORS.RED) && color !== ""
            );
            if (is_last_row_filled) {
                const is_word_found: boolean = last_row.every(
                    ({ color }) => color.includes(COLOR_CODE.BG_GREEN)
                );
                //Game Over condition
                if (board.length === MAXROW || is_word_found) {
                    setIsGameOver(true);
                    setShowGameOverPopup(true);
                    let gameData: WordHuntGameCmpltdType = {
                        isGameOver: true,
                        data: {
                            boardData: board,
                            score: getScore(board),
                            tstmp: Math.floor(Date.now() / 1000)
                        },
                    };
                    setGameOverData(gameData);
                    lclStorage.setItem(WORDHUNT_COMPLETED_DATA, JSON.stringify(gameData));
                    SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                    lclStorage.resetItem(TEMP_WORDHUNT_DATA);
                    ReactGA.event({
                        category: "game_over",
                        action: "finished_game",
                        label: "wordhunt",
                        value: 1,
                    });
                } else {
                    //temp data saving
                    const tmp_data: TempWordHuntData = {
                        data: {
                            boardData: board,
                            tstmp:Math.floor(Date.now() / 1000)
                        }
                    }
                    lclStorage.setItem(TEMP_WORDHUNT_DATA, JSON.stringify(tmp_data));
                }
            }
        }
    }, [board]);

    useEffect(() => {
        dailyWordPuzzleDataAPI.isAllGameOver().then((data) => {
            if (data) {
                setIsALlGameOver(data);
                setShowGameOverPopup(true)
            }
        });
    }, [isGameOver]);

    const decodeBoardDataWithColorCode = (boardData: BoardType): BoardType => {
        let board_data: BoardType = boardData.map((row) =>
            row.map((square) => {
                let square_data: SquareType = square;
                if (square.color === COLOR_CODE.BG_RED) {
                    square_data = {
                        letter: square.letter,
                        color: BGCOLORS.RED,
                    };
                }
                if (square.color === COLOR_CODE.BG_GRAY) {
                    square_data = {
                        letter: square.letter,
                        color: BGCOLORS.GRAY,
                    };
                }
                if (square.color === COLOR_CODE.BG_GREEN) {
                    square_data = {
                        letter: square.letter,
                        color: BGCOLORS.GREEN,
                    };
                }
                return square_data;
            })
        );
        return board_data;
    };

    function checkWordIsMatched(): boolean {
        let isMatched: boolean = false;
        const current_word: string = board[board.length - 1]
            .map(({ letter }) => letter)
            .join("");
        if (current_word === final_word) {
            isMatched = true;
        }
        return isMatched;
    }

    const OnEditBorderColor = (colorType: string) => {
        setBoard(
            produce((board_state) => {
                let last_row: SquareType[] | undefined = board_state.pop();
                if (last_row) {
                    last_row.forEach(
                        (square) => (square.color = `${colorType}`)
                    );
                    board_state.push(last_row);
                }
            })
        );
    };

    const clearRow = () => {
        setTimeout(() => {
            setBoard(
                produce((board_data) => {
                    const last_row = board_data[board_data.length - 1];
                    if (last_row[0].color === BORDERCOLORS.RED) {
                        board_data.pop();
                    }
                })
            );
        }, 1000);
    };

    const currentWordColourDescriptor = (
        word: string
    ): CurrentWordDescriptorType => {
        let curr_word_des: CurrentWordDescriptorType = {};
        word.split("").forEach((letter, index) => {
            let clrmap = curr_word_des[letter];
            if (clrmap) {
                clrmap[index] = COLOR_CODE.BG_GRAY;
            } else {
                curr_word_des[letter] = { [index]: COLOR_CODE.BG_GRAY };
            }
        });
        return curr_word_des;
    };

    const getMainWordDescriptor = (word: string): MainWordDescriiptorType => {
        let main_word_des: MainWordDescriiptorType = {};
        word.split("").forEach((letter, index) => {
            let arr_index: string[] = main_word_des[letter];
            if (arr_index) {
                arr_index.push(String(index));
            } else {
                main_word_des[letter] = [String(index)];
            }
        });
        return main_word_des;
    };

    function updateColorOfSquare(letter_index: number, color: string) {
        setBoard(
            produce((board_data) => {
                let last_row: SquareType[] | undefined = board_data.pop();
                if (last_row) {
                    last_row[letter_index].color = color;
                    board_data.push(last_row);
                }
            })
        );
    }

    const updateBoardProperty = (key: string) => {
        switch (key) {
            case "BACKSPACE":
                setBoard(
                    produce((board_state) => {
                        const last_row: SquareType[] | undefined =
                            board_state.pop();
                        if (
                            last_row &&
                            last_row[last_row.length - 1].color === ""
                        ) {
                            last_row.pop();
                        }
                        if (last_row && last_row.length) {
                            board_state.push(last_row);
                        }
                    })
                );

                break;

            case "ENTER":
                const last_row: SquareType[] = board[board.length - 1];
                if (last_row) {
                    if (last_row.length === MAXCOLUMN) {
                        //one row completed check for validation
                        const current_word: string = last_row.map(({ letter }) => letter).join("");

                        if (dailyWordPuzzleDataAPI.isWordValidate(current_word)) {
                            let curr_word_des: CurrentWordDescriptorType = currentWordColourDescriptor(current_word);
                            let main_word_des: MainWordDescriiptorType = getMainWordDescriptor(final_word);

                            Object.keys(curr_word_des).forEach((letter) => {
                                let idx_with_color = curr_word_des[letter];
                                let found_index: string[] = main_word_des[letter];
                                if (found_index) {
                                    //either going to be red or green
                                    for (let index = 0; index < found_index.length; index++) {
                                        let idx: string = found_index[index];
                                        let fd: string[] = Object.keys(idx_with_color);
                                        if (fd.includes(idx)) {
                                            idx_with_color[idx] = COLOR_CODE.BG_GREEN;
                                        } else {
                                            let fdd: string[] = fd.filter((idx) => idx_with_color[idx] === COLOR_CODE.BG_GRAY); //get all still uncoloured indexes
                                            if (fdd[0]) {
                                                idx_with_color[fdd[0]] = COLOR_CODE.BG_RED;
                                            }
                                        }
                                        found_index.splice(index, 1); //since either green red or discarded should not appear in next iteration
                                        index--; //splice reduced the array by 1
                                    }
                                }
                            });

                            //color letter box
                            for (let letter in curr_word_des) {
                                const idx_with_color = curr_word_des[letter];
                                Object.keys(idx_with_color).forEach((idx) => {
                                    const cal_index: number = parseInt(idx);
                                    const color: string = idx_with_color[idx];
                                    updateColorOfSquare(cal_index, color);
                                });
                            }

                            if (checkWordIsMatched()) {
                                //active word found
                                if (!isGameOver) {
                                    OnEditBorderColor(COLOR_CODE.BG_GREEN);
                                }
                            }
                            SndMngr.playSound(SoundType.USERWINGAME);
                        } else {
                            //word not found
                            SndMngr.playSound(SoundType.ERROR);
                            OnEditBorderColor(BORDERCOLORS.RED);
                            clearRow();
                        }
                    } else {
                        //alert message of not enough letter
                        SndMngr.playSound(SoundType.ERROR);
                        OnEditBorderColor(BORDERCOLORS.RED);
                        clearRow();
                    }
                }
                break;

            default:
                setBoard(
                    produce((board_state) => {
                        if (!board_state.length) {
                            board_state.push([{ letter: key, color: "" }]);
                        } else {
                            let last_row: SquareType[] =
                                board_state[board_state.length - 1];
                            if (
                                last_row.length === MAXCOLUMN &&
                                last_row.every(
                                    ({ color }) =>
                                        !color.includes(BORDERCOLORS.RED) &&
                                        color !== ""
                                )
                            ) {
                                board_state.push([{ letter: key, color: "" }]);
                            } else if (last_row.length < MAXCOLUMN) {
                                last_row.push({ letter: key, color: "" });
                            }
                        }
                    })
                );
        }
    };

    const handleKeyPress = (keyStroke: string) => {
        inputRef.current?.focus();
        const key = keyStroke.toUpperCase();
        const found_key = KEYBOARDLETTER.find((letter) => letter === key);
        if (found_key) {
            SndMngr.playSound(SoundType.CLICKBTN);
            updateBoardProperty(key);
        }
    };

    const renderLetterInSquare = () => {
        let total_boxes: JSX.Element[] = [];

        for (let row = 0; row < MAXROW; row++) {
            for (let col = 0; col < MAXCOLUMN; col++) {
                const board_data: BoardType =
                    decodeBoardDataWithColorCode(board);
                let square_data: SquareType | undefined =
                    board_data[row]?.[col];
                let square_color = "";
                let square_letter = "";
                if (square_data) {
                    square_color = square_data.color;
                    square_letter = square_data.letter;
                }
                if (square_color === "") {
                    square_color = BORDERCOLORS.YELLOW;
                }
                if (!square_color.includes("text-")) {
                    square_color = square_color + " text-primary-700";
                }
                total_boxes.push(
                    <input
                        className={` uppercase text-center font-roboto font-extrabold text-3xl xsm:text-xl rounded-md border-4  w-12 h-12 xsm:w-10 xsm:h-10 lg:w-16 lg:h-16 focus:outline-none ${square_color} `}
                        value={square_letter}
                        readOnly={true}
                        disabled={GameOverData.isGameOver ? true : false}
                        key={`${row}-${col}`}
                        ref={inputRef}
                    />
                );
            }
        }
        return (
            <form
                className={`grid grid-cols-${MAXCOLUMN} gap-1 py-5 xsm:py-2 self-center`}
                onKeyDown={(event) => handleKeyPress(event.key)}
            >
                {total_boxes}
            </form>
        );
    };

    const toggleStatsPopup = () => {
        setShowStats(!showStats);
    };

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    const getScore = (board_: BoardType): number => {
        //score = how many square are success + how many chances left
        let success_square: number = 0;
        let chances_left: number = MAXROW - board_.length;
        let dic_success_letter: { [letter: string]: string } = {};
        board_.forEach((row) =>
            row.forEach(({ letter, color }) => {
                if (color === COLOR_CODE.BG_GREEN) {
                    dic_success_letter[letter] = color;
                }
            })
        );
        success_square = Object.keys(dic_success_letter).length;
        return (success_square + chances_left) * EACH_SQUARE_SCORE;
    };

    const onHintClicked = () => {
        setIsLoading(true);
        SndMngr.playSound(SoundType.CLICKBTN);
    };

    const renderGameOver = () => {
        return (
            <GameOver
                title={titleWordHunt}
                gameScore={GameOverData.data.score ?? getScore(board)}
                onClose={() =>
                    navigator(
                        isAllGameOver &&
                            !dailyWordPuzzleDataAPI.hasVistedFinalPage
                            ? pathFinalScore
                            : pathIndexOfGame
                    )
                }
                handleGameOverPopup={()=>setShowGameOverPopup(false)}
            />
        );
    };

    const renderMostRecentPuzzleAnswers = (): JSX.Element | null => {
        const previous_words: string | undefined = dailyWordPuzzleDataAPI.getMostRecentPuzzleAnswers()?.wordhunt;
        let previousWordJSX: JSX.Element | null = null;
        if (previous_words) {
            previousWordJSX = (
                <>
                    <img
                        src={WordsOfLastPlayedIcon}
                        alt="icon"
                        className=" h-10 w-10 text-black cursor-pointer self-end"
                        onClick={() => {
                            SndMngr.playSound(SoundType.DROPBTNSOUND)
                            ReactGA.event({
                                category: "click",
                                action: "history",
                                label: "wordhunt",
                                value: 1,
                            });
                            setShowMostRecentPuzzleAnswers(true)
                        }}
                    />

                    {showMostRecentPuzzleAnswers && (
                        <div className="absolute z-20">
                            <LastDayWord
                                words={previous_words ? [previous_words] : []}
                                onPress={() => setShowMostRecentPuzzleAnswers(false)}
                            />
                        </div>
                    )}
                </>
            );
        }
        return previousWordJSX;
    };

    const renderGameAfterTutorial = () => {
        if (isLoading) {
            return <Tutorial setShowTutorial={setIsLoading} />;
        } else {
            return (
                <>
                    <div className="flex relative w-[300px] lg:w-[380px] justify-between items-center  mb-1 md:mb-4 flex-row-reverse">
                        <BlupIcon
                            style={`h-12 w-12 text-black self-center cursor-pointer rounded-full `}
                            clicked={() => onHintClicked()}
                            // ntfNumber={showHint.ntfNumber}
                            // isGameCmpltd={GameOverData.isGameOver}
                        />
                        {renderMostRecentPuzzleAnswers()}
                    </div>
                    {renderLetterInSquare()}
                    <KeyBoard
                        clicked={
                            !GameOverData.isGameOver ? handleKeyPress : () => {}
                        }
                        boardData={decodeBoardDataWithColorCode(board)}
                    />
                    {(isGameOver || GameOverData.isGameOver || isAllGameOver) && showGameOverPopup && renderGameOver()}
                </>
            );
        }
    };

    return (
        <>
            <Header
                title={titleWordHunt}
                setMenuIsLoading={setIsLoading}
                onMenuHintClicked={() => {}}
                onMenuStatsClicked={toggleStatsPopup}
                onMenuHelpClicked={() => {
                    handleShow();
                }}
            />
            {showHelp && <HelpDeskPopup closeHelp={handleClose} />}
            {/* <ProgressBar
                p1={true}
                p2={isGameOver || GameOverData.isGameOver}
                p3={false}
                style={` pb-2 md:pb-8`}
            /> */}
            <div className="flex flex-col h-full justify-center items-center">
                {showStats && <StatsPopup closeStats={toggleStatsPopup} />}
                {renderGameAfterTutorial()}
            </div>
        </>
    );
};
