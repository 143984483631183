import { useSelector } from "react-redux";
import { kCompleted, kWordsLeft } from "../../../Commons/constant";
import { RootState } from "../../../Commons/types";
import Fade from "../../../Component/Animation/Fade";

const SelectedWord = () => {
    let selected_word = useSelector((state: RootState) => state.selectedWord);

    let bgColor = selected_word.is_selected
        ? `border-primary-100`
        : selected_word.already_seleted
        ? `bg-warning-600`
        : `bg-warning-400`;

    let selected_word_jsx: JSX.Element = (
        <div
            className={` ${bgColor} flex flex-row self-center p-1 md:p-2 mb-2 md:mb-4 rounded-md`}
        >
            <p className="px-3 md:py-2 text-sm  h-6 sm:text-2xl sm:h-8  md:h-12 bg-white text-center rounded font-roboto font-extrabold">
                {selected_word.word_left
                    ? selected_word.word_left === -1
                        ? `0 ${kWordsLeft}`
                        : `${selected_word.word_left} ${kWordsLeft}`
                    : `${kCompleted}`}
            </p>
        </div>
    );

    if (selected_word.letters.length) {
        selected_word_jsx = (
            <div
                className={`${bgColor} flex flex-row self-center p-1 md:p-2  mb-2 md:mb-4 rounded-md`}
            >
                {selected_word.letters.map((letter, index) => {
                    return (
                        <Fade key={index}>
                            <p className="flex justify-center items-center bg-white w-6 h-6 sm:w-8 sm:h-8 sm:text-3xl md:w-12 md:h-12  text-center text-sm font-bold uppercase rounded md:px-3 md:py-1 mx-1 lg:text-4xl">
                                {letter}
                            </p>
                        </Fade>
                    );
                })}
            </div>
        );
    }
    return selected_word_jsx;
};
export default SelectedWord;
