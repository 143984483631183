import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDrag } from "@use-gesture/react";
import {
    RJWordSearchBoardData,
    RJWordSearchPoint,
    RJWordSearchGridPoint,
    LineDirection,
    RJWordSearchCallBackHandler,
    RJNtfActions,
    gameOverReason,
} from "./Types";
import LineSvg from "./LineSvg";
import BlupIcon from "../../Component/SVG/BlupIcon";
import { Tutorial } from "../WordSearch/Tutorial/Tutorial";
import WordsOfLastPlayedIcon from "../../Assets/svg/WordsOfLastPlayedIcon.svg";

import {
    RJSLineColourLst,
    RJS_TEXT_WIDTH_PERCENT,
    RJS_SVG_LINE_OPACITY,
    RJS_SVG_LINE_WIDTH_PERCENT,
    titleWordSearch,
    RJS_EACH_VALID_WORD_WIDTH_PERCENT,
} from "./Constants";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { pathFinalScore, pathIndexOfGame, SHOW_TUTORIAL_ONCE } from "../../Commons/constant";
import { useNavigate } from "react-router-dom";
import GameOver from "../../Component/GameOver/GameOver";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import { LastDayWord } from "../../Component/LastDayWord/LastDayWord";
import ReactGA from "react-ga4";
import lclStorage from "../../Utils/LocalStorage";
import { ShowTutorialOnceType } from "../../Commons/types";

let isEqual = require("lodash.isequal");

export const WordSearchBoard: React.FC<RJWordSearchBoardData & RJWordSearchCallBackHandler> = (
    props: RJWordSearchBoardData & RJWordSearchCallBackHandler
): JSX.Element => {
    let navigator = useNavigate();
    let [columns, setColumns] = useState<number>(0);
    let [from, setFrom] = useState<RJWordSearchGridPoint>();
    let [to, setTo] = useState<RJWordSearchPoint>();
    let [boardRect, setBoardRect] = useState<DOMRect>();
    let [showGameOverPopup, setShowGameOverPopup] = useState<boolean>(false);
    let [isAllGameOver, setIsAllGameOver] = useState<boolean>(false);
    const [showTutorial, setShowtutorial] = useState<boolean>(true);
    const [showMostRecentPuzzleAnswers, setShowMostRecentPuzzleAnswers] = useState<boolean>(false);

    const boardRef = useRef<HTMLDivElement>(null);
    const getColorCode = (idx: number, opacity: number): string => {
        let color = RJSLineColourLst[idx] ?? RJSLineColourLst[0];
        let acolor = color.replace("xx", opacity.toFixed(2));
        return acolor;
    };

    useEffect(() => {
        const onWordSearchBoardInit = async () => {
            setColumns(props?.board?.[0].length ?? 0);
            const tutorial_once: string | null = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
            if (tutorial_once) {
                let parse_tut_once_data: ShowTutorialOnceType = JSON.parse(tutorial_once);
                if (parse_tut_once_data?.wordSearchTutShown) {
                    setShowtutorial(false);
                    SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                }
            }
        };
        onWordSearchBoardInit();
        const handleResize = () => {
            updateBoardWidth();
            // let timer: NodeJS.Timeout;
            // return () => {
            //     clearTimeout(timer);
            //     timer = setTimeout(() => {
            //         updateBoardWidth();
            //     }, 0);
            // };
        };
        window.addEventListener("resize", handleResize);
        setTimeout(() => {
            updateBoardWidth();
        }, 20);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateBoardWidth();
    }, [columns]);

    useEffect(() => {
        if (showTutorial === false) {
            updateBoardWidth();
        }
    }, [showTutorial]);

    useEffect(() => {
        if (props.gameOverReason === gameOverReason.NORMAL) {
            setShowGameOverPopup(true);
            dailyWordPuzzleDataAPI.isAllGameOver().then((is_all_game_over) => setIsAllGameOver(is_all_game_over));
        }
    }, [props.gameOverReason]);

    const renderPreviousLines = (): JSX.Element[] | null => {
        if (boardRect !== null && boardRect !== undefined) {
            let tbdrect = boardRect;
            const svg_line_width: number = Math.floor((tbdrect.width / columns) * RJS_SVG_LINE_WIDTH_PERCENT);
            let aa = props.linesOnBoard.map((qq) => {
                let centerfrom: RJWordSearchPoint = convertGridPointToPoint(qq.from);
                let centerto: RJWordSearchPoint = convertGridPointToPoint(qq.to);
                let linedir: LineDirection = getLineDirection(qq.from, qq.to);
                let word = getCurrentWordMade(qq.from, qq.to, linedir);
                let wordrev = word.split("").reverse().join("");
                let idx = props.valword.indexOf(word);
                let revidx = props.valword.indexOf(wordrev);
                if (revidx !== -1) {
                    word = wordrev;
                    idx = revidx;
                }
                let colour: string = getColorCode(idx, RJS_SVG_LINE_OPACITY);
                return (
                    <LineSvg
                        key={JSON.stringify({ from: centerfrom, to: centerto })}
                        line={{ from: centerfrom, to: centerto }}
                        style={{
                            ...styles.lineStyle,
                            ...{ top: tbdrect.top + "px", left: tbdrect.left + "px" },
                            ...{ color: colour },
                        }}
                        width={svg_line_width}
                    />
                );
            });
            return aa;
        }
        return null;
    };

    const renderCurrentLine = (): JSX.Element | null => {
        if (!boardRect) return null;
        let tbdrect = boardRect;
        if (from && to) {
            let centerfrom: RJWordSearchPoint = convertGridPointToPoint(from);
            const svg_line_width: number = Math.floor((tbdrect.width / columns) * RJS_SVG_LINE_WIDTH_PERCENT);
            return (
                <LineSvg
                    line={{ from: centerfrom, to }}
                    style={{ ...styles.lineStyle, ...{ top: tbdrect.top + "px", left: tbdrect.left + "px" } }}
                    width={svg_line_width}
                />
            );
        } else {
            return null;
        }
    };

    // this function needs the point and rect to be in the same coordinate system or to have the same origin
    const rectContainsPoint = (rect: DOMRect, point: RJWordSearchPoint): boolean => {
        let xisinrect: boolean = rect.left <= point.x && rect.right >= point.x;
        let yisinrect: boolean = rect.top <= point.y && rect.bottom >= point.y;
        return xisinrect && yisinrect;
    };

    const convertGridPointToPoint = (point: RJWordSearchGridPoint): RJWordSearchPoint => {
        let eachwh: number = (boardRect?.width ?? 0) / columns;
        let x = point.c * eachwh + eachwh / 2;
        let y = point.r * eachwh + eachwh / 2;
        return { x, y };
    };

    const convertPointToGridPoint = (point: RJWordSearchPoint): RJWordSearchGridPoint => {
        let eachwh: number = (boardRect?.width ?? 0) / columns;
        let r: number = Math.floor(point.y / eachwh);
        let c: number = Math.floor(point.x / eachwh);
        return { r, c };
    };

    let updateBoardWidth = () => {
        const rect: DOMRect | undefined = boardRef?.current?.getBoundingClientRect();
        setBoardRect(rect);
    };

    const onLineDraw = useDrag((state) => {
        let isGameOver: boolean = props.gameOverReason !== gameOverReason.NONE;
        if (boardRect && !isGameOver) {
            if (state?.down) {
                let xx: number = state?.xy[0]; // superview coordinate system
                let yy: number = state?.xy[1]; // superview coordinate system
                if (rectContainsPoint(boardRect, { x: xx, y: yy })) {
                    let pxy: RJWordSearchPoint = { x: xx - boardRect.left, y: yy - boardRect.top }; // view coordinate system
                    let gp: RJWordSearchGridPoint = convertPointToGridPoint(pxy);
                    if (!from) {
                        setFrom(gp);
                        setTo(pxy);
                    } else {
                        setTo(pxy);
                    }
                }
            } else {
                if (to && from) {
                    let gp: RJWordSearchGridPoint = convertPointToGridPoint(to);
                    //validation
                    let linedir: LineDirection = getLineDirection(from, gp);
                    let wordalreadyfound = wordAlreadyPresent(from, gp);
                    if (linedir !== LineDirection.NONE && !wordalreadyfound) {
                        //find word made
                        let word = getCurrentWordMade(from, gp, linedir);
                        let wordrev = word.split("").reverse().join("");
                        let idx = props.valword.indexOf(word);
                        let revidx = props.valword.indexOf(wordrev);
                        if (revidx !== -1) {
                            word = wordrev;
                        }

                        if (idx !== -1 || revidx !== -1) {
                            props.callBack({ action: RJNtfActions.WORDFOUND, data: { from, to: gp, word } });
                        }
                    }
                    setFrom(undefined);
                    setTo(undefined);
                    //end validation
                }
            }
        }
    });

    const getLineDirection = (tfrom: RJWordSearchGridPoint, tto: RJWordSearchGridPoint): LineDirection => {
        let linedir: LineDirection = LineDirection.NONE;
        let issamerow: boolean = tfrom.r === tto.r;
        let issamecol: boolean = tfrom.c === tto.c;
        let isdiagonal: boolean = Math.abs(tfrom.r - tto.r) === Math.abs(tfrom.c - tto.c);
        if (issamerow) {
            linedir = LineDirection.HORIZONTAL;
        } else if (issamecol) {
            linedir = LineDirection.VERTICAL;
        } else if (isdiagonal) {
            linedir = LineDirection.DIAGONAL;
        }
        return linedir;
    };

    const wordAlreadyPresent = (from: RJWordSearchGridPoint, to: RJWordSearchGridPoint) => {
        let found = props.linesOnBoard.filter((uu) => {
            return (isEqual(uu.from, from) && isEqual(uu.to, to)) || (isEqual(uu.from, to) && isEqual(uu.to, from));
        });
        return found.length > 0;
    };

    const getCurrentWordMade = (tfrom: RJWordSearchGridPoint, tto: RJWordSearchGridPoint, linedir: LineDirection): string => {
        let wordmade: string = "";
        if (linedir === LineDirection.HORIZONTAL) {
            let gridpointarr: RJWordSearchGridPoint[] = [tfrom, tto];
            gridpointarr.sort((a, b) => a.c - b.c);
            wordmade = props?.board?.[gridpointarr[0].r].slice(gridpointarr[0].c, gridpointarr[1].c + 1).join("");
        } else if (linedir === LineDirection.VERTICAL) {
            let gridpointarr: RJWordSearchGridPoint[] = [tfrom, tto];
            gridpointarr.sort((a, b) => a.r - b.r);
            wordmade = props?.board
                ?.slice(gridpointarr[0].r, gridpointarr[1].r + 1)
                .map((ss) => {
                    return ss[gridpointarr[0].c];
                })
                .join("");
        } else if (linedir === LineDirection.DIAGONAL) {
            let numltrs = Math.abs(tfrom.r - tto.r) + 1;
            let qq: Array<RJWordSearchGridPoint> = Array(numltrs)
                .fill(0)
                .map((_, idx) => {
                    let r = tfrom.r < tto.r ? tfrom.r + idx : tfrom.r - idx;
                    let c = tfrom.c < tto.c ? tfrom.c + idx : tfrom.c - idx;
                    return { r, c };
                });

            wordmade = qq
                .map((uu) => {
                    return props?.board?.[uu.r][uu.c];
                })
                .join("");
        }
        return wordmade;
    };

    const renderBoardTiles = (): JSX.Element[] => {
        let elements: JSX.Element[] = props.board.map((q, idx) => {
            return (
                <div key={"r_" + idx} style={styles.tileRowStyle}>
                    {q.map((z, xid) => {
                        return (
                            <div
                                key={"rc_" + idx + "_" + xid}
                                style={{
                                    ...styles.tileStyle,
                                    fontSize: `${Math.floor((boardRect?.width ?? 0) / columns) * RJS_TEXT_WIDTH_PERCENT}px`,
                                }}
                                className="font-roboto"
                            >
                                {z}
                            </div>
                        );
                    })}
                </div>
            );
        });
        return elements;
    };

    const renderValWords = (): JSX.Element[] => {
        let elements: JSX.Element[] = props.valword.map((q, idx) => {
            return (
                <div
                    key={"r_" + idx}
                    className={`${props.wordsFound.includes(q) ? "line-through decoration-[#FCA605] decoration-2" : ""} `}
                    style={{
                        fontSize: `${Math.floor((boardRect?.width ?? 0) / columns) * RJS_EACH_VALID_WORD_WIDTH_PERCENT}px`,
                    }}
                >
                    {q}
                </div>
            );
        });
        return elements;
    };

    const renderBoardUI = (): JSX.Element | null => {
        if (columns) {
            return (
                <>
                    <div className="flex w-[80%] justify-between flex-row-reverse">
                        <BlupIcon
                            style={`h-10 w-10 text-black self-center  cursor-pointer rounded-full `}
                            clicked={() => onHintClicked()}
                        />
                        {renderMostRecentPuzzleAnswers()}
                    </div>
                    <div className="w-[80%] h-[14%] grid grid-rows-4 grid-flow-col text-center font-roboto text-[#1F508F] bg-[#F5F5F5]">
                        {renderValWords()}
                    </div>
                    <div style={styles.boardUiStyle} {...onLineDraw()} ref={boardRef}>
                        {renderBoardTiles()}
                        {renderPreviousLines()}
                        {renderCurrentLine()}
                    </div>
                </>
            );
        }
        return null;
    };

    const setTutorialOnceToLclStr = async () => {
        let tut_once: ShowTutorialOnceType = {
            wordChainTutShow: false,
            wordHuntTutShown: false,
            assemblerTutShown: false,
            wordKnitTutShown: false,
            wordspiderTutShown: false,
            wordAlikeTutShown: false,
            wordSearchTutShown: true,
        };
        const tutorial_once: string | null = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
        if (tutorial_once) {
            tut_once = JSON.parse(tutorial_once);
            if (!tut_once?.wordSearchTutShown) {
                tut_once.wordSearchTutShown = true;
            }
        }
        lclStorage.setItem(SHOW_TUTORIAL_ONCE, JSON.stringify(tut_once));
    };

    const onStartGame = () => {
        setShowtutorial(false);
        setTutorialOnceToLclStr();
        SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
    };
    const onHintClicked = () => {
        setShowtutorial(true);
        SndMngr.playSound(SoundType.CLICKBTN);
    };
    const renderMostRecentPuzzleAnswers = (): JSX.Element | null => {
        const previous_words: string[] | undefined = dailyWordPuzzleDataAPI.getMostRecentPuzzleAnswers()?.wordsearch;
        let previousWordJSX: JSX.Element | null = null;
        if (previous_words && previous_words.length) {
            previousWordJSX = (
                <>
                    <img
                        src={WordsOfLastPlayedIcon}
                        alt="icon"
                        className=" h-10 w-10 cursor-pointer self-end text-black"
                        onClick={() => {
                            SndMngr.playSound(SoundType.DROPBTNSOUND);
                            setShowMostRecentPuzzleAnswers(true);
                            ReactGA.event({
                                category: "click",
                                action: "history",
                                label: "wordsearch",
                                value: 1,
                            });
                        }}
                    />
                    {showMostRecentPuzzleAnswers && (
                        <div className="absolute z-20">
                            <LastDayWord words={previous_words ?? []} onPress={() => setShowMostRecentPuzzleAnswers(false)} />
                        </div>
                    )}
                </>
            );
        }
        return previousWordJSX;
    };

    const renderGameOver = () => {
        let navPath: string = pathIndexOfGame;
        if (isAllGameOver && !dailyWordPuzzleDataAPI.hasVistedFinalPage) {
            navPath = pathFinalScore;
        }
        let uiGameOver: JSX.Element | null = null;
        if ((isAllGameOver || props.gameOverReason === gameOverReason.NORMAL) && showGameOverPopup) {
            uiGameOver = (
                <GameOver
                    title={titleWordSearch}
                    gameScore={props.score}
                    onClose={() => navigator(navPath)}
                    handleGameOverPopup={() => setShowGameOverPopup(false)}
                />
            );
        }
        return uiGameOver;
    };

    const renderBoard = () => {
        if (showTutorial) {
            return <Tutorial startGameClicked={onStartGame} />;
        } else {
            return (
                <div style={styles.boardStyle}>
                    {renderBoardUI()}
                    {renderGameOver()}
                </div>
            );
        }
    };
    return <>{renderBoard()}</>;
};

const styles = {
    lineStyle: {
        position: "absolute",
        overflow: "visible",
        color: "rgba(181,181,181,0.4)",
    },
    boardStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexBasis: "auto",
        flexGrow: "1",
        minWidth: "300px",
        maxWidth: "100%",
        aspectRatio: "1/1",
        backgroundColor: "#F5F5F5",
        flexDirection: "column" as "column",
        padding : 40,
    },
    boardUiStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        aspectRatio: "1/1",
        width: "80%",
        flexDirection: "column" as "column",
        backgroundColor: "#F5F5F5",
        touchAction: "none",
        color: "#393939",
        borderRadius: "6px",
        borderColor: "#EFEFEF",
    },
    tileStyle: {
        display: "flex",
        flexBasis: "auto",
        flexGrow: "1",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minWidth: "10px",
        maxWidth: "200px",
        aspectRatio: "1/1",
        backgroundColor: "#F5F5F5",
    },
    tileRowStyle: {
        display: "flex",
        width: "100%",
        flexDirection: "row" as "row",
        backgroundColor: "#EFEFEF",
    },
};
