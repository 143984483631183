import { BOARD_MAX, NUMBER_OF_BLOCK } from "../ScrambledBoard/ScrambledBoard";
import { DIRECTION, LINK_DIR } from "./constant";
import { BlockDataType, WordKnitServerDataType, TileDataType } from "./type";

//==============Block Arrangement=====================

export const coalesceBlocks = (linkedblocks: BlockDataType[], boardmax: number, minblkcnt: number = 3) => {
    let tlb: BlockDataType[] = JSON.parse(JSON.stringify(linkedblocks));
    let relatedblocks = getRelatedBlocks(tlb);
    let bidtilecnt = tlb.map((block) => {
        let tilecnt = block.blk.length;
        return { bid: block.bid, tilecnt };
    });
    let tocoalesce = bidtilecnt.filter((tt) => tt.tilecnt <= minblkcnt).map((mm) => mm.bid);
    let relatedblocktilecnt = relatedblocks
        .map((rb) => {
            let tcnt1 = bidtilecnt.find((mm) => mm.bid === rb[0]);
            let tcnt2 = bidtilecnt.find((mm) => mm.bid === rb[1]);
            let cnt = tcnt1!.tilecnt + tcnt2!.tilecnt;
            return { rb: rb, cnt };
        })
        .sort((a, b) => a!.cnt! - b!.cnt!);
    let tocoalescepair = relatedblocktilecnt.find((mm) => {
        return tocoalesce.includes(mm.rb[0]) || tocoalesce.includes(mm.rb[1]);
    });
    if (tocoalescepair) {
        let res = mergeBlocks(linkedblocks, boardmax, tocoalescepair.rb[0], tocoalescepair.rb[1], [0, 0]);
        if (res.updated) {
            linkedblocks = coalesceBlocks(res.blocks, boardmax);
        }
    }
    linkedblocks.forEach(block => delete block.merged)
    return linkedblocks;
};

export const prepareBlocks = (moveTiles: WordKnitServerDataType, noOfBlock: number) => {
    let blocks: string[][] = [];
    let mMoveTiles: WordKnitServerDataType = JSON.parse(JSON.stringify(moveTiles));
    //===================================
    //remove blank arrays
    let remblanks: string[][] = mMoveTiles.filter((move) => {
        return move.length > 0;
    });
    let nNoOfBlock = remblanks;
    if (noOfBlock < remblanks.length) {
        nNoOfBlock = remblanks.slice(0, noOfBlock);
    }
    let uncommittedBlocks: string[][] = [];
    //end remove blank arrays
    //===================================
    //filter single moves
    let mvremsingles: string[][] = nNoOfBlock.filter((move) => {
        return move.length > 1;
    });
    //end filter single moves
    //===================================
    mvremsingles.forEach((move) => {
        let rowArr: number[] = [];
        let colArr: number[] = [];
        move.forEach((str) => {
            let infoArr = str.split(",");
            rowArr.push(Number(infoArr[1]));
            colArr.push(Number(infoArr[2]));
        });

        let isSameRow = new Set(rowArr).size === 1;
        let isSameCol = new Set(colArr).size === 1;

        let isAllConnected = false;
        let operatingarray: number[] = [];
        if (isSameRow) {
            operatingarray = colArr;
        } else if (isSameCol) {
            operatingarray = rowArr;
        }

        let srted = operatingarray.slice(0).sort((a, b) => a - b);

        let minidx = srted[0];
        let isAnyOneDisconnect = srted
            .map((item, index) => {
                return item === index + minidx;
            })
            .includes(false);

        isAllConnected = !isAnyOneDisconnect;

        if (isAllConnected) {
            //shift directly
            blocks.push(move);
        } else {
            //merged with other

            let missingIdx = [];

            let count = srted[0];
            do {
                if (!srted.includes(count)) {
                    missingIdx.push(count);
                }
                count++;
            } while (count < srted[srted.length - 1]);
            let missingTile = [];
            if (isSameRow) {
                missingTile = missingIdx.map((cidx) => {
                    return [rowArr[0], cidx];
                });
            } else {
                missingTile = missingIdx.map((ridx) => {
                    return [ridx, colArr[0]];
                });
            }
            let blockmap = blocks.map((mm) => {
                return mm.map((nn) => {
                    let idx = nn.split(",");
                    return idx.slice(1, 3).join();
                });
            });
            let mtmap = missingTile.map((mm) => {
                return mm.join(",");
            });

            let cntidx = blockmap.findIndex((move) => {
                return mtmap.every((r) => move.includes(r));
            });

            if (cntidx !== -1) {
                blocks[cntidx].push(...move);
            } else {
                move.forEach((mm) => uncommittedBlocks.push([mm]));
            }
        }
    });
    //===================================
    //Manage Single Blocks

    let singleBlocks = nNoOfBlock.filter((move) => {
        return move.length === 1;
    });
    //treat uncommittedBlocks as singleBlocks
    singleBlocks.push(...uncommittedBlocks);
    singleBlocks.forEach((move) => {
        let idata = move[0].split(",");
        let row = Number(idata[1]);
        let col = Number(idata[2]);
        let adjectIndex = [
            [row, col - 1],
            [row, col + 1],
            [row - 1, col],
            [row + 1, col],
        ];

        let containerIndex = blocks.findIndex((item) => {
            return item.find((ele) => {
                let idx = ele.split(",");
                return adjectIndex.find((midx) => {
                    return midx[0] === Number(idx[1]) && midx[1] === Number(idx[2]);
                });
            });
        });

        if (containerIndex !== -1) {
            blocks[containerIndex].push(...move);
        }
    });

    // End Manage Single Blocks
    //===================================
    return blocks;
};

//==============Block To Info Conversion=====================
export const linkBlocks = (blocks: string[][], boardmax: number) => {
    let brd: ("-" | { id: number; bid: number })[][] = Array(boardmax)
        .fill("")
        .map(() => Array(boardmax).fill("-"));
    let charId = 1;
    let temp_converted_block = blocks.map((block, idx) => {
        let blk = block.map((data) => {
            let tileInfo = data.split(",");
            let row = Number(tileInfo[1]);
            let col = Number(tileInfo[2]);

            let blk = {
                rw: row,
                cl: col,
                ro: row,
                co: col,
                ch: tileInfo[3],
                id: charId++,
            };
            return { ...blk };
        });
        blk.forEach((tile) => {
            let id = tile.id;
            brd[tile.rw][tile.cl] = { id, bid: idx + 1 };
        });
        let bid = idx + 1;
        return { bid, blk };
    });

    let converted_block = temp_converted_block.map(({ bid, blk }, idx) => {
        let temp_block = blk.map((btile) => {
            let { rw, cl, ch, id } = btile;
            let adjectIndex = [
                [rw, cl - 1 < 0 ? cl : cl - 1, LINK_DIR.LEFT],
                [rw, cl + 1 >= boardmax ? cl : cl + 1, LINK_DIR.RIGHT],
                [rw - 1 < 0 ? rw : rw - 1, cl, LINK_DIR.TOP],
                [rw + 1 >= boardmax ? rw : rw + 1, cl, LINK_DIR.BOTTOM],
            ];
            let mm: {
                [tileDirection in LINK_DIR]?: {
                    id: number;
                    bid: number;
                };
            } = {};
            adjectIndex.forEach((rc) => {
                let tile = brd[rc[0] as number][rc[1] as number];

                if (tile !== "-" && tile !== undefined && tile.bid !== idx + 1) {
                    switch (rc[2] as LINK_DIR) {
                        case LINK_DIR.LEFT:
                            mm[LINK_DIR.LEFT] = { ...tile };
                            break;
                        case LINK_DIR.RIGHT:
                            mm[LINK_DIR.RIGHT] = { ...tile };
                            break;
                        case LINK_DIR.TOP:
                            mm[LINK_DIR.TOP] = { ...tile };
                            break;
                        case LINK_DIR.BOTTOM:
                            mm[LINK_DIR.BOTTOM] = { ...tile };
                            break;
                    }
                }
            });
            return { ...btile, rw, cl, ch, id, ...mm };
        });
        temp_block.forEach((tile) => {
            let id = tile.id;
            brd[tile.rw][tile.cl] = { id, bid };
        });
        return { bid, blk: temp_block };
    });

    return converted_block;
};
//==============RowDelta ColumnDelta=====================
export const getProbablePositionCombination = () => {
    let arr = [];
    for (let row = 0; row < 15; row++) {
        for (let col = 0; col < 15; col++) {
            if (row === 0 && col === 0) {
                continue;
            }
            arr.push([row, col]);
            if (col !== 0) {
                arr.push([row, -col]);
            }
            if (row !== 0) {
                arr.push([-row, col]);
            }
            if (row !== 0 && col !== 0) {
                arr.push([-row, -col]);
            }
        }
    }
    return arr;
};

//==============Scramble Block=====================
export const scrambleBlock = (blocks: BlockDataType[], boardmax: number): BlockDataType[] => {
    let brd: string[][] = Array(boardmax)
        .fill("")
        .map(() => Array(boardmax).fill("-"));
    let positionCombination = getProbablePositionCombination();
    positionCombination.sort(() => 0.5 - Math.random()); //shuffle the array
    let tempblocks: BlockDataType[] = JSON.parse(JSON.stringify(blocks));
    tempblocks.sort((bl1, bl2) => {
        return bl2.blk.length - bl1.blk.length;
    });
    let scrm: BlockDataType[] = tempblocks.map((block, idx) => {
        let { bid, blk } = block;
        let passed: boolean = false;
        // let mm = null;
        let mm: TileDataType[] = [];
        for (let cmbidx = 0; cmbidx < positionCombination.length; cmbidx++) {
            passed = true;
            let rowdelta = positionCombination[cmbidx][0];
            let coldelta = positionCombination[cmbidx][1];
            let blkcln: TileDataType[] = JSON.parse(JSON.stringify(blk));
            // eslint-disable-next-line no-loop-func
            let pp = blkcln.map((tile) => {
                //largest block should be at original place
                if (idx !== 0) {
                    let { rw, cl } = tile;
                    let nwrow = rw + rowdelta;
                    let nwcol = cl + coldelta;
                    //new row and column validation
                    if (!(nwrow >= 0 && nwrow < boardmax && nwcol >= 0 && nwcol < boardmax)) {
                        passed = false;
                    }
                    //will be placed at blank position validation
                    if (passed === true && brd[nwrow][nwcol] !== "-") {
                        passed = false;
                    }
                    tile.rw = nwrow;
                    tile.cl = nwcol;
                }
                return tile;
            });
            //disjoint validation
            if (passed === true) {
                // eslint-disable-next-line no-loop-func
                pp.forEach(({ rw, cl }) => {
                    let tb = cl - 1 < 0 ? true : brd[rw][cl - 1] === "-";
                    let bb = cl + 1 >= boardmax ? true : brd[rw][cl + 1] === "-";
                    let lb = rw - 1 < 0 ? true : brd[rw - 1][cl] === "-";
                    let rb = rw + 1 >= boardmax ? true : brd[rw + 1][cl] === "-";
                    if (passed === true) {
                        passed = tb && bb && lb && rb;
                    }
                });
            }
            if (passed === true) {
                //if passed update board array for upcomming checks
                pp.forEach(({ rw, cl, ch }) => {
                    brd[rw][cl] = ch;
                });
            }
            mm = pp;
            if (passed === true) {
                break;
            }
        }
        if (passed === false) {
            throw new Error("Please adjust number of blocks and try again");
        }
        blk = mm;
        return { bid, blk };
    });
    return scrm;
};

//==============Reated Block=====================
export const getRelatedBlocks = (blocks: BlockDataType[]) => {
    let relatedBlocks: string[] = [];
    blocks.forEach((moves) => {
        let eachRelatedblock: string[] = [];
        moves.blk.forEach((move) => {
            Object.keys(move).forEach((i) => {
                const tile_move_key = i as keyof typeof move;
                if (i === "lt" || i === "rt" || i === "tp" || i === "bt") {
                    const tile_move_bid = move[tile_move_key] as {
                        id: number;
                        bid: number;
                    };
                    eachRelatedblock.push(JSON.stringify([moves.bid, tile_move_bid.bid].sort((a, b) => a - b)));
                }
            });
        });
        relatedBlocks.push(...eachRelatedblock);
    });

    return Array.from(new Set(relatedBlocks), (i) => JSON.parse(i));
};

//==============Displacement Block Checking=====================
export const willDisplacementOverwrite = (
    blocks: BlockDataType[],
    boardmax: number,
    srcbid: number,
    displacement: number[]
) => {
    let srcBlockInfo = blocks.filter((i) => i.bid === srcbid)[0];
    let brd = Array(boardmax)
        .fill("")
        .map(() => Array(boardmax).fill("-"));

    blocks.forEach(({ bid, blk }, idx) => {
        if (srcBlockInfo.bid !== bid) {
            //exclude src bid as its the one whose position is being changed
            blk.forEach((tile) => {
                brd[tile.rw][tile.cl] = tile.ch;
            });
        }
    });

    let allDplcMoves = srcBlockInfo.blk.map((srcMove) => {
        return [srcMove.rw + displacement[0], srcMove.cl + displacement[1]];
    });

    for (let rc of allDplcMoves) {
        let [r, c] = rc;
        if (r >= boardmax || c >= boardmax || r < 0 || c < 0) return true;
        if (brd[r][c] !== "-") {
            return true;
        }
    }

    return false;
};

//==============Displacement Block=====================
export const getMergeDisplacement = (
    blocks: BlockDataType[],
    boardmax: number,
    srcbid: number,
    targetbid: number
): number[][] | number[] => {
    let targetBlockInfo = blocks.filter((i) => i.bid === targetbid)[0];
    let srcBlockInfo = blocks.filter((i) => i.bid === srcbid)[0];
    let disRowColArr: number[][] = [];
    if (targetBlockInfo === null || targetBlockInfo === undefined || srcBlockInfo === null || srcBlockInfo === undefined) {
        return disRowColArr;
    }
    srcBlockInfo.blk.forEach((srcMove) => {
        Object.keys(srcMove).forEach((key) => {
            if (key === "tp" || key === "bt" || key === "lt" || key === "rt") {
                targetBlockInfo.blk.forEach((trgtMove) => {
                    if (srcMove[key]?.id === trgtMove.id) {
                        let [row, col] = [trgtMove.rw, trgtMove.cl];
                        switch (key) {
                            case "lt":
                                col = col + 1;
                                break;
                            case "rt":
                                col = col - 1;
                                break;
                            case "tp":
                                row = row + 1;
                                break;
                            case "bt":
                                row = row - 1;
                                break;
                        }
                        let diseRowCol = [row - srcMove.rw, col - srcMove.cl];
                        disRowColArr.push(diseRowCol);
                    }
                });
            }
        });
    });

    let willDisplacementDisturb = false;
    if (disRowColArr.length > 0) {
        willDisplacementDisturb = willDisplacementOverwrite(blocks, boardmax, srcbid, disRowColArr[0]);
    }

    let hasMultipleDisRC = new Set(disRowColArr.map((i) => JSON.stringify(i))).size !== 1;

    if (hasMultipleDisRC || willDisplacementDisturb) {
        return [];
    }

    return disRowColArr[0];
};

//==============Can Blocks Be Marged=====================
export const canBlocksBeMerged = (blocks: BlockDataType[], srcbid: number, targetbid: number): boolean => {
    let srcblock = blocks.filter((block) => {
        return block.bid === srcbid;
    });
    let targetblock = blocks.filter((block) => {
        return block.bid === targetbid;
    });
    if (srcblock.length === 0 || targetblock.length === 0) {
        return false;
    }
    let canbemerged = false;
    let tblk = srcblock.find(({ bid, blk }) => {
        let found = false;
        for (let tile of blk) {
            found =
                tile.bt?.bid === targetbid ||
                tile.rt?.bid === targetbid ||
                tile.lt?.bid === targetbid ||
                tile.tp?.bid === targetbid;
            if (found) {
                break;
            }
        }
        return found;
    });
    canbemerged = tblk != null && tblk !== undefined;
    return canbemerged;
};

//==============Merge Block=====================
export const mergeBlocks = (
    blocks: BlockDataType[],
    boardmax: number,
    srcbid: number,
    targetbid: number,
    displacement: number[]
) => {
    let sblocks: BlockDataType[] = JSON.parse(JSON.stringify(blocks));
    let targetBlockInfo = sblocks.filter((i) => i.bid === targetbid)[0];
    let srcBlockInfo = sblocks.filter((i) => i.bid === srcbid)[0];
    if (targetBlockInfo === null || targetBlockInfo === undefined || srcBlockInfo === null || srcBlockInfo === undefined) {
        return { updated: false, blocks };
    }
    let displaceRowCol = getMergeDisplacement(blocks, boardmax, srcbid, targetbid) as number[];
    if (displacement != null) {
        let qq = JSON.stringify(displaceRowCol);
        let uu = JSON.stringify(displacement);
        if (qq !== uu) {
            return {
                updated: false,
                blocks,
            };
        }
    }
    if (displaceRowCol.length < 1) {
        return {
            updated: false,
            blocks,
        };
    }
    let mfdBlocks = sblocks
        .map((moves, idx) => {
            if (moves.bid === targetbid) {
                let srcChIds: number[] = [];
                let mdfBlk = srcBlockInfo.blk.map((tile) => {
                    srcChIds.push(tile.id);
                    return {
                        ...tile,
                        rw: (tile.rw + displaceRowCol[0]) as number,
                        cl: (tile.cl + displaceRowCol[1]) as number,
                    };
                });
                //=================Get Merged Id Array ============
                let isTrgtMergedPresent = Object.keys(targetBlockInfo).includes("merged");

                let finalMerged: number[][] = [];

                let isSrcMergedPresent = Object.keys(srcBlockInfo).includes("merged");

                if (isTrgtMergedPresent && targetBlockInfo?.merged) {
                    finalMerged = [...targetBlockInfo.merged];
                }

                if (isSrcMergedPresent && srcBlockInfo?.merged) {
                    let srcMergedChIds = srcBlockInfo.merged.flat();
                    let srcPrimalChIds = srcChIds.map((chId) => {
                        let required_child:number|undefined
                        if (!srcMergedChIds.includes(chId)) {
                            required_child = chId
                        }
                        return required_child;
                    }).filter((i) => i !== null && i !== undefined);

                    finalMerged = [...finalMerged, ...srcBlockInfo.merged];
                    if (srcPrimalChIds.length > 0) {
                        finalMerged = [...finalMerged, srcPrimalChIds as number[]];
                    }
                } else {
                    finalMerged = [...finalMerged, srcChIds];
                }

                moves.merged = finalMerged;

                // Removing Top, Botoom, Left, Right Keys from Merged Moves
                moves.blk.push(...mdfBlk);
                moves.blk.forEach((item, indx) => {
                    Object.keys(item).forEach((key) => {
                        const tile_move_key = key as keyof typeof item;
                        const tile_move = item[tile_move_key] as {
                            id: number;
                            bid: number;
                        };
                        if (
                            (key === "tp" || key === "bt" || key === "lt" || key === "rt") &&
                            (tile_move?.bid === srcBlockInfo.bid || tile_move?.bid === targetBlockInfo.bid)
                        ) {
                            delete moves.blk[indx][key];
                        }
                    });
                });
            } else {
                moves.blk.forEach((item, indx) => {
                    Object.keys(item).forEach((key) => {
                        const tile_move_key = key as keyof typeof item;
                        const tile_move = item[tile_move_key] as {
                            id: number;
                            bid: number;
                        };
                        if (
                            (key === "tp" || key === "bt" || key === "lt" || key === "rt") &&
                            tile_move?.bid === srcBlockInfo.bid
                        ) {
                            tile_move.bid = targetBlockInfo.bid;
                        }
                    });
                });
            }

            // Removing Merged Block
            if (moves.bid === srcbid) {
                // eslint-disable-next-line array-callback-return
                return;
            }
            return moves;
        })
        .filter((i) => i !== null && i !== undefined);

    return { updated: true, blocks: mfdBlocks as BlockDataType[] };
};
//==============Building Block=====================
export const buildBlock = (moveTiles: WordKnitServerDataType, noOfBlock: number, boardmax: number) => {
    let mdfiedBlocks = prepareBlocks(moveTiles, noOfBlock);
    let linkedBlock = linkBlocks(mdfiedBlocks, boardmax);
    let coalescedLinkedBlocks = coalesceBlocks(linkedBlock, boardmax);
    let scrambledBlock = scrambleBlock(coalescedLinkedBlocks, boardmax);

    return {
        scrambledBlock,
    };
};

export const moveBlockBy = (
    blocks: BlockDataType[],
    boardmax: number,
    srcbid: number,
    displacement: number[]
): {
    updated: boolean;
    blocks: BlockDataType[];
} => {
    let willoverwrite = willDisplacementOverwrite(blocks, boardmax, srcbid, displacement);
    let updated = {
        updated: false,
        blocks,
    };
    if (willoverwrite === false) {
        let tmpboard: BlockDataType[] = JSON.parse(JSON.stringify(blocks));
        let block = tmpboard.find((bb) => bb.bid === srcbid);
        if (block) {
            block.blk.forEach((tile) => {
                tile.rw = tile.rw + displacement[0];
                tile.cl = tile.cl + displacement[1];
            });
            updated = {
                updated: true,
                blocks: tmpboard,
            };
        }
    }
    return updated;
};

export const getAdjacentBlocks = (
    blocks: BlockDataType[],
    boardmax: number,
    srcbid: number,
    displacement: number[] = [0, 0]
) => {
    let movedblkres = moveBlockBy(blocks, boardmax, srcbid, displacement);
    let adjacentblocks: number[] = [];
    if (movedblkres.updated === true) {
        let srcblock: BlockDataType | undefined = movedblkres.blocks.find((block) => block.bid === srcbid);
        if (srcblock) {
            //2d array board
            let brd = Array(boardmax)
                .fill("")
                .map(() => Array(boardmax).fill(0));
            movedblkres.blocks.forEach(({ bid, blk }, idx) => {
                if (srcbid !== bid) {
                    //dont place srcbid on board
                    blk.forEach((tile) => {
                        brd[tile.rw][tile.cl] = bid;
                    });
                }
            });
            srcblock.blk.forEach((tile) => {
                if (
                    tile.rw + 1 < 15 &&
                    brd[tile.rw + 1][tile.cl] !== 0 &&
                    !adjacentblocks.includes(brd[tile.rw + 1][tile.cl])
                ) {
                    adjacentblocks.push(brd[tile.rw + 1][tile.cl]);
                }
                if (
                    tile.rw - 1 >= 0 &&
                    brd[tile.rw - 1][tile.cl] !== 0 &&
                    !adjacentblocks.includes(brd[tile.rw - 1][tile.cl])
                ) {
                    adjacentblocks.push(brd[tile.rw - 1][tile.cl]);
                }
                if (
                    tile.cl + 1 < 15 &&
                    brd[tile.rw][tile.cl + 1] !== 0 &&
                    !adjacentblocks.includes(brd[tile.rw][tile.cl + 1])
                ) {
                    adjacentblocks.push(brd[tile.rw][tile.cl + 1]);
                }
                if (
                    tile.cl - 1 < 15 &&
                    brd[tile.rw][tile.cl - 1] !== 0 &&
                    !adjacentblocks.includes(brd[tile.rw][tile.cl - 1])
                ) {
                    adjacentblocks.push(brd[tile.rw][tile.cl - 1]);
                }
            });
        }
    }
    return adjacentblocks;
};

export const isBlockAtOriginalPosition = (blocks: BlockDataType[], srcbid: number) => {
    if (srcbid) {
        let srcblock: BlockDataType | undefined = blocks.find((block) => block.bid === srcbid);
        if (srcblock) {
            if (srcblock.blk.length > 0) {
                let tile = srcblock.blk[0];
                if (tile.rw === tile.ro && tile.cl === tile.co) {
                    return true;
                }
            }
        }
    }
    return false;
};

const traversal = (
    brd: string[][],
    rw: number,
    cl: number,
    direction: DIRECTION,
    dest_blk: BlockDataType,
    flag: boolean,
    letters: string[]
) => {
    const ch: string | undefined = brd[rw]?.[cl]?.trim().toUpperCase();
    //checking same blk or not
    if (flag === false) {
        const is_found = dest_blk.blk.find((tile) => tile.rw === rw && tile.cl === cl);
        if (is_found) {
            flag = true;
        }
    }

    //base case
    if (!ch) {
        return { letters, flag };
    } else {
        switch (direction) {
            case DIRECTION.LEFT:
                letters.unshift(ch);
                traversal(brd, rw, cl - 1, DIRECTION.LEFT, dest_blk, flag, letters);
                return { letters, flag };
            case DIRECTION.RIGHT:
                letters.push(ch);
                traversal(brd, rw, cl + 1, DIRECTION.RIGHT, dest_blk, flag, letters);
                return { letters, flag };
            case DIRECTION.TOP:
                letters.unshift(ch);
                traversal(brd, rw - 1, cl, DIRECTION.TOP, dest_blk, flag, letters);
                return { letters, flag };
            case DIRECTION.BUTTON:
                letters.push(ch);
                traversal(brd, rw + 1, cl, DIRECTION.BUTTON, dest_blk, flag, letters);
                return { letters, flag };
        }
    }
};

export const getMergedWord = (
    block_info: BlockDataType[],
    scrblkid: number,
    destblkid: number,
    boardSize: number
): string[] => {
    let required_word: string[] = [];

    const blk_info: BlockDataType[] = JSON.parse(JSON.stringify(block_info));

    const src_blk: BlockDataType | undefined = blk_info.find(({ bid }) => bid === scrblkid);
    const dest_blk: BlockDataType | undefined = blk_info.find(({ bid }) => bid === destblkid);

    if (src_blk && dest_blk) {
        //create board
        let brd: string[][] = Array(boardSize)
            .fill("")
            .map(() => Array(boardSize).fill(""));

        //injecting source and destination block
        [src_blk, dest_blk].forEach(({ blk }) =>
            blk.forEach(({ rw, cl, ch }) => {
                brd[rw][cl] = ch;
            })
        );

        src_blk.blk.forEach(({ rw, cl, ch }) => {
            let required_res = traversal(brd, rw, cl - 1, DIRECTION.LEFT, dest_blk, false, [ch]);
            required_res = traversal(brd, rw, cl + 1, DIRECTION.RIGHT, dest_blk, required_res.flag, required_res.letters);
            if (required_res.flag) {
                required_word.push(required_res.letters.join("").trim().toUpperCase());
            }
            required_res = traversal(brd, rw - 1, cl, DIRECTION.TOP, dest_blk, false, [ch]);
            required_res = traversal(brd, rw + 1, cl, DIRECTION.BUTTON, dest_blk, required_res.flag, required_res.letters);
            if (required_res.flag) {
                required_word.push(required_res.letters.join("").trim().toUpperCase());
            }
        });
    }
    return required_word;
};

export const getAllWordsToBeMade = (wordKnitServerData?: WordKnitServerDataType): string[] => {
    let required_word: string[] = [];
    if (wordKnitServerData) {
        let mdfiedBlocks = prepareBlocks(wordKnitServerData, NUMBER_OF_BLOCK);
        let linkedBlock = linkBlocks(mdfiedBlocks, BOARD_MAX);
        let coalescedLinkedBlocks = coalesceBlocks(linkedBlock, BOARD_MAX);
        let already_placed_block = coalescedLinkedBlocks[coalescedLinkedBlocks.length - 1];
        while (coalescedLinkedBlocks.length > 1) {
            for (let i = 0; i < coalescedLinkedBlocks.length; i++) {
                let src_blk: BlockDataType = coalescedLinkedBlocks[i];
                let merged_info = mergeBlocks(coalescedLinkedBlocks, BOARD_MAX, src_blk.bid, already_placed_block.bid, [0, 0]);
                if (merged_info.updated) {
                    required_word.push(
                        ...getMergedWord(coalescedLinkedBlocks, src_blk.bid, already_placed_block.bid, BOARD_MAX)
                    );
                    coalescedLinkedBlocks = merged_info.blocks;
                    break;
                }
            }
        }
    }
    return required_word;
};
