import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathFinalScore, pathIndexOfGame, SHOW_TUTORIAL_ONCE } from "../../Commons/constant";
import { ShowTutorialOnceType } from "../../Commons/types";
import GameOver from "../../Component/GameOver/GameOver";
import Header from "../../Component/Header/Header";
import { LastDayWord } from "../../Component/LastDayWord/LastDayWord";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import BlupIcon from "../../Component/SVG/BlupIcon";
import lclStorage from "../../Utils/LocalStorage";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { Tutorial } from "./Tutorial/Tutorial";
import { titleWordAlike } from "./Utils/constants";
import { WordAlikeFinGameType } from "./Utils/type";
import { WordAlikeContainer } from "./WordAlikeContainer/WordAlikeContainer";
import WordsOfLastPlayedIcon from "../../Assets/svg/WordsOfLastPlayedIcon.svg";
import ReactGA from "react-ga4";

export const WordAlike = () => {
    const [isTutorialOpen, setIsTutorialOpen] = useState<boolean>(true);
    const [isAllGameOver, setIsAllGameOver] = useState<boolean>(false);
    const [gameOverData, setGameOverData] = useState<WordAlikeFinGameType | undefined>();
    const [gameOverPopup, setGameOverPopup] = useState<{ alreadyTrigger: boolean; showGameOverPopup: boolean }>({
        alreadyTrigger: false,
        showGameOverPopup: false,
    });
    const [showHelp, setShowHelp] = useState<boolean>(false);
    let navigator = useNavigate();
    const [showMostRecentPuzzleAnswers, setShowMostRecentPuzzleAnswers] = useState<boolean>(false);

    useEffect(() => {
        const onWordAlikeInit = async () => {
            const tutorial_once: string | null = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
            if (tutorial_once) {
                let parse_tut_once_data: ShowTutorialOnceType = JSON.parse(tutorial_once);
                if (parse_tut_once_data?.wordAlikeTutShown) {
                    setIsTutorialOpen(false);
                    SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                }
            }
        };
        onWordAlikeInit();
    }, []);

    useEffect(() => {
        dailyWordPuzzleDataAPI.isAllGameOver().then((isAllGameOver) => {
            if (isAllGameOver) {
                setIsAllGameOver(isAllGameOver);
            }
        });
    }, [gameOverData]);

    const setTutorialOnceToLclStr = async () => {
        let tut_once: ShowTutorialOnceType = {
            wordChainTutShow: false,
            wordHuntTutShown: false,
            assemblerTutShown: false,
            wordKnitTutShown: false,
            wordspiderTutShown: false,
            wordAlikeTutShown: true,
            wordSearchTutShown: false,
        };
        const tutorial_once: string | null = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
        if (tutorial_once) {
            tut_once = JSON.parse(tutorial_once);
            if (!tut_once?.wordAlikeTutShown) {
                tut_once.wordAlikeTutShown = true;
            }
        }
        lclStorage.setItem(SHOW_TUTORIAL_ONCE, JSON.stringify(tut_once));
    };

    const updateGameOverData = (game_over_data: WordAlikeFinGameType) => {
        setGameOverData(game_over_data);
        if (!gameOverPopup.alreadyTrigger) {
            setGameOverPopup({ alreadyTrigger: true, showGameOverPopup: true });
            SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
        }
    };

    const onTutorialClose = () => {
        setIsTutorialOpen(false);
        SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
        setTutorialOnceToLclStr();
    };

    const renderGameOver = () => {
        let navPath: string = pathIndexOfGame;
        if (isAllGameOver && !dailyWordPuzzleDataAPI.hasVistedFinalPage) {
            navPath = pathFinalScore;
        }
        let uiGameOver: JSX.Element | null = null;
        if ((isAllGameOver || gameOverData?.isGameOver) && gameOverPopup.showGameOverPopup) {
            uiGameOver = (
                <GameOver
                    title={titleWordAlike}
                    gameScore={gameOverData?.isGameOver ? gameOverData?.score : 0}
                    onClose={() => navigator(navPath)}
                    handleGameOverPopup={() => setGameOverPopup({ alreadyTrigger: true, showGameOverPopup: false })}
                />
            );
        }
        return uiGameOver;
    };

    const renderBulpIcon = () => {
        return (
            <BlupIcon
                style={styles.icon}
                clicked={() => {
                    SndMngr.playSound(SoundType.CLICKBTN);
                    setIsTutorialOpen(true);
                }}
            />
        );
    };

    const renderTutorial = () => {
        let ui_tutorial: JSX.Element | null = null;
        if (isTutorialOpen) {
            ui_tutorial = <Tutorial onTutorialClose={onTutorialClose} />;
        }
        return ui_tutorial;
    };

    const renderMostRecentPuzzleAnswers = (): JSX.Element | null => {
        const previous_words: string[] | undefined = dailyWordPuzzleDataAPI.getMostRecentPuzzleAnswers()?.wordalike;
        let previousWordJSX: JSX.Element | null = null;
        if (previous_words && previous_words.length) {
            previousWordJSX = (
                <>
                    <img
                        src={WordsOfLastPlayedIcon}
                        alt="icon"
                        className=" h-10 w-10 cursor-pointer self-end text-black"
                        onClick={() => {
                            SndMngr.playSound(SoundType.DROPBTNSOUND);
                            setShowMostRecentPuzzleAnswers(true);
                            ReactGA.event({
                                category: "click",
                                action: "history",
                                label: "wordalike",
                                value: 1,
                            });
                        }}
                    />
                    {showMostRecentPuzzleAnswers && (
                        <div className="absolute z-20">
                            <LastDayWord words={previous_words ?? []} onPress={() => setShowMostRecentPuzzleAnswers(false)} />
                        </div>
                    )}
                </>
            );
        }
        return previousWordJSX;
    };

    return (
        <div className={styles.mainContainer}>
            <Header title={titleWordAlike} onMenuHelpClicked={() => setShowHelp(true)} />
            {showHelp && <HelpDeskPopup closeHelp={() => setShowHelp(false)} />}
            {renderTutorial()}
            <div className="flex h-full w-fit flex-col justify-center self-center">
                {!isTutorialOpen && (
                    <div className="flex flex-row-reverse justify-between">
                        {renderBulpIcon()}
                        {renderMostRecentPuzzleAnswers()}
                    </div>
                )}
                {!isTutorialOpen && <WordAlikeContainer updateGameOverData={updateGameOverData} />}
            </div>
            {renderGameOver()}
        </div>
    );
};

const styles = {
    mainContainer: "flex flex-col w-screen h-screen bg-white select-none ",
    icon: "h-12 w-12 text-black self-end cursor-pointer m-2 rounded-full",
};
