import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { SelectedWordType } from "../../../Commons/types";

const initialState: SelectedWordType = {
    letters: [],
    is_selected: false,
    word_left: -1,
    already_seleted: false,
};

export const selectedWordSlice = createSlice({
    name: "selectedWord",
    initialState,
    reducers: {
        insertSelectedLetter(state, action: PayloadAction<string>) {
            state.letters.push(action.payload);
        },
        updateSelection(state, action: PayloadAction<boolean>) {
            state.is_selected = action.payload;
        },
        resetSelectedWord: (state) => {
            state.letters = [];
            state.is_selected = false;
        },

        removeLastLetter(state) {
            state.letters.pop();
        },
        setInitialWordLeft(state, action: PayloadAction<number>) {
            state.word_left = action.payload;
        },
        setAlreadySelected(state, action: PayloadAction<boolean>) {
            state.already_seleted = action.payload;
        },
        decreaseWordLeft(state) {
            state.word_left--;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    insertSelectedLetter,
    resetSelectedWord,
    updateSelection,
    removeLastLetter,
    setInitialWordLeft,
    setAlreadySelected,
    decreaseWordLeft,
} = selectedWordSlice.actions;

export default selectedWordSlice.reducer;
