import { useCallback, useEffect, useState } from "react";

// import ProgressBar from "../../Component/ProgressBar/ProgressBar";
import {
    pathFinalScore,
    pathIndexOfGame,
    ASSEMBLER_INP_GAME_DATA,
    titleAssembler,
    ASSEMBLER_FIN_GAME_DATA,
    SHOW_TUTORIAL_ONCE,
} from "../../Commons/constant";
import Header from "../../Component/Header/Header";
import BlupIcon from "../../Component/SVG/BlupIcon";
// import Timer from "../../Component/Timer/Timer";
// import useTimer from "../../Hooks/useTimer";
import {
    // useTimerType,
    HintType,
    // TimerPropType,
    // AssemblerInpGameType,
    ShowTutorialOnceType,
} from "../../Commons/types";
import GameOver from "../../Component/GameOver/GameOver";
import ColumnContainer from "./ColumnContainer/ColumnContainer";
import { useNavigate } from "react-router-dom";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import { Tutorial } from "./Tutorial/Tutorial";
import { StatsPopup } from "./GameStats/StatsPopup";
import lclStorage from "../../Utils/LocalStorage";
import { AssemblerFinGameType, ColumnType } from "../../Commons/types";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import ReactGA from "react-ga4";
import WordsOfLastPlayedIcon from "../../Assets/svg/WordsOfLastPlayedIcon.svg";
import { LastDayWord } from "../../Component/LastDayWord/LastDayWord";

export const Assembler = () => {
    let navigator = useNavigate();
    const [showHelp, setShowHelp] = useState(false);
    // const [timer, setTimer] = useState<number>(0);
    const updateScore = useCallback((scores: number) => {
        setScore(scores);
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [words, setWords] = useState<string[]>([]);
    const [score, setScore] = useState<number>(0);
    const [showHint, setShowHint] = useState<HintType>({
        show: false,
        ntfNumber: 0,
    });
    // const [isPause, setIsPause] = useState<boolean>(false);
    const [wordCompleted, setWordCompleted] = useState<boolean>(false);
    const [showStats, setShowStats] = useState(false);
    // const { startTimer, remainingTime, isTimeEnd, stopTimer }: useTimerType =
    //     useTimer(isPause);

    const [showMostRecentPuzzleAnswers, setShowMostRecentPuzzleAnswers] = useState<boolean>(false)

    const [GameOverData, setGameOverData] = useState<AssemblerFinGameType>({
        isGameOver: false,
        data: {
            wordsState: [],
            hintData: showHint,
            // initialTime: getTimeInHHMMSS(timer),
            // cmpltdTime: { hr: 0, min: 0, sec: 0 },
            score: 0,
            wordsFound: [],
            tstmp:0
        },
    });
    const [isAllGameOver, setIsALlGameOver] = useState<boolean>(false);
    const [showGameOverPopup, setShowGameOverPopup] = useState<boolean>(false);

    const getColorCode = (
        Data: Array<Array<{ letter: string; colorCode: string }>>
    ): ColumnType => {
        let wordData = [];
        for (let item of Data) {
            let gData = [];
            for (let ele of item) {
                let colorType = "bg-warning-400";
                if (ele.colorCode === "NFD") {
                    colorType = "bg-danger-900";
                }
                gData.push({
                    letter: ele.letter,
                    color: colorType,
                });
            }
            wordData.push(gData);
        }
        return wordData;
    };

    useEffect(() => {
        const onInitAssembler = async () => {
            //-----------------------------------------
            let showtutorial:ShowTutorialOnceType = {
                wordChainTutShow: false,
                wordHuntTutShown: false,
                assemblerTutShown: false,
                wordKnitTutShown:false,
                wordspiderTutShown: false,
                wordAlikeTutShown: false,
                wordSearchTutShown: false,
            };
            let tt = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
            if (tt !== null && tt !== undefined) {
                showtutorial = JSON.parse(tt);
            }
            //-----------------------------------------
            // let initialtime = -1;
            let word_columns: string[] = [];
            let completedgamedata = await lclStorage.getItem(
                ASSEMBLER_FIN_GAME_DATA
            );
            if (completedgamedata !== null && completedgamedata !== undefined) {
                let gData = JSON.parse(completedgamedata);
                let gameData: any = {
                    isGameOver: gData.isGameOver,
                    data: {
                        wordsState: getColorCode(gData.data.wordsState),
                        hintData: gData.data.hintData,
                        // initialTime: gData.data.initialTime,
                        // cmpltdTime: gData.data.cmpltdTime,
                        score: gData.data.score,
                        lastActiveWord: gData.data.lastActiveWord,
                        tstmp: Math.floor(Date.now() / 1000)
                    },
                };
                setGameOverData(gameData);
                ReactGA.event({
                    category: "game_over",
                    action: "revisited",
                    label: "assembler",
                    value: 1,
                });
                setShowGameOverPopup(true)
            } else {
                let resumegame = await lclStorage.getItem(ASSEMBLER_INP_GAME_DATA);
                if (resumegame !== null && resumegame !== undefined) {
                    // let assembler_data: AssemblerInpGameType =
                    //     JSON.parse(resumegame);
                    // initialtime = assembler_data.timer;
                    ReactGA.event({
                        category: "game_start",
                        action: "resume",
                        label: "assembler",
                        value: 1,
                    });
                } else {
                    let frshdata = await dailyWordPuzzleDataAPI.assemblerData();
                    if (frshdata) {
                        // initialtime = frshdata.duration;
                        word_columns = frshdata.wordcolumns;
                        ReactGA.event({
                            category: "game_start",
                            action: "new_game",
                            label: "assembler",
                            value: 1,
                        });
                    }
                }
            }
            // if (initialtime !== -1) {
            //     setTimer(initialtime);
            //     startTimer(initialtime);
            // }

            //-----------------------------------------
            if (showtutorial.assemblerTutShown === false) {
                showtutorial.assemblerTutShown = true;
                setIsLoading(showtutorial.assemblerTutShown);
                lclStorage.setItem(
                    SHOW_TUTORIAL_ONCE,
                    JSON.stringify(showtutorial)
                );
            } else {
                onStartGame();
            }

            if (isWordValidate(word_columns)) {
                setWords(word_columns);
            }

            //-----------------------------------------
        };

        onInitAssembler();
    }, []);

    useEffect(() => {
        if (GameOverData.isGameOver) {
            setIsLoading(false);
            setShowHint({
                show: false,
                ntfNumber: GameOverData.data.hintData.ntfNumber,
            });
            // startTimer(getTimeInSec(GameOverData.data.cmpltdTime));
            // setIsPause(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GameOverData.isGameOver]);

    useEffect(() => {
        setTimeout(() => {
            dailyWordPuzzleDataAPI.isAllGameOver().then((data) => {
                if (data) {
                    setIsALlGameOver(data);
                }
            });
        }, 100);
        if (!GameOverData.isGameOver) {
            //game has completed either by timeover or all words found
            // if(isTimeEnd){
            //     ReactGA.event({
            //         category: "game_over",
            //         action: "time_over",
            //         label: "assembler",
            //         value: 1,
            //     });
            // } else
            if (wordCompleted) {
                SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                ReactGA.event({
                    category: "game_over",
                    action: "finished_game",
                    label: "assembler",
                    value: 1,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordCompleted]);

    const onStartGame = () => {
        setIsLoading(false);
        SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
        // if (isPause) {
        //     let remainingpauseTime =
        //         {
        //             hr: remainingTime.hr,
        //             min: remainingTime.min,
        //             sec: remainingTime.sec + 1,
        //         } ?? timer;
        //     startTimer(getTimeInSec(remainingpauseTime));
        //     setIsPause(false);
        // }
    };

    // function getTimeInSec(time: {
    //     hr: number;
    //     min: number;
    //     sec: number;
    // }): number {
    //     const total_time_in_sec = time.hr * 3600 + time.min * 60 + time.sec;
    //     return total_time_in_sec;
    // }

    // function getTimeInHHMMSS(totalTimeInSec: number): TimerPropType {
    //     let hr = Math.floor(totalTimeInSec / 3600); // get hours
    //     let min = Math.floor((totalTimeInSec - hr * 3600) / 60); // get minutes
    //     let sec = totalTimeInSec - hr * 3600 - min * 60; //  get seconds
    //     return { hr, min, sec };
    // }

    // useEffect(() => {
    //     if (wordCompleted) {
    //         setIsPause(true);
    //         stopTimer();
    //     }
    //     if (isPause) {
    //         stopTimer();
    //         if (!wordCompleted && !showStats && !GameOverData.isGameOver) {
    //             setIsLoading(true);
    //         }
    //     }
    // }, [isPause, wordCompleted]);

    const toggleStatsPopup = () => {
        if (showStats) {
            setShowStats(false);
            // setIsPause(false);
        } else {
            setShowStats(true);
            // setIsPause(true);
        }
    };

    function isWordValidate(words: string[]): boolean {
        //only checking length of words for now
        let isValidate: boolean = false;
        let first_word_len = words[0]?.split("").length;
        isValidate = words.every(
            (word) => word.split("").length === first_word_len
        );
        return isValidate;
    }

    const onHintClicked = () => {
        setIsLoading(true);
        // setIsPause(true);
        // if (showHint.ntfNumber > 0) {
        //     setShowHint({
        //         show: !showHint.show,
        //         ntfNumber:
        //             showHint.ntfNumber === 0 ? 0 : showHint.ntfNumber - 1,
        //     });
        // }
    };

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    const renderMostRecentPuzzleAnswers = (): JSX.Element | null => {
        const previous_words: string[] | undefined = dailyWordPuzzleDataAPI.getMostRecentPuzzleAnswers()?.assembler;
        let previousWordJSX: JSX.Element | null = null;
        if (previous_words) {
            previousWordJSX = (
                <>
                    <img
                        src={WordsOfLastPlayedIcon}
                        alt="icon"
                        className=" h-10 w-10 text-black cursor-pointer self-end"
                        onClick={() => {
                            SndMngr.playSound(SoundType.DROPBTNSOUND);
                            ReactGA.event({
                                category: "click",
                                action: "history",
                                label: "assembler",
                                value: 1,
                            });
                            setShowMostRecentPuzzleAnswers(true)
                        }}
                    />
                    {showMostRecentPuzzleAnswers && (
                        <div className="absolute z-20">
                            <LastDayWord words={previous_words ?? []} onPress={() => setShowMostRecentPuzzleAnswers(false)} />
                        </div>
                    )}
                </>
            );
        }
        return previousWordJSX;
    };

    const renderGameAfterTutorial = () => {
        if (isLoading) {
            return <Tutorial startGame={onStartGame} />;
        } else {
            return (
                <div className={`flex flex-col h-full items-center pt-2`}>
                    <div
                        className={`flex relative w-[350px] justify-between items-center py-2s mb-1 md:mb-4 flex-row-reverse`}
                    >
                        {/* <Timer
                            hr={remainingTime.hr}
                            min={remainingTime.min}
                            sec={remainingTime.sec}
                        /> */}
                        <BlupIcon
                            style={`h-12 w-12 text-black self-center  cursor-pointer `}
                            clicked={() => {
                                SndMngr.playSound(SoundType.CLICKBTN);
                                onHintClicked();
                            }}
                            ntfNumber={showHint.ntfNumber}
                        />
                        {renderMostRecentPuzzleAnswers()}
                    </div>
                    <div className="flex h-[90%] w-full justify-center overflow-hidden items-center ">
                        <ColumnContainer
                            showHint={showHint}
                            words={words}
                            score={score}
                            scoreController={updateScore}
                            doGameOver={(isGameOver:boolean) => {
                                if (isGameOver) {
                                    setWordCompleted(isGameOver)
                                    setShowGameOverPopup(true)
                                }
                            }}
                            isGameOver={GameOverData.isGameOver}
                            onHintClicked={showHint.show}
                            // isTimeEnd={isTimeEnd}
                            // timer={getTimeInHHMMSS(timer)}
                            // remainingTime={remainingTime}
                            getColorCode={getColorCode}
                        />
                    </div>
                    {(isAllGameOver ||
                        wordCompleted ||
                        GameOverData.isGameOver) && showGameOverPopup && (
                        <GameOver
                            title={titleAssembler}
                            gameScore={score}
                            onClose={() =>
                                navigator(
                                    isAllGameOver &&
                                        !dailyWordPuzzleDataAPI.hasVistedFinalPage
                                        ? pathFinalScore
                                        : pathIndexOfGame
                                )
                            }
                            handleGameOverPopup={()=>setShowGameOverPopup(false)}
                        />
                    )}
                </div>
            );
        }
    };

    return (
        <div className="flex flex-col h-full ">
            <Header
                title={titleAssembler}
                // setMenuIsLoading={setIsPause}
                onMenuHintClicked={onHintClicked}
                onMenuStatsClicked={toggleStatsPopup}
                onMenuHelpClicked={() => {
                    handleShow();
                }}
            />
            {showHelp && <HelpDeskPopup closeHelp={handleClose} />}
            <div className="flex flex-col h-full">
                {/* <ProgressBar
                    p1={true}
                    p2={true}
                    p3={isTimeEnd || wordCompleted || GameOverData.isGameOver}
                    style={`pb-2 md:pb-8`}
                /> */}
                {showStats && <StatsPopup closeStats={toggleStatsPopup} />}
                {renderGameAfterTutorial()}
            </div>
        </div>
    );
};
