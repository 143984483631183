import { useEffect, useState } from "react";
import produce from "immer";
import {
    RJWordSearchGameData,
    RJNotificationData,
    RJNtfActions,
    gameOverReason,
    RJWordSearchInpGameType,
    RJWordSearchGridLine,
} from "./Types";
import { WordSearchBoard } from "./WordSearchBoard";
import { RJS_EACH_LETTER_SCORE, WORDSEARCH_INP_GAME_DATA } from "./Constants";
import lclStorage from "../../Utils/LocalStorage";
import { titleWordSearch } from "./Constants";
import Header from "../../Component/Header/Header";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import ReactGA from "react-ga4";

export const WordSearch = () => {
    let [gameData, setGameData] = useState<RJWordSearchGameData>();
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        const onWordSearchInit = async () => {
            initGameData();
        };
        onWordSearchInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBoardDataFromStr = (boardstr: string): Array<Array<string>> => {
        let board: Array<Array<string>> = boardstr.split(",").map((str: string) => {
            return str.split("");
        });
        return board;
    };

    const initGameData = async () => {
        const server_data = dailyWordPuzzleDataAPI.getWordSearchData();
        if(server_data){
            let board: string[][] = getBoardDataFromStr(server_data.board);
            let valword: string[] = server_data.valword.split(",");
            let numbonus: number = Number(server_data.numbonus);
            let lines_on_board: RJWordSearchGridLine[] = [];
            let words_found: string[] = [];
            let score: number = 0;
            let game_over_reason: gameOverReason = gameOverReason.NONE;

            let lcl_data: string | null = await lclStorage.getItem(WORDSEARCH_INP_GAME_DATA);
            if (lcl_data) {
                ReactGA.event({
                    category: "game_start",
                    action: "resume",
                    label: "wordsearch",
                    value: 1,
                });
                let parse_lcl_data: RJWordSearchInpGameType = JSON.parse(lcl_data);
                lines_on_board = parse_lcl_data.linesOnBoard;
                words_found = parse_lcl_data.wordsFound;
                score = parse_lcl_data.score;
                game_over_reason = parse_lcl_data.gameOverReason;
            } else {
                ReactGA.event({
                    category: "game_start",
                    action: "new_game",
                    label: "wordsearch",
                    value: 1,
                });
                let lcl_data: RJWordSearchInpGameType = {
                    linesOnBoard: [],
                    wordsFound: [],
                    score: 0,
                    gameOverReason: gameOverReason.NONE,
                    tstmp: Math.floor(Date.now() / 1000),
                };
                lclStorage.setItem(WORDSEARCH_INP_GAME_DATA, JSON.stringify(lcl_data));
            }

            let gamedata: RJWordSearchGameData = {
                board,
                valword,
                numbonus,
                linesOnBoard: lines_on_board,
                wordsFound: words_found,
                score,
                gameOverReason: game_over_reason,
            };
            setGameData(gamedata);
        }
    };

    const notificationHandler = (data: RJNotificationData) => {
        if (data.action === RJNtfActions.WORDFOUND) {
            setGameData(
                produce((game) => {
                    if (game) {
                        game.linesOnBoard.push({ from: data.data.from, to: data.data.to });
                        game.wordsFound.push(data.data.word);
                        if (game.wordsFound.length === game.valword.length) {
                            game.gameOverReason = gameOverReason.NORMAL;
                            ReactGA.event({
                                category: "game_over",
                                action: "finished_game",
                                label: "wordsearch",
                                value: 1,
                            });
                            SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                        } else {
                            SndMngr.playSound(SoundType.USERWINGAME);
                        }
                        game.score += data.data.word.length * RJS_EACH_LETTER_SCORE;

                        let lcl_data: RJWordSearchInpGameType = {
                            linesOnBoard: game.linesOnBoard,
                            wordsFound: game.wordsFound,
                            score: game.score,
                            gameOverReason: game.gameOverReason,
                            tstmp: Math.floor(Date.now() / 1000),
                        };
                        lclStorage.setItem(WORDSEARCH_INP_GAME_DATA, JSON.stringify(lcl_data));
                    }
                })
            );
        }
    };

    const getBoard = (): JSX.Element | null => {
        if (gameData?.board) {
            return (
                <WordSearchBoard
                    board={gameData.board}
                    valword={gameData.valword}
                    numbonus={gameData.numbonus}
                    linesOnBoard={gameData.linesOnBoard}
                    wordsFound={gameData.wordsFound}
                    score={gameData.score}
                    gameOverReason={gameData.gameOverReason}
                    callBack={notificationHandler}
                />
            );
        }
        return null;
    };

    const handleShow = () => {
        setShowHelp(true);
    };
    const handleClose = () => setShowHelp(false);

    return (
        <>
            <div className="flex flex-col h-full">
                <Header
                    title={titleWordSearch}
                    onMenuHelpClicked={() => {
                        handleShow();
                    }}
                />
                {showHelp && <HelpDeskPopup closeHelp={handleClose} />}
                <div className={styles.mainContainer}>{getBoard()}</div>
            </div>
        </>
    );
};

const styles = {
    mainContainer: "flex flex-col h-full bg-[#F5F5F5] select-none items-center justify-center",
};
