// import React, { useState } from "react";
import { Modal } from "../../../Component/Modal/Modal";
import CrossIcon from "../../../Assets/svg/CrossIcon.svg";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";

export const StatsPopup = (props: { closeStats: () => void }) => {
    const renderIcons = () => (
        <img
            src={CrossIcon}
            className={style.icon}
            onClick={() => {
                SndMngr.playSound(SoundType.RECALL);
                props.closeStats();
            }}
            alt = "crossIcon"
        />
    );

    const renderStats = () => {
        return (
            <div>
                <h6>Game Stats</h6>
            </div>
        );
    };

    return (
        <Modal>
            <div className={style.mainContainer}>
                <div className={`w-[280px] flex flex-row pt-8 justify-end`}>
                    {renderIcons()}
                </div>
                {renderStats()}
            </div>
        </Modal>
    );
};

const style = {
    mainContainer:
        "w-[350px] h-[500px] flex flex-col bg-info-200  rounded-3xl shadow-[4px_5px_6px_0px_rgba(0,0,0,0.5)] items-center",
    text: "font-montserrat text-[18px] font-medium px-8 ",
    icon: "w-9 h-9 stroke-secondary-600 cursor-pointer ",
};
