export const titleWordSearch = "Word Search";
export const pathWordSearch = "/Pages/WordSearch";

export const RJS_SVG_LINE_WIDTH_PERCENT = 0.6;
export const RJS_SVG_LINE_OPACITY = 0.4;
export const RJS_TEXT_WIDTH_PERCENT = 0.5;
export const RJS_EACH_VALID_WORD_WIDTH_PERCENT = 0.3;
export const RJS_EACH_LETTER_SCORE = 5;

export const RJSLineColourLst = [
    "rgba(245,0,255,xx)",
    "rgba(20,190,212,xx)",
    "rgba(252,166,5,xx)",
    "rgba(244,80,9,xx)",
    "rgba(245,0,255,xx)",
    "rgba(20,190,212,xx)",
    "rgba(252,166,5,xx)",
    "rgba(244,80,9,xx)",
    "rgba(245,0,255,xx)",
    "rgba(20,190,212,xx)",
    "rgba(252,166,5,xx)",
    "rgba(244,80,9,xx)",
];

export const WORDSEARCH_INP_GAME_DATA = "wordsearch_inp_game_data";

export const wrdSearchTutorial1 = "Find these given words in the board.";
export const wrdSearchTutorial2 = "Connect the letters in this board to form the given words";
