import React from "react";
import Tile from "./WordSpiderTile";
import {
    InitialTilesUSUK,
    ENGLISH_LETTER_SCORES,
    TOTAL_COLUMN,
    TOTAL_ROWS,
    SUFFLE_PENALTY_POINT,
    WrdMsg,
    titleWordSpider,
    SHOW_TUTORIAL_ONCE,
    WORDSPIDER_INP_GAME_DATA,
    WORDSPIDER_FIN_GAME_DATA,
    kCompleted,
} from "./Utils/Constants";
import { pathIndexOfGame, pathFinalScore } from "../../Commons/constant";
import { WordSpiderStateType, WordSpiderTileType, TempWordSpiderData, WeightDistType } from "./Utils/Types";
import Header from "../../Component/Header/Header";
import { HelpDeskPopup } from "../../Component/Menu/HelpDeskPopup";
import BlupIcon from "../../Component/SVG/BlupIcon";
import Fade from "../../Component/Animation/Fade";
import { Tutorial } from "./Tutorial/Tutorial";
import { SndMngr, SoundType } from "./../../Utils/SoundManager";
import lclStorage from "../../Utils/LocalStorage";
import { dailyWordPuzzleDataAPI } from "./../Introduction/api/DailyWordPuzzleDataAPI";
import ReactTooltip from "react-tooltip";
import GameOver from "../../Component/GameOver/GameOver";
import withRouter from "../WordSpider/withRouter";
import ReactGA from "react-ga4";
import ShuffleIcon from "../../Assets/svg/WordSpiderShuffleIcon.svg";
import { Controller, animated, AnimationResult } from "react-spring";
import { ShowTutorialOnceType } from "../../Commons/types";
interface Props {
    navigate: any;
}

class WordSpider extends React.Component<Props> {
    state: WordSpiderStateType = {
        selectedTiles: [],
        totalScore: 0,
        initialTiles: [],
        ShowHelp: false,
        isLoading: true,
        WordCount: 0,
        wordsFound: [],
        valid_word: true,
        tiles_left: 0,
        wordsCanBeFormed: true,
        isGameOver: false,
        isAllGameOver: false,
        totalWeight: 0,
        tilesbag: "",
        col: 0,
        showGameOverPopup: true,
    };

    showtutorial: ShowTutorialOnceType = {
        wordChainTutShow: false,
        wordHuntTutShown: false,
        assemblerTutShown: false,
        wordKnitTutShown: false,
        wordspiderTutShown: false,
        wordAlikeTutShown: false,
        wordSearchTutShown: false,
    };

    containerPositions: Array<number> = [];
    containerIDs: Array<number> = [];
    animations: Array<Controller> = [];
    animationIsRunning: boolean = false;

    componentDidMount = async () => {
        dailyWordPuzzleDataAPI.wordspiderData().then((wordspider) => {
            if (wordspider) {
                this.setState({ tilesbag: wordspider.tilesbag, col: wordspider.col });
            }
        });
        let resumegame = await lclStorage.getItem(WORDSPIDER_INP_GAME_DATA);
        let wordspider_data: TempWordSpiderData | null = null;
        let wordsCanBeFormed = false;
        if (resumegame !== null && resumegame !== undefined) {
            wordspider_data = JSON.parse(resumegame);
            ReactGA.event({
                category: "game_start",
                action: "resume",
                label: "wordspider",
                value: 1,
            });
            wordsCanBeFormed = true;
        } else {
            let gamecompleteddata = await lclStorage.getItem(WORDSPIDER_FIN_GAME_DATA);
            if (gamecompleteddata !== null && gamecompleteddata !== undefined) {
                wordspider_data = JSON.parse(gamecompleteddata);
                ReactGA.event({
                    category: "game_over",
                    action: "revisited",
                    label: "wordspider",
                    value: 1,
                });
            }
        }

        if (wordspider_data != null) {
            let tleft = 0;
            wordspider_data.initialTiles.forEach((rack) => {
                tleft += rack.length;
            });
            this.updateStoredTileContainerAnimations(wordspider_data.initialTiles);
            this.setState(
                {
                    initialTiles: wordspider_data.initialTiles,
                    totalScore: wordspider_data.totalScore,
                    WordCount: wordspider_data.WordCount,
                    wordsFound: wordspider_data.wordsFound,
                    tiles_left: tleft,
                    wordsCanBeFormed,
                    totalWeight: wordspider_data.totalWeight,
                    isGameOver: wordspider_data.isGameOver,
                },
                () => {
                    setTimeout(() => {
                        this.updateStoredTileContainerIDs();
                        this.updateStoredTileContainerPositions();
                    }, 0);
                }
            );
        } else {
            this.initTiles();
        }
        this.setState({ isLoading: true });
        let tt = await lclStorage.getItem(SHOW_TUTORIAL_ONCE);
        if (tt !== null && tt !== undefined) {
            this.showtutorial = JSON.parse(tt);
        }
        if (this.showtutorial.wordspiderTutShown === false) {
            this.showtutorial.wordspiderTutShown = true;
            lclStorage.setItem(SHOW_TUTORIAL_ONCE, JSON.stringify(this.showtutorial));
        } else {
            this.onStartGame();
        }
    };

    getShuffleTiles = (value: string): Array<string> => {
        let a = value.split(""),
            n = a.length;
        for (let i = n - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a;
    };

    getTileDistribution = (): string => {
        let strarr: string[] = Array.from(new Set(InitialTilesUSUK));
        let q = InitialTilesUSUK;
        let requiredlen = TOTAL_ROWS * TOTAL_COLUMN;
        let vowels = "AEIOU".split("");
        let weight: WeightDistType[] = [];
        strarr.forEach((ltr) => {
            let num = (q.match(new RegExp(ltr, "g")) || []).length;
            let pr = num / q.length;
            weight.push({ char: ltr, wt: pr * 100 });
        });
        let vowelWt = weight.filter((wt) => vowels.includes(wt.char));
        let consonantWt = weight.filter((wt) => !vowels.includes(wt.char));
        let vowelpcnt = vowelWt.reduce((total, cval) => total + cval.wt, 0.0);
        let consonantpcnt = consonantWt.reduce((total, cval) => total + cval.wt, 0.0);
        let totalvowelcnt = Math.round((vowelpcnt / 100) * requiredlen);
        let totalconsonantcnt = Math.round((consonantpcnt / 100) * requiredlen);
        let tmp = 0.0;
        let vowelCumulativeWt = vowelWt.map((wt) => {
            tmp += wt.wt * 100.0;
            wt.wt = tmp;
            return wt;
        });
        tmp = 0.0;
        let consonantCumulativeWt = consonantWt.map((wt) => {
            tmp += wt.wt * 100.0;
            wt.wt = tmp;
            return wt;
        });
        let nstr = "";
        let minWt = vowelCumulativeWt[0].wt;
        let maxWt = vowelCumulativeWt[vowelCumulativeWt.length - 1].wt;
        for (let i = 0; i < totalvowelcnt; i++) {
            let random = Math.random() * (maxWt - minWt) + minWt;
            let filtered = vowelCumulativeWt.filter((wt) => wt.wt < random);
            nstr += filtered.slice(-1)[0].char;
        }
        minWt = consonantCumulativeWt[0].wt;
        maxWt = consonantCumulativeWt[consonantCumulativeWt.length - 1].wt;
        for (let i = 0; i < totalconsonantcnt; i++) {
            let random = Math.random() * (maxWt - minWt) + minWt;
            let filtered = consonantCumulativeWt.filter((wt) => wt.wt < random);
            nstr += filtered.slice(-1)[0].char;
        }

        return nstr.split("").sort().join("");
    };

    initTiles = () => {
        // let shuffleTiles = this.getShuffleTiles(this.getTileDistribution());
        let shuffleTiles = this.state.tilesbag.split("");
        let lettrArr = [];
        let arrIndex = 0;
        let tempArr = [];
        let colLetterLimit = Math.ceil(shuffleTiles.length / this.state.col);
        for (let i = 0; i < shuffleTiles.length; i++) {
            let tile: WordSpiderTileType = {
                letter: shuffleTiles[i],
                score: this.getTileScore(shuffleTiles[i]),
                cindex: arrIndex,
                index: tempArr.length,
            };
            tempArr.push(tile);
            if (tempArr.length === colLetterLimit || i === shuffleTiles.length - 1) {
                lettrArr[arrIndex] = tempArr;
                tempArr = [];
                arrIndex++;
            }
        }
        // console.log("---initialTiles: ", lettrArr);
        let totalWeightOfAllTiles = 0;
        lettrArr.forEach((ele) => {
            ele.forEach((scr) => {
                totalWeightOfAllTiles += scr.score;
            });
        });
        this.updateStoredTileContainerAnimations(lettrArr);
        this.setState({ initialTiles: lettrArr, totalWeight: totalWeightOfAllTiles }, () => {
            setTimeout(() => {
                this.setTempWordSpiderDataToLclStg();
                this.updateStoredTileContainerIDs();
                this.updateStoredTileContainerPositions();
            }, 0);
        });
    };

    getTileScore = (letter: string): number => {
        let lScoreIndex = ENGLISH_LETTER_SCORES.indexOf(letter) + 2;
        let lScore = ENGLISH_LETTER_SCORES.charAt(lScoreIndex);
        return Number(lScore);
    };

    onTileSelect = (cindex: number, index: number, isSelected: boolean) => {
        let selectedTiles = [...this.state.selectedTiles];
        if(!this.state.isGameOver)
        if (isSelected) {
            selectedTiles.push(cindex);
        } else {
            selectedTiles = selectedTiles.filter((item) => item !== cindex);
        }
        this.setState({ selectedTiles }, () => {
            // console.log(this.state.selectedTiles);
        });
    };

    getBonusValue = () => (this.state.selectedTiles.length === this.state.col ? 40 : 0);

    getWordAndScore = (): string => {
        let word = "";
        let score = 0;
        this.state.selectedTiles.map((item: number) => {
            let colTiles: Array<WordSpiderTileType> = this.state.initialTiles[item];
            let tile: WordSpiderTileType = colTiles[colTiles.length - 1];
            word += tile.letter;
            score += Number(tile.score);
            return word;
        });
        score += this.getBonusValue();
        return word + " (" + score + ")";
    };

    getWord = (): string => {
        let word = "";
        this.state.selectedTiles.map((item: number) => {
            let colTiles: Array<WordSpiderTileType> = this.state.initialTiles[item];
            if (colTiles) {
                let tile: WordSpiderTileType = colTiles[colTiles.length - 1];
                word += tile.letter;
            }
            return word;
        });
        return word;
    };

    selectedWords = (): Array<string> => {
        let words = this.getWord();
        let j = words.split("");
        return j;
    };

    clearTiles = () => {
        this.setState({ selectedTiles: [] });
    };

    shuffleTiles = () => {
        this.clearTiles();
        let initialTiles = [...this.state.initialTiles];
        let tileLetters = "";
        initialTiles.map((colTiles: Array<WordSpiderTileType>) =>
            colTiles.map((tile: WordSpiderTileType) => (tileLetters += tile.letter))
        );

        let shuffleTiles = this.getShuffleTiles(tileLetters);
        let count = 0;
        initialTiles.map((colTiles: Array<WordSpiderTileType>) =>
            colTiles.map((tile: WordSpiderTileType) => {
                tile.letter = shuffleTiles[count];
                tile.score = this.getTileScore(shuffleTiles[count]);
                count++;
                return colTiles;
            })
        );
        let totalScore = this.state.totalScore - SUFFLE_PENALTY_POINT;
        this.setState({ initialTiles, totalScore });
    };

    submitTiles = (): boolean => {
        let initialTiles = [...this.state.initialTiles];
        let wordsFound = [...this.state.wordsFound];
        let totalScore = this.state.totalScore;
        let WordCount = this.state.WordCount;
        let words = this.getWord();
        let is_valid_word = dailyWordPuzzleDataAPI.isWordValidate(words);
        initialTiles.map((colTile: Array<WordSpiderTileType>) => {
            if (colTile.length > 0) {
                let tile: WordSpiderTileType = colTile[colTile.length - 1];
                let isTileSelected = this.state.selectedTiles.filter((item) => item === tile.cindex).length;
                if (tile && !!isTileSelected && is_valid_word === true) {
                    totalScore += tile.score;
                    colTile.pop();
                }
            }
            return initialTiles;
        });
        if (is_valid_word === false) {
            this.setState({ valid_word: false });
            this.setState({ selectedTiles: [...this.state.selectedTiles] });
            this.animationIsRunning = true;
            setTimeout(() => {
                this.setState({ selectedTiles: [] });
                this.setState({ valid_word: true });
                this.animationIsRunning = false;
            }, 1000);
        }

        if (is_valid_word === true) {
            WordCount = WordCount + 1;
            wordsFound.push(words);
            SndMngr.playSound(SoundType.USERWINGAME);
            this.setState({ valid_word: true });
            this.setState({ selectedTiles: [] });
        }
        if (this.state.selectedTiles.length === 0) {
            this.setState({ valid_word: true });
        }
        totalScore += this.getBonusValue();
        let tiles_left = 0;
        initialTiles.forEach((rack) => {
            tiles_left += rack.length;
        });
        let initialTilesWithValue = initialTiles.filter((e) => e.length);
        for (let i = 0; i < initialTilesWithValue.length; i++) {
            initialTilesWithValue[i].map((ele) => {
                return (ele.cindex = i);
            });
        }

        let wordsCanBeFormed: boolean = dailyWordPuzzleDataAPI.getWordsFormed(this.allEndTilesLetters()).length > 0;

        this.setState(
            {
                initialTiles: initialTilesWithValue,
                totalScore,
                WordCount,
                wordsFound,
                tiles_left: tiles_left,
                wordsCanBeFormed,
            },
            () => {
                if (this.state.tiles_left === 0 || this.state.tiles_left === 1 || wordsCanBeFormed === false) {
                    SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
                    lclStorage.resetItem(WORDSPIDER_INP_GAME_DATA).then(() => {
                        ReactGA.event({
                            category: "game_over",
                            action: "finished_game",
                            label: "wordspider",
                            value: 1,
                        });
                        this.setState({ isGameOver: true });
                        this.setWordSpiderGameOverDataToLclStg();
                    });
                } else {
                    this.setTempWordSpiderDataToLclStg();
                }
            }
        );
        setTimeout(() => {
            dailyWordPuzzleDataAPI.isAllGameOver().then((data) => {
                if (data) {
                    this.setState({ isAllGameOver: data });
                }
            });
        }, 100);
        return is_valid_word;
    };

    handleShow = () => {
        this.setState({ ShowHelp: true });
    };

    handleClose = () => this.setState({ ShowHelp: false });

    onStartGame = () => {
        this.setState({ isLoading: false });
        SndMngr.playSound(SoundType.GAMESTARTENDSOUND);
    };

    onHintClicked = () => {
        this.setState({ isLoading: true });
        SndMngr.playSound(SoundType.CLICKBTN);
    };

    wordCheck = (): boolean => {
        let words = this.getWord();
        let is_valid_word = dailyWordPuzzleDataAPI.isWordValidate(words);
        return is_valid_word;
    };

    listHover = (): Array<string> => {
        let allWords = this.state.wordsFound.map((item) => {
            return item;
        });
        return allWords;
    };

    allEndTilesLetters = (): Array<string> => {
        let EndTilesLetters: Array<string> = [];
        this.state.initialTiles.forEach((tileCol) => tileCol.slice(-1).forEach((tile) => EndTilesLetters.push(tile.letter)));
        return EndTilesLetters;
    };

    setTempWordSpiderDataToLclStg = () => {
        let temp_data: TempWordSpiderData = {
            initialTiles: this.state.initialTiles,
            WordCount: this.state.WordCount,
            totalScore: this.state.totalScore,
            wordsFound: this.state.wordsFound,
            isGameOver: this.state.isGameOver,
            totalWeight: this.state.totalWeight,
            tstmp: Math.floor(Date.now() / 1000),
        };
        lclStorage.setItem(WORDSPIDER_INP_GAME_DATA, JSON.stringify(temp_data));
    };

    setWordSpiderGameOverDataToLclStg = () => {
        let temp_data: TempWordSpiderData = {
            initialTiles: this.state.initialTiles,
            WordCount: this.state.WordCount,
            totalScore: this.state.totalScore,
            wordsFound: this.state.wordsFound,
            isGameOver: true,
            totalWeight: this.state.totalWeight,
            tstmp: Math.floor(Date.now() / 1000),
        };
        lclStorage.setItem(WORDSPIDER_FIN_GAME_DATA, JSON.stringify(temp_data));
    };

    shuffleColumnWordSpider = (array: Array<Array<WordSpiderTileType>>): Array<Array<WordSpiderTileType>> => {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        for (let i = 0; i < array.length; i++) {
            array[i].map((ele) => {
                return (ele.cindex = i);
            });
        }
        return array;
    };

    getShuffleIcon = (): JSX.Element => {
        return (
            <img
                src={ShuffleIcon}
                // data-tip={"Shuffle"}
                className={`w-12 rounded-[7px] cursor-pointer `}
                onClick={this.shuffleOnClick}
                alt="shuffle"
            />
        );
    };

    timerIDs: Array<NodeJS.Timeout | null> = [];


    shuffleOnClick = () => {
        if(!this.state.isGameOver)
        if (!this.animationIsRunning) {
            this.animationIsRunning = true;
            this.updateStoredTileContainerIDs()
            this.updateStoredTileContainerPositions();
            let temptiles: WordSpiderTileType[][] = JSON.parse(JSON.stringify(this.state.initialTiles));
            let selectedTiles: number[] = JSON.parse(JSON.stringify(this.state.selectedTiles));
            this.animations = this.animations.filter((_, index) => temptiles[index]?.length)
            temptiles = temptiles.filter(tilesCol=>tilesCol.length)
            for (let i = this.containerIDs.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                let temp = this.containerIDs[i];
                this.containerIDs[i] = this.containerIDs[j];
                this.containerIDs[j] = temp;

                let temp2 = temptiles[i];
                temptiles[i] = temptiles[j];
                temptiles[j] = temp2;
            }
            selectedTiles = selectedTiles.map(idx => this.containerIDs.indexOf(idx))
            
            //update temptiles cindex
            temptiles.forEach((tiles, clIndex) => tiles.forEach((tile) => tile.cindex = clIndex))
            
            let animresult: Promise<AnimationResult>[] = [];
            this.containerIDs.forEach((tcid, idx) => {
                let container: HTMLElement | null = document.getElementById("tc_" + tcid);
                if (container) {
                    let rect: DOMRect = container.getBoundingClientRect();
                    let style = window.getComputedStyle(container);
                    let matrix = new WebKitCSSMatrix(style.transform);
                    let mm = matrix.m41;
                    if (isNaN(mm)) {
                        mm = 0;
                    }
                    let qq = this.containerIDs.findIndex((item) => item === tcid);
                    if (qq !== -1) {
                        let x = this.containerPositions[qq] - rect.x + mm;
                        let transform = `translateX(${x}px)`;
                        let animationresult = this.animations[tcid].start({ transform });
                        animresult.push(animationresult);
                    }
                }
            });
            Promise.all(animresult).then((values) => {
                let transform = "translateX(0px)";
                this.setState({ initialTiles: temptiles, selectedTiles }, () => {
                    this.updateStoredTileContainerIDs();
                    this.animations.forEach((item) => {
                        item.set({ transform });
                    });
                });
                this.animationIsRunning = false;
            });
            SndMngr.playSound(SoundType.SHUFFLE);
        }
    };

    renderTextShowBox = () => {
        return this.state.isGameOver ? (
            <div className={`flex flex-col items-center justify-center`}>
                <p className="text-white text-xs sm:text-sm md:text-base">Blank</p>
                <div
                    className={`bg-warning-350  md:px-[6px] px-[1px] sm:p-1 sm:px-[1px] flex flex-row self-center p-1 md:p-2 mb-6 md:mb-12 sm:mb-10 rounded-md w-[170px] sm:w-[170px] md:w-[260px]`}
                >
                    <p className="flex justify-center font-roboto text-secondary-700 items-center bg-white tracking-wider w-40 h-6 sm:w-40  sm:text-sm md:w-60 md:h-12 sm:h-8 text-center text-xs font-bold  rounded sm-px-2 md:px-3 md:py-1 mx-1 md:text-lg">
                        {kCompleted}
                    </p>
                </div>
            </div>
        ) : this.state.selectedTiles.length === 0 || this.selectedWords().length === 0 ? (
            <div className={`flex flex-col items-center justify-center`}>
                <p className="text-white text-xs sm:text-sm md:text-base">Blank</p>
                <div
                    className={`bg-warning-350  md:px-1 px-0 sm:p-1 sm:px-0 flex flex-row self-center p-1 md:p-2 mb-6 md:mb-12 sm:mb-10 rounded-md w-[232px] sm:w-[248px] md:w-[300px]`}
                >
                    <p className="flex justify-center font-roboto text-[#4C4C4C] items-center bg-white w-60 h-6 sm:w-60  sm:text-sm md:w-80 md:h-12 sm:h-8 text-center text-xs font-bold  rounded sm-px-2 md:px-3 md:py-1 mx-1 md:text-lg">
                        {WrdMsg}
                    </p>
                </div>
            </div>
        ) : this.state.valid_word ? (
            <div className={`flex flex-col items-center justify-center `}>
                <p className="text-white text-xs sm:text-sm md:text-base">Blank</p>
                <div
                    className={` border-white border-2 md:border-0 sm:border-0 bg-warning-350 flex flex-row self-center p-1 md:p-2 sm:p-2 mb-5 md:mb-12 sm:mb-8 rounded-md`}
                >
                    {this.selectedWords().map((letter, index) => {
                        return (
                            <Fade key={index}>
                                <p className="flex justify-center font-roboto text-secondary-700 items-center bg-white w-6 h-6 sm:w-8 sm:h-8 sm:text-3xl md:w-12 md:h-12  text-center text-sm font-bold uppercase rounded md:px-3 md:py-1 mx-1 lg:text-4xl">
                                    {letter}
                                </p>
                            </Fade>
                        );
                    })}
                </div>
            </div>
        ) : (
            <div className={`flex flex-col items-center justify-center `}>
                <p className="text-warning-600 text-xs sm:text-sm md:text-base">Not a word!!</p>
                <div
                    className={`border-warning-600 border-[2px] sm:border-4 md:border-4 bg-warning-350 flex flex-row self-center p-1 md:p-1 mb-5 md:mb-12 sm:mb-8 rounded-md`}
                >
                    {this.selectedWords().map((letter, index) => {
                        SndMngr.playSound(SoundType.INVALIDWORD);
                        return (
                            <Fade key={index}>
                                <p className="flex justify-center font-roboto items-center text-secondary-700 bg-white w-6 h-6 sm:w-8 sm:h-8 sm:text-3xl md:w-12 md:h-12  text-center text-sm font-bold uppercase rounded md:px-3 md:py-1 mx-1 lg:text-4xl">
                                    {letter}
                                </p>
                            </Fade>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderWordCountAndSubmit = () => {
        return (
            <div className="flex justify-center">
                <div className="flex flex-row mt-2 md:w-80 sm:w-60 w-52">
                    <p className="font-normal text-[#6A6A6A md:text-[15px] sm:text-[14px] text-[13px] font-roboto mr-1 leading-4 sm:leading-5 md:leading-5 md:tracking-[-0.02em]">
                        {"Word Count: "}
                    </p>
                    <p data-tip={this.listHover()} className={`md:text-[15px] cursor-pointer sm:text-[14px] text-[13px] text-[#6A6A6A] font-roboto`}>
                        {this.state.WordCount}
                    </p>
                    <ReactTooltip
                        className={`text-black font-roboto font-light text-center max-w-[300px]`}
                        place={"top"}
                        multiline={true}
                        effect="solid"
                    />
                </div>
                <div className="mr-4">
                    {this.getShuffleIcon()}
                    {/* <ReactTooltip
                        className={`text-black font-roboto font-light text-center`}
                        place={"top"}
                        effect="solid"
                        afterShow={() =>
                            setTimeout(() => {
                                ReactTooltip.hide();
                            }, 1000)
                        }
                    /> */}
                </div>
                <div className="flex flex-row mr-2">
                    <button
                        className={`${styles.submitBtn} ${
                            this.state.selectedTiles.length === 0 || this.state.valid_word === false
                                ? "bg-secondary-200"
                                : "bg-success-350"
                        }`}
                        onClick={this.submitTiles}
                        disabled={this.state.selectedTiles.length === 0 || this.state.valid_word === false}
                    >
                        <p className={` ${this.state.selectedTiles.length === 0 || this.state.valid_word === false ? "text-[#EEEEEE]" :"text-white"} md:text-[16px] sm:text-[15px] text-[14px] font-bold leading-5 font-roboto tracking-[0.04em]`}>
                            Submit
                        </p>
                    </button>
                </div>
            </div>
        );
    };

    updateStoredTileContainerPositions = () => {
        let xarr: Array<number> = [];
        this.containerIDs.forEach((tcid, idx) => {
            let container: HTMLElement | null = document.getElementById("tc_" + tcid);
            if (container) {
                let rect: DOMRect = container.getBoundingClientRect();
                xarr.push(rect.x);
            }
        });
        this.containerPositions = xarr;
    };

    updateStoredTileContainerAnimations = (initialTiles: WordSpiderTileType[][]) => {
        let xarr: Array<Controller> = [];
        initialTiles.forEach((column, idx) => {
            xarr.push(new Controller({ transform: "translateX(0px)" }));
        });
        this.animations = xarr;
    };

    updateStoredTileContainerIDs = () => {
        let xarr: Array<number> = [];
        this.state.initialTiles.forEach((column, idx) => {
            let container: HTMLElement | null = document.getElementById("tc_" + idx);
            if (container && column.length) {
                xarr.push(idx);
            }
        });
        this.containerIDs = xarr;
    };

    renderAllTiles = () => {
        return (
            <div className="mt-4 sm:mt-8 md:12 ml-2 h-72">
                <div className="flex flex-row place-content-center items-start h-68">
                    {this.state.initialTiles.map((tileCol: Array<WordSpiderTileType>, cindex: number) => {
                        return (
                            <animated.div
                                id={"tc_" + cindex}
                                className="flex flex-col-reverse mr-2 md:mr-3sm:mr-3"
                                key={"letterArr-" + cindex}
                                style={this.animations[cindex]?.springs}
                            >
                                {tileCol.length > 0 ? (
                                    tileCol.map((tile: WordSpiderTileType, index: number) => {
                                        return (
                                            <Tile
                                                tileData={tile}
                                                isEndTile={index === tileCol.length - 1}
                                                selectedTiles={this.state.selectedTiles}
                                                onTileSelect={this.onTileSelect}
                                                key={"letterArr--" + index}
                                            />
                                        );
                                    })
                                ) : (
                                    <Tile default />
                                )}
                            </animated.div>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderToIndexOrFinalPage = () => {
        const { navigate } = this.props;
        return this.state.isAllGameOver && !dailyWordPuzzleDataAPI.hasVistedFinalPage
            ? navigate(pathFinalScore)
            : navigate(pathIndexOfGame);
    };

    renderAfterTutorial = () => {
        if (this.state.isLoading) {
            return <Tutorial startGameClicked={this.onStartGame} />;
        } else {
            return (
                <div className="ml-2 flex flex-col h-full ">
                    <div className="flex flex-col items-center h-full justify-center ">
                        <div className={`flex flex-col ml-28  justify-end py-2s mb-1 md:mb-4 md:mt-2`}>
                            <BlupIcon
                                style={`  h-10 w-10  text-black self-center cursor-pointer md:ml-72 sm:ml-60 ml-48`}
                                clicked={() => {
                                    SndMngr.playSound(SoundType.CLICKBTN);
                                    this.onHintClicked();
                                }}
                            />
                        </div>
                        {this.renderTextShowBox()}
                        {this.renderWordCountAndSubmit()}
                        {this.renderAllTiles()}
                        {this.state.isGameOver && this.state.showGameOverPopup && (
                            <GameOver
                                title={titleWordSpider}
                                gameScore={this.state.totalScore}
                                onClose={() => this.renderToIndexOrFinalPage()}
                                handleGameOverPopup={() => {
                                    this.setState({ showGameOverPopup: false });
                                }}
                            />
                        )}
                    </div>
                </div>
            );
        }
    };

    render = () => (
        <div className="h-full flex flex-col">
            <Header
                title={titleWordSpider}
                onMenuHelpClicked={() => {
                    this.handleShow();
                }}
            />
            {this.state.ShowHelp && <HelpDeskPopup closeHelp={this.handleClose} />}
            {this.renderAfterTutorial()}
        </div>
    );
}

const styles = {
    submitBtn: "p-2 px-4 md:px-6 sm:px-5 rounded-[7px]",
};

export default withRouter(WordSpider);
