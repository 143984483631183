import React from "react";
import Fade from "../Animation/Fade";

export const Modal: React.FC<{
    children?: React.ReactNode;
    closeModal?: () => void;
    style?: string;
    isMenuType?: boolean;
}> = (props) => {
    const contentStyle = props.isMenuType
        ? props.style
        : `fixed z-10 inset-0 overflow-y-auto`;
    const content2Style = `flex items-center sm:items-center justify-center min-h-full  ${
        props.isMenuType ? null : "sm:items-center p-4 text-center sm:p-0"
    }`;

    const closeModal = () => {
        if (props.closeModal) {
            props.closeModal();
        }
    };
    return (
        <Fade>
            <div onClick={() => closeModal()} className="relative z-10">
                <div
                    onClick={() => closeModal()}
                    className="fixed inset-0 bg-secondary-100 bg-opacity-60 transition-opacity blur"
                ></div>
                <div className={contentStyle}>
                    <div className={content2Style}>{props.children}</div>
                </div>
            </div>
        </Fade>
    );
};
