import { ReactNode } from "react";
import { ConvertStyle } from "../../Utils/ConvertStyle";

export const SuccessButton = (props: {
    clicked: () => void;
    title: string;
    icon?: ReactNode;
    style?: string;
}) => {
    return (
        <button
            onClick={props.clicked}
            className={ConvertStyle([style.success, props.style ?? ""])}
        >
            {props.title}
            {props.icon}
        </button>
    );
};

const style = {
    success:
        "flex self-center place-content-center py-2 px-6 gap-1 bg-success-300 hover:bg-success-400 text-xl text-white font-roboto  rounded-[8px] font-medium ",
};
