import localForage from "localforage";
import { titleIntro } from "../Commons/constant";
class LocalStorage {
    constructor() {
        this.onAppInit();
    }
    onAppInit() {
        localForage.config({
            driver: localForage.LOCALSTORAGE,
            name: titleIntro,
        });
    }
    async getItem(key: string) {
        try {
            const word: string | null = await localForage.getItem(key);
            return word;
        } catch (err) {
            throw err;
        }
    }
    async setItem(key: string, value: string) {
        try {
            await localForage.setItem(key, value);
            return 200;
        } catch (err) {
            throw err;
        }
    }

    async resetItem(key: string) {
        try {
            await localForage.removeItem(key);
            return 200;
        } catch (err) {
            throw err;
        }
    }
    async getAllIDs() {
        try {
            const keys = await localForage.keys();
            return keys;
        } catch (err) {
            throw err;
        }
    }

    async getTotalNumberOfIDs() {
        try {
            const numberOfKeys = await localForage.length();
            return numberOfKeys;
        } catch (err) {
            throw err;
        }
    }

    async removeAllKey() {
        try {
            await localForage.clear();
            return 200;
        } catch (err) {
            throw err;
        }
    }
}
const lclStorage = new LocalStorage();
export default lclStorage;
