import React, { useState } from "react";
import { Modal } from "../../../Component/Modal/Modal";
import ChevronLeftIcon from "../../../Assets/svg/ChevronLeftIcon.svg";
import ChevronRightIcon from "../../../Assets/svg/ChevronRightIcon.svg";
import CrossIcon from "../../../Assets/svg/CrossIcon.svg";
import WordSpiderFirst from "../../../Assets/svg/WordSpiderFirstTut.svg";
import WordSpiderSec from "../../../Assets/svg/WordSpiderSecTut.svg";
import WordSpiderThird from "../../../Assets/svg/WordSpiderThirdTut.svg";
import WordSpiderFourth from "../../../Assets/svg/WordSpiderFourthTut.svg";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import { titleWordSpider, wrdSpiderTutorial1, wrdSpiderTutorial2, wrdSpiderTutorial3, wrdSpiderTutorial4 } from "../Utils/Constants";

export const Tutorial = (props: { startGameClicked: () => void }) => {
    const [displayPage, setDisplayPage] = useState<number>(1);

    const clickableButtonWithImage = (
        svgFile: string,
        clickHandler: () => void
    ): JSX.Element => {
        return (
            <img
                src={svgFile}
                className={style.icon}
                onClick={clickHandler}
                alt="..."
            />
        );
    };

    const getLeftIcon = (page: number): JSX.Element | null => {
        switch (page) {
            case 1:
                return <div className="w-9 h-9"></div>;
            default:
                return clickableButtonWithImage(ChevronLeftIcon, () => {
                    if (displayPage > 1) {
                        SndMngr.playSound(SoundType.RECALL);
                        setDisplayPage(displayPage - 1);
                    }
                });
        }
    };

    const getRightIcon = (page: number): JSX.Element | null => {
        switch (page) {
            case 1:
            case 2:
                return clickableButtonWithImage(ChevronRightIcon, () => {
                    SndMngr.playSound(SoundType.RECALL);
                    setDisplayPage(displayPage + 1);
                });
            case 3:
                return clickableButtonWithImage(ChevronRightIcon, () => {
                    SndMngr.playSound(SoundType.RECALL);
                    setDisplayPage(displayPage + 1);
                });
            case 4:
                return clickableButtonWithImage(CrossIcon, () => {
                    SndMngr.playSound(SoundType.RECALL);
                    props.startGameClicked();
                });
            default:
                return null;
        }
    };

    const getTitle = (): JSX.Element => (
        <p className={style.title}>{titleWordSpider}</p>
    );

    const getDescriptionText = (page: number): string => {
        switch (page) {
            case 1:
                return wrdSpiderTutorial1;
            case 2:
                return wrdSpiderTutorial2;
            case 3:
                return wrdSpiderTutorial4;
            case 4:
                return wrdSpiderTutorial3;    
            default:
                return "";
        }
    };

    const getImageDescription = (page: number) => {
        switch (page) {
            case 1:
                return { imgsrc: WordSpiderFirst, style: `w-[200px] h-[250px]` };
            case 2:
                return { imgsrc: WordSpiderSec, style: `w-[200px] h-[250px]` };
            case 3:
                return { imgsrc: WordSpiderFourth, style: `w-[100px] h-[100px] mt-12` };
            case 4:
                return { imgsrc: WordSpiderThird, style: `w-[150px] h-[150px] mt-4` };
            default:
                return {};
        }
    };

    const renderTutorialDescription = (
        displayPage: number
    ): JSX.Element | null => {
        return (   
            <p className={style.text + `py-6 px-4`}>
                {getDescriptionText(displayPage)}
            </p>
        );
    };

    const renderTutorialImage = (displayPage: number): JSX.Element | null => {
        let description = getImageDescription(displayPage);
        return (
            <img
                src={description.imgsrc}
                className={style.Svg + description.style}
                alt=""
            />
        );
    };

    return (
        <Modal>
            <div className={style.mainContainer}>
                <div className={`w-[280px] flex flex-row pt-8 justify-between`}>
                    {getLeftIcon(displayPage)}
                    {getTitle()}
                    {getRightIcon(displayPage)}
                </div>
                {renderTutorialDescription(displayPage)}
                {renderTutorialImage(displayPage)}
            </div>
        </Modal>
    );
};

const style = {
    mainContainer:
        "w-[350px] h-[500px] flex flex-col  rounded-3xl items-center bg-secondary-150",
    title: "font-medium text-3xl font-secular",
    text: "font-roboto text-[18px] font-normal ",
    icon: "w-9 h-9 stroke-secondary-600 cursor-pointer ",
    Svg: "flex self-center place-content-center ",
};
