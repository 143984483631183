import {
    WORDCHAIN_COMPLETED_DATA,
    WORDHUNT_COMPLETED_DATA,
    Emoji,
    titleWordChain,
    ASSEMBLER_FIN_GAME_DATA,
    titleWordHunt,
    titleAssembler,
} from "../../Commons/constant";
import { WORDSPIDER_FIN_GAME_DATA, titleWordSpider } from "../../Pages/WordSpider/Utils/Constants";
import { AssemblerFinGameType, BoardType, WordChainGameCmpltdType, WordCombinationStoreType, WordHuntGameCmpltdType } from "../../Commons/types";
import copy from "copy-to-clipboard";
import lclStorage from "../../Utils/LocalStorage";
import { dailyWordPuzzleDataAPI } from "../../Pages/Introduction/api/DailyWordPuzzleDataAPI";
import { WORDKNIT_FIN_GAME_DATA, titleWordKnit } from "../../Pages/WordKnit/Utils/constant";
import { GameOverWordKnitComplDataType } from "../../Pages/WordKnit/Utils/type";
import { COLOR_CODE } from "../../Pages/WordHunt/LetterInSquares/LetterInSquares";
import { TempWordSpiderData } from "../../Pages/WordSpider/Utils/Types";
import { titleWordAlike, WORDALIKE_FIN_GAME_DATA } from "../../Pages/WordAlike/Utils/constants";
import { WordAlikeFinGameType } from "../../Pages/WordAlike/Utils/type";
import { titleWordSearch, WORDSEARCH_INP_GAME_DATA } from "../../Pages/WordSearch/Constants";
import { RJWordSearchGameData, RJWordSearchInpGameType } from "../../Pages/WordSearch/Types";

declare const window: Window &
    typeof globalThis & {
        _daily_puzzle_game_configuration_: any;
    };

let ShareLink = window._daily_puzzle_game_configuration_.shareurl;

// const getTimeInSec = (
//     issuedTime: TimerPropType,
//     compltedTime: TimerPropType
// ) => {
//     return (
//         issuedTime.hr * 3600 +
//         issuedTime.min * 60 +
//         issuedTime.sec -
//         (compltedTime.hr * 3600 + compltedTime.min * 60 + compltedTime.sec)
//     );
// };

export const getWordChainResult = async (isShareText: boolean) => {
    let gdata = await lclStorage.getItem(WORDCHAIN_COMPLETED_DATA);
    if (gdata !== null && gdata !== undefined) {
        let gameData = JSON.parse(gdata);
        let wordChainData = await dailyWordPuzzleDataAPI.wordChainData();
        let total_valid_words = 0;
        if (wordChainData && wordChainData.wordlens) {
            total_valid_words = Object.values(wordChainData.wordlens).reduce((b, a) => Number(b) + Number(a), 0);
        }
        const word_commbination_data: WordCombinationStoreType[] = gameData.data.word_store;
        let wordWithPriority: Array<string> = [];
        word_commbination_data.forEach((word_state) => word_state.words.forEach((word) => wordWithPriority.push(word)));
        wordWithPriority = wordWithPriority.filter((i) => i !== null && i !== undefined && i.trim());

        let emojiDes = `${titleWordChain}\n\nScore🎰 : ${gameData.data.score}\nAttempts🎯 : ${wordWithPriority.length} out of ${total_valid_words} \n\n`;
        let scorePerct = (wordWithPriority.length / total_valid_words) * 100;
        if (scorePerct < 50) {
            emojiDes = emojiDes + "Better luck next time 💫";
        } else {
            emojiDes = emojiDes + "Great Job 👍";
        }
        if (isShareText) {
            copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
        } else {
            return `You found ${wordWithPriority.length} out of ${total_valid_words} possible words and scored ${gameData.data.score} points.`;
        }
    }
};

export const getWordHuntResult = async (isShareText: boolean) => {
    let gdata = await lclStorage.getItem(WORDHUNT_COMPLETED_DATA);
    if (gdata !== null && gdata !== undefined) {
        let gameData = JSON.parse(gdata);
        if (gameData.isGameOver) {
            let gOverBoardData: BoardType = gameData.data.boardData;
            let emojiDes = `${titleWordHunt}\n\nScore🎰 : ${gameData.data.score}\nAttempts🎯 : ${gOverBoardData.length} out of 6 \n\n`;

            let hasWordFound = () => {
                let last_row = gOverBoardData[gOverBoardData.length - 1];
                let result: boolean = last_row.every(({ color }) => color === COLOR_CODE.BG_GREEN);
                return result;
            };

            gOverBoardData.forEach((row) => {
                row.forEach(({ color }) => {
                    if (color === `PR_NPL`) {
                        emojiDes = emojiDes + Emoji.PR_NPL;
                    }
                    if (color === `NPR_NPL`) {
                        emojiDes = emojiDes + Emoji.NPR_NPL;
                    }
                    if (color === `PR_PL`) {
                        emojiDes = emojiDes + Emoji.PR_PL;
                    }
                    emojiDes = emojiDes + " ";
                });
                emojiDes = emojiDes + "\n";
            });

            if (isShareText) {
                copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
            } else {
                let gameOverMgs = "Yet to HUNT the right word";
                if (hasWordFound()) {
                    let WordHuntPzzlData = await dailyWordPuzzleDataAPI.wordHuntData();
                    gameOverMgs = `You found ${WordHuntPzzlData?.finword} in ${gOverBoardData.length} attempts!`;
                }
                return gameOverMgs;
            }
        }
    }
};

export const getAssemblerResult = async (isShareText: boolean) => {
    let gdata = await lclStorage.getItem(ASSEMBLER_FIN_GAME_DATA);
    if (gdata !== null && gdata !== undefined) {
        let gameData = JSON.parse(gdata);
        let assemblerData = await dailyWordPuzzleDataAPI.assemblerData();
        if (assemblerData) {
            let wordCompleted = gameData.data.wordsState;
            let totalTiles = 0;
            let coloredTiles = 0;

            wordCompleted.forEach((item: any) => {
                item.forEach((ele: any) => {
                    if (ele.colorCode === "FD") {
                        coloredTiles = coloredTiles + 1;
                    }
                    totalTiles = totalTiles + 1;
                });
            });

            let totalWordsFound = assemblerData.wordcolumns.length;
            if (totalTiles === coloredTiles) {
                totalWordsFound = gameData.data.wordsFound.length;
            }

            let emojiDes = `${titleAssembler}\n\nScore🎰 : ${gameData.data.score}\n\nWords Found🔎 : ${gameData.data.wordsFound.length} out of ${totalWordsFound} \nColor of ${coloredTiles} out of ${totalTiles} tiles changed\n\n`;

            let scorePerct = (wordCompleted.length / assemblerData.wordcolumns.length) * 100;
            if (scorePerct < 50) {
                emojiDes = emojiDes + "Better luck next time 💫";
            } else if (scorePerct > 50 && scorePerct < 80) {
                emojiDes = emojiDes + "Good 🙂";
            } else {
                emojiDes = emojiDes + "Great Job 👍";
            }
            if (isShareText) {
                copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
            } else {
                return `You found ${gameData.data.wordsFound.length} words\nand\nchanged colours of ${coloredTiles}/${totalTiles} tiles`;
            }
        }
    }
};

let GetStarEmj = (totalPer: number) => {
    let perctg = Number((totalPer / 20).toFixed());
    let emojiText = "";
    for (let i = 0; i < 5; i++) {
        if (i < perctg) emojiText = emojiText + "★";
        else emojiText = emojiText + "✩";
    }
    return emojiText;
};

export const getWordSpiderResult = async (isShareText: boolean) => {
    let gdata = await lclStorage.getItem(WORDSPIDER_FIN_GAME_DATA);
    if (gdata !== null && gdata !== undefined) {
        let gameData:TempWordSpiderData = JSON.parse(gdata);
        let emojiDes = `${titleWordSpider}\n\nScore🎰 : ${gameData.totalScore}\nWords made🧪 : ${gameData.wordsFound.length} \n\n`;
        let scorePerct = (gameData.totalScore / gameData.totalWeight) * 100;
        if (scorePerct <= 100 && scorePerct >= 90) {
            emojiDes = emojiDes + `${GetStarEmj(scorePerct)}\nCongrats!! You got this!! 👍`;
        } else if (scorePerct <= 90 && scorePerct >= 70) {
            emojiDes = emojiDes + `${GetStarEmj(scorePerct)}\nGreat Job 👍 `;
        } else if (scorePerct <= 70 && scorePerct >= 50) {
            emojiDes = emojiDes + `${GetStarEmj(scorePerct)}\nKeep Progressing 😊 `;
        } else {
            emojiDes = emojiDes + `${GetStarEmj(scorePerct)}\nMake better choice, get more score.`;
        }
        if (isShareText) {
            copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
        } else {
            return `You found ${gameData.wordsFound.length} words \n and \nscored ${gameData.totalScore} points.`;
        }
    }
};

export const getWordKnitResult = async (isShareText: boolean): Promise<string | undefined> => {
    let completed_data: string | null = await lclStorage.getItem(WORDKNIT_FIN_GAME_DATA);
    if (completed_data) {
        let cmp_parse_data: GameOverWordKnitComplDataType = JSON.parse(completed_data);

        //found tiles calculation
        let found_tiles = 0;
        cmp_parse_data.blockInfo.map(({ merged }) =>
            merged?.forEach((tile_ids) => (found_tiles = found_tiles + tile_ids.filter((id) => id).length))
        );

        let final_quotes: string = "";
        if (cmp_parse_data.blockInfo.length === 1 && cmp_parse_data.wordsFound) {
            final_quotes = `You found ${cmp_parse_data.wordsFound.length} words and changed the color of ${found_tiles} tiles`;
        }

        let emojiDes = `${titleWordKnit}\n\nScore🎰 : ${cmp_parse_data.score}\n\n${final_quotes}\n`;

        if (isShareText) {
            copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
        } else {
            return final_quotes;
        }
    }
};

export const getWordAlikeResult = async (isShareText: boolean): Promise<string | undefined> => {
    let completed_data: string | null = await lclStorage.getItem(WORDALIKE_FIN_GAME_DATA);
    if (completed_data) {
        let cmp_parse_data: WordAlikeFinGameType = JSON.parse(completed_data);

        let final_quotes: string = "";
        if (cmp_parse_data.wordsFound.length) {
            final_quotes = `You found ${cmp_parse_data.wordsFound.length} synonyms of word "${cmp_parse_data.clueWord.toUpperCase()}" `;
        }

        let emojiDes = `${titleWordAlike}\n\nScore🎰 : ${cmp_parse_data.score}\n\n${final_quotes}\n`;

        if (isShareText) {
            copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
        } else {
            return final_quotes;
        }
    }
};

export const getWordSearchResult = async (isShareText: boolean): Promise<string | undefined> => {
    let completed_data: string | null = await lclStorage.getItem(WORDSEARCH_INP_GAME_DATA);
    if (completed_data) {
        let total_valid_words: string | undefined = dailyWordPuzzleDataAPI.getWordSearchData()?.valword;
        let cmp_parse_data: RJWordSearchInpGameType = JSON.parse(completed_data);

        let final_quotes: string = "";
        final_quotes = `You found ${cmp_parse_data.wordsFound.length} out of ${total_valid_words?.split(",").length} words`;

        let emojiDes = `${titleWordSearch}\n\nScore🎰 : ${cmp_parse_data.score}\n\n${final_quotes}\n`;

        if (isShareText) {
            copy(emojiDes + `\n\n${ShareLink}`, { format: "text/plain" });
        } else {
            return final_quotes;
        }
    }
};

export const getFinalResult = async () => {
    const game_order: { gameTitle: string; lclStg: string }[] = [
        { gameTitle: titleAssembler, lclStg: ASSEMBLER_FIN_GAME_DATA },
        { gameTitle: titleWordHunt, lclStg: WORDHUNT_COMPLETED_DATA },
        { gameTitle: titleWordSpider, lclStg: WORDSPIDER_FIN_GAME_DATA },
        { gameTitle: titleWordKnit, lclStg: WORDKNIT_FIN_GAME_DATA },
        { gameTitle: titleWordAlike, lclStg: WORDALIKE_FIN_GAME_DATA },
        { gameTitle: titleWordSearch, lclStg: WORDSEARCH_INP_GAME_DATA },
        { gameTitle: titleWordChain, lclStg: WORDCHAIN_COMPLETED_DATA },
    ];

    let TotalScore = 0;
    let totalScorePertage = 0;
    let gameTitle: string[] = game_order.map(({ gameTitle }) => `${gameTitle}`);
    let scoreInTextFormat: string[] = [];

    const arry_of_promise = game_order.map(async ({ lclStg }) => {
        let lcl_data: string | null = await lclStorage.getItem(lclStg);
        if (lcl_data) {
            let parse_lcl_data:
                | WordChainGameCmpltdType
                | WordHuntGameCmpltdType
                | AssemblerFinGameType
                | TempWordSpiderData
                | GameOverWordKnitComplDataType
                | WordAlikeFinGameType
                | RJWordSearchGameData = JSON.parse(lcl_data);
            return parse_lcl_data;
        } else {
            return null;
        }
    });

    let arr_of_cmp_data = await Promise.all(arry_of_promise);
    arr_of_cmp_data.forEach((cmp_data) => {
        let score: number = 0;
        if (cmp_data) {
            if ("totalScore" in cmp_data) {
                score = cmp_data.totalScore;
            } else if ("score" in cmp_data) {
                score = cmp_data.score;
            } else {
                score = cmp_data.data.score;
            }
            TotalScore = TotalScore + score;
            scoreInTextFormat.push(`Score : ${score.toString()}`);
        }
    });

    // total percentage Calculation
    let WordchainPzzleData = await dailyWordPuzzleDataAPI.wordChainData();
    let assemblerPzzlData = await dailyWordPuzzleDataAPI.assemblerData();
    let total_valid_words = 0;
    if (WordchainPzzleData && assemblerPzzlData) {
        total_valid_words = Object.values(WordchainPzzleData.wordlens).reduce((b, a) => Number(b) + Number(a), 0);

        const word_commbination_data: WordCombinationStoreType[] = (
            arr_of_cmp_data[game_order.findIndex(({ gameTitle }) => gameTitle === titleWordChain)] as WordChainGameCmpltdType
        ).data.word_store;
        let wordWithPriority: Array<string> = [];
        word_commbination_data.forEach((word_state) => word_state.words.forEach((word) => wordWithPriority.push(word)));
        wordWithPriority = wordWithPriority.filter((i) => i && i.trim());

        let assemblerWordCompleted = (
            arr_of_cmp_data[game_order.findIndex(({ gameTitle }) => gameTitle === titleAssembler)] as AssemblerFinGameType
        ).data.wordsState[0]
            .map((item) => {
                let required_item:
                    | {
                          letter: string;
                          colorCode: string;
                      }
                    | undefined;
                if (item.colorCode === "FD") {
                    required_item = item;
                }
                return required_item;
            })
            .filter((i) => i);
        let wordHuntCmpData = arr_of_cmp_data[
            game_order.findIndex(({ gameTitle }) => gameTitle === titleWordHunt)
        ] as WordHuntGameCmpltdType;

        let worrdChainTotalScorePrctg = (wordWithPriority.length / total_valid_words) * 100;
        let wordHuntTotalScorePrctg = (wordHuntCmpData.data.boardData.length / 5 / 6) * 100;
        let assemblerTotalScorePrctg = (assemblerWordCompleted.length / assemblerPzzlData.wordcolumns.length) * 100;
        totalScorePertage = ((worrdChainTotalScorePrctg + wordHuntTotalScorePrctg + assemblerTotalScorePrctg) / 300) * 100;
    }

    gameTitle.forEach((title, index) => {
        let titleLen: number = title.length;
        let scoreTextLen: number = scoreInTextFormat[index].length;
        if (titleLen > scoreTextLen) {
            scoreInTextFormat[index] = scoreInTextFormat[index].padEnd(titleLen);
        } else if (titleLen < scoreTextLen) {
            gameTitle[index] = title.padEnd(scoreTextLen);
        }
    });

    let textRslt =
        gameTitle.join(' | ') + "\n" + scoreInTextFormat.join(' | ') + `\n\nTotal Score : ${TotalScore}\n${GetStarEmj(totalScorePertage)}\n\n`;

    copy(textRslt + `${ShareLink}`, { format: "text/plain" });
};
