import { Modal } from "../Modal/Modal";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import { PrimaryButton } from "../Buttons/PrimaryButton";

export const PopupUp = (props: {
    msg: string;
    btnText: string;
    setShowMenu: () => void;
}) => {
    const closeModal = () => {
        props.setShowMenu();
    };

    return (
        <Modal closeModal={closeModal}>
            <div className={style.mainContainer}>
                <div className="px-12 pt-8 pb-6">
                    {props.msg.split("\n").map((item, idx) => {
                        return (
                            <p className={style.text} key={idx}>
                                {item}
                            </p>
                        );
                    })}
                </div>
                {/* <p className={style.text}>{props.msg}</p> */}
                <PrimaryButton
                    clicked={() => {
                        SndMngr.playSound(SoundType.MESSAGE);
                        closeModal();
                    }}
                    style={style.buttonStyle}
                    title={props.btnText}
                />
            </div>
        </Modal>
    );
};

const style = {
    mainContainer:
        "flex flex-col place-items-center  bg-[#FCFAF3] rounded-[9px] shadow-[4px_6px_4px_rgba(0,0,0,0.25)]",
    text: "font-montserrat text-center text-[16px] font-[600] leading-7 text-[#363636] ",
    buttonStyle:
        "flex self-center place-content-center font-lato tracking-[.1em] text-[19px] mb-8 leading-[23px] rounded-[7px] bg-primary-400 text-white font-extrabold",
};
