import {
    RootState,
    HintType,
    // TimerPropType,
    WordChainGameCmpltdType,
    // ScoreType,
    WordCombinationStoreType,
    TempWordChainData,
} from "../../../Commons/types";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
    resetSelectedWord,
    decreaseWordLeft,
    // insertSelectedLetter,
    // updateSelection,
    setInitialWordLeft,
    setAlreadySelected,
} from "../SelectedWord/selectedWord-slice";
import produce from "immer";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import lclStorage from "../../../Utils/LocalStorage";
import {
    TEMP_WORDCHAIN_DATA,
    WORDCHAIN_COMPLETED_DATA,
} from "../../../Commons/constant";
import { dailyWordPuzzleDataAPI } from "../../Introduction/api/DailyWordPuzzleDataAPI";
import ReactTooltip from "react-tooltip";

const FoundWordContainer = (props: {
    showHint: HintType;
    // isTimeEnd: boolean;
    // initialTime: TimerPropType;
    // remainingTime: TimerPropType;
    onHintClicked: () => void;
    updateScore: (scr: number) => void;
    doGameOver: (isGameOver:boolean)=>void;
    currentScore: number;
}) => {
    const dispatch = useDispatch();

    const [wordStore, setWordStore] = useState<WordCombinationStoreType[]>([]);
    useEffect(() => {
        const onWordCombinationInit = async () => {
            const completed_data = await lclStorage.getItem(
                WORDCHAIN_COMPLETED_DATA
            );
            if (completed_data) {
                if (completed_data) {
                    let gameCmpltdData: WordChainGameCmpltdType =
                        JSON.parse(completed_data);
                    if (gameCmpltdData.isGameOver) {
                        if (gameCmpltdData.isGameOver) {
                            setWordStore(gameCmpltdData.data.word_store);
                        }
                    }
                }
            } else {
                const temp_wordchain_data = await lclStorage.getItem(
                    TEMP_WORDCHAIN_DATA
                );
                if (temp_wordchain_data) {
                    const word_store: TempWordChainData =
                        JSON.parse(temp_wordchain_data);
                    setWordStore(word_store.word_comb);
                    let total_valid_words = word_store.word_comb.reduce(
                        (total, { maxPossibilities }) =>
                            total + maxPossibilities,
                        0
                    );
                    let num_words_played: number = word_store.word_comb.reduce(
                        (total, { words }) => total + words.length,
                        0
                    );
                    dispatch(
                        setInitialWordLeft(total_valid_words - num_words_played)
                    );
                } else {
                    dailyWordPuzzleDataAPI
                        .wordChainData()
                        .then((word_chain_data) => {
                            let word_store: WordCombinationStoreType[] = [];
                            if (word_chain_data?.wordlens) {
                                for (let key in word_chain_data.wordlens) {
                                    const max_possibilities: number =
                                        word_chain_data.wordlens[key];

                                    word_store.push({
                                        words: [],
                                        maxPossibilities: max_possibilities,
                                        eachWordLength: parseInt(key),
                                    });
                                }
                                setWordStore(word_store);

                                let total_valid_words = word_store.reduce(
                                    (total, { maxPossibilities }) =>
                                        total + maxPossibilities,
                                    0
                                );
                                dispatch(setInitialWordLeft(total_valid_words));
                            }
                        });
                }
            }
        };
        onWordCombinationInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let selected_word = useSelector((state: RootState) => state.selectedWord);

    const setTempWordChainDataToLclStg = () => {
        // let remaining_time =
        //     props.remainingTime.hr * 3600 +
        //     props.remainingTime.min * 60 +
        //     props.remainingTime.sec;
        // if (remaining_time) {
        let temp_data: TempWordChainData = {
            // timer: remaining_time,
            word_comb: wordStore,
            tstmp: Math.floor(Date.now() / 1000)
        };
        lclStorage.setItem(TEMP_WORDCHAIN_DATA, JSON.stringify(temp_data));
        // }
    };

    const savedGameOverData = (word_store: WordCombinationStoreType[]) => {
        let data: WordChainGameCmpltdType = {
            isGameOver: true,
            data: {
                word_store: word_store,
                hintData: props.showHint,
                // initialTime: props.initialTime,
                // cmpltdTime: props.remainingTime,
                score: props.currentScore,
                tstmp: Math.floor(Date.now() / 1000)
            },
        };
        lclStorage.getItem(WORDCHAIN_COMPLETED_DATA).then((item) => {
            if (item === null || item === undefined) {
                lclStorage.setItem(
                    WORDCHAIN_COMPLETED_DATA,
                    JSON.stringify(data)
                );
            }
        });
    };

    // useEffect(() => {
    //     if (wordStore.length ) {
    //         savedGameOverData(wordStore);
    //         lclStorage.resetItem(TEMP_WORDCHAIN_DATA);
    //         setTempWordChainDataToLclStg();
    //     }
    // }, [wordStore]);

    useEffect(() => {
        if (selected_word.is_selected) {
            const select_word_length: number = selected_word.letters.length;
            const select_word: string = selected_word.letters
                .join("")
                .toUpperCase();
            let word_store: WordCombinationStoreType | undefined =
                wordStore.find(
                    ({ eachWordLength }) =>
                        select_word_length === eachWordLength
                );
            if (select_word_length) {
                if (word_store?.words) {
                    const already_word_found = word_store.words.findIndex(
                        (word) => word === select_word
                    );
                    if (already_word_found !== -1) {
                        dispatch(setAlreadySelected(true));
                    } else {
                        dispatch(setAlreadySelected(false));
                    }
                }
            }
            if (selected_word.is_selected) {
                if (word_store?.words) {
                    const is_word_already_present: number =
                        word_store.words.findIndex(
                            (word) => word === select_word
                        );

                    if (is_word_already_present === -1) {
                        //check word is validate or not

                        if (
                            dailyWordPuzzleDataAPI.isWordValidate(select_word)
                        ) {
                            let word_state_index: number = wordStore.findIndex(
                                ({ eachWordLength }) =>
                                    eachWordLength === select_word_length
                            );
                            if (
                                word_state_index !== -1
                                // &&
                                // wordStore[word_state_index].occurance <
                                //     wordStore[word_state_index]
                                //         .maxPossibilities
                            ) {
                                setWordStore(
                                    produce((word_store) => {
                                        word_store[word_state_index].words.push(
                                            select_word
                                        );
                                    })
                                );
                                //update score
                                SndMngr.playSound(SoundType.USERWINGAME);
                                props.updateScore(select_word_length);
                                dispatch(decreaseWordLeft());
                            }
                        }
                    }
                }
                dispatch(resetSelectedWord());
                dispatch(setAlreadySelected(false));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected_word]);

    useEffect(() => {
        if (wordStore.length) {
            if (!selected_word.word_left) {
                savedGameOverData(wordStore);
                lclStorage.resetItem(TEMP_WORDCHAIN_DATA);
                props.doGameOver(true);
            }
            setTempWordChainDataToLclStg();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordStore]);

    const getCompletedWords = (index: number) => {
        if (wordStore.length && wordStore[index]) {
            let cmp_words_arr = wordStore[index].words.filter(
                (i) => i !== null && i !== undefined && i.trim()
            );
            return cmp_words_arr.join(", ");
        }
    };

    const combineLettersInBox = (
        word: string | undefined,
        index: number
    ): JSX.Element[] => {
        let letters: string[] = [];

        if (word) {
            letters = word.split("").map((letter) => letter);
        } else {
            letters = Array(wordStore[index].eachWordLength).fill(" ");
        }

        return letters.map((letter, i) => (
            <div
                className={`border-4 border-warning-350 flex rounded w-8 h-8 sm:w-12 sm:h-12 lg:w-14 lg:h-14`}
                key={i}
            >
                <p
                    className={`flex-1 text-[20px] leading-9 font-roboto font-light text-black text-center self-center  uppercase`}
                >
                    {letter}
                </p>
            </div>
        ));
    };

    return (
        <div className="flex flex-col gap-1 sm:gap-3 self-center">
            {wordStore.map(({ words, maxPossibilities }, index) => (
                <div className="flex flex-row gap-1 " key={index}>
                    {combineLettersInBox(words[words.length - 1], index)}
                    <p
                        data-tip={getCompletedWords(index)}
                        className="text-black font-roboto font-light tracking-[0.015em] leading-3 self-center m-2 text-[13px] lg:text-[16px]"
                    >
                        [{words.length}/{maxPossibilities}]
                    </p>
                    <ReactTooltip
                        className={`text-black font-roboto font-light px-2 text-center max-w-[300px]`}
                        place={"top"}
                        multiline={true}
                        effect="solid"
                    />
                </div>
            ))}
        </div>
    );
};

export default FoundWordContainer;
