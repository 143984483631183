import { useEffect } from "react";
import ShareIcon from "../../Assets/svg/ShareIcon.svg";
import {
    kGameOver,
    kNextGame,
    kShare,
    kScore,
    titleWordHunt,
    titleWordChain,
    titleAssembler,
    kContinue,
} from "../../Commons/constant";

import { Modal } from "../Modal/Modal";
import { useState } from "react";
import { SuccessButton } from "../Buttons/SuccessButton";
import { WarningButton } from "../Buttons/WarningButton";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import { Toast, TOASTVARIANT } from "../../Component/Toast/Toast";
import { useToast } from "../../Hooks/useToast";
import {
    getWordChainResult,
    getWordHuntResult,
    getWordKnitResult,
    getAssemblerResult,
    getWordSpiderResult,
    getWordAlikeResult,
    getWordSearchResult,
} from "./ShareTexts";
import { titleWordKnit } from "../../Pages/WordKnit/Utils/constant";
import { titleWordSpider } from "../../Pages/WordSpider/Utils/Constants";
import CrossIcon from "../../Assets/svg/CrossIcon.svg";
import { titleWordAlike } from "../../Pages/WordAlike/Utils/constants";
import { titleWordSearch } from "../../Pages/WordSearch/Constants";
import { dailyWordPuzzleDataAPI } from "../../Pages/Introduction/api/DailyWordPuzzleDataAPI";
import ReactGA from "react-ga4";

const GameOver = (props: { title: string; gameScore: number; onClose?: () => void; handleGameOverPopup: () => void }) => {
    let [isGameOver, setIsGameOver] = useState(true);
    let { addToast, toast } = useToast();
    const [gameOverText, setGameOverText] = useState("");
    const [isAllGameOver,setIsAllGameOver] = useState<boolean>(false)
    useEffect(() => {
        if (props.title === titleWordChain) {
            setTimeout(() => {
                getWordChainResult(false).then((text) => {
                    if (text !== null && text !== undefined) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }

        if (props.title === titleWordHunt) {
            setTimeout(() => {
                getWordHuntResult(false).then((text) => {
                    if (text !== null && text !== undefined) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }

        if (props.title === titleAssembler) {
            setTimeout(() => {
                getAssemblerResult(false).then((text) => {
                    if (text !== null && text !== undefined) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }

        if (props.title === titleWordSpider) {
            setTimeout(() => {
                getWordSpiderResult(false).then((text) => {
                    if (text !== null && text !== undefined) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }

        if (props.title === titleWordKnit) {
            setTimeout(() => {
                getWordKnitResult(false).then((text) => {
                    if (text) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }

        if (props.title === titleWordAlike) {
            setTimeout(() => {
                getWordAlikeResult(false).then((text) => {
                    if (text) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }

        if (props.title === titleWordSearch) {
            setTimeout(() => {
                getWordSearchResult(false).then((text) => {
                    if (text) {
                        setGameOverText(text);
                    }
                });
            }, 100);
        }
        
        dailyWordPuzzleDataAPI.isAllGameOver().then((required_result:boolean)=>setIsAllGameOver(required_result))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeGameOver = () => {
        if (props.onClose) {
            props.onClose();
            setGameOverText("");
        }
        setIsGameOver(false);
    };

    const onShareBtnClick = () => {
        addToast(TOASTVARIANT.NOICON, "Copied to clipboard");
        if (props.title === titleWordChain) {
            getWordChainResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "wordchain",
                value: 1,
            });
        }
        if (props.title === titleWordHunt) {
            getWordHuntResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "wordhunt",
                value: 1,
            });
        }
        if (props.title === titleAssembler) {
            getAssemblerResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "assembler",
                value: 1,
            });
        }
        if (props.title === titleWordKnit) {
            getWordKnitResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "wordknit",
                value: 1,
            });
        }
        if (props.title === titleWordSpider) {
            getWordSpiderResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "wordspider",
                value: 1,
            });
        }
        if (props.title === titleWordAlike) {
            getWordAlikeResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "wordalike",
                value: 1,
            });
        }
        if (props.title === titleWordSearch) {
            getWordSearchResult(true);
            ReactGA.event({
                category: "share",
                action: "scoreshare",
                label: "wordsearch",
                value: 1,
            });
        }
    };

    const renderText = () => {
        return (
            <div className={styles.text + ` text-[15px]`}>
                {gameOverText.split("\n").map((item, idx) => {
                    return (
                        <p className={styles.msgtext} key={idx}>
                            {item}
                        </p>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            {isGameOver && (
                <Modal>
                    <Toast toast={[...toast]} />
                    <div className={styles.mainContainer}>
                        <img
                            src={CrossIcon}
                            alt="crossicon"
                            className="absolute top-1 right-1 cursor-pointer rounded-2xl hover:shadow-xl"
                            onClick={()=>{
                                props.handleGameOverPopup();
                                SndMngr.playSound(SoundType.CLICKBTN);
                            }}
                        />
                        <p className="text-[22px] font-secular font-bold tracking-[2px] pb-4">{kGameOver}</p>
                        <p className={styles.text}>
                            {kScore}
                            {props.gameScore}
                        </p>
                        {renderText()}
                        <div className="flex flex-col gap-y-1">
                            <WarningButton
                                title={isAllGameOver ? kContinue : kNextGame}
                                clicked={() => {
                                    SndMngr.playSound(SoundType.CLICKBTN);
                                    closeGameOver();
                                }}
                                style={` text-[20px] font-medium  font-secular tracking-[2px] place-content-center w-[220px]`}
                            />

                            <SuccessButton
                                title={kShare}
                                clicked={() => {
                                    SndMngr.playSound(SoundType.CLICKBTN);
                                    onShareBtnClick();
                                }}
                                icon={<img src={ShareIcon} className={` w-5 stroke-white self-center`} alt="Share" />}
                                style={` text-[20px] font-medium  font-secular tracking-[2px] w-[220px]`}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
export default GameOver;

const styles = {
    mainContainer:
        "flex flex-col w-96 bg-secondary-150 rounded-lg p-12 place-items-center  shadow-[4px_5px_6px_0px_rgba(0,0,0,0.5)] relative",
    text: " text-[22px] font-roboto font-medium tracking-[2px] self-center pb-8",
    msgtext: "font-roboto text-[15px] font-normal",
};
