import { ReactNode } from "react";
import { ConvertStyle } from "../../Utils/ConvertStyle";

export const PrimaryButton = (props: {
    clicked: () => void;
    title: string;
    icon?: ReactNode;
    style?: string;
}) => {
    return (
        <button
            onClick={props.clicked}
            className={ConvertStyle([style.primary, props.style ?? ""])}
        >
            {props.title}
            {props.icon}
        </button>
    );
};
const style = {
    primary:
        "flex self-center place-content-center py-2 px-6 gap-1 bg-primary-400 hover:bg-primary-700 text-xl text-white font-montserrat border rounded-lg ",
};
