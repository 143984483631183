import React from "react";
import { RJWordSearchLineProps } from "./Types";

const LineSvg: React.FunctionComponent<RJWordSearchLineProps> = (props) => {
    const getLineDrawableFormat = (): Array<string> => {
        let points: Array<string> = [];
        points.push(`${props.line.from.x},${props.line.from.y}`);
        points.push(`${props.line.to.x},${props.line.to.y}`);
        return points;
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={props.style}
            fill="none"
            stroke="currentColor"
            strokeWidth={props.width}
            strokeLinecap="round"
        >
            <polyline points={getLineDrawableFormat().join(" ")} />
        </svg>
    );
};
export default LineSvg;
