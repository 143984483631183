import { PropsWithChildren, useEffect, useRef, useState } from "react";
import lclStorage from "../../../Utils/LocalStorage";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import { dailyWordPuzzleDataAPI } from "../../Introduction/api/DailyWordPuzzleDataAPI";
import {
    buildBlock,
    getAdjacentBlocks,
    getMergedWord,
    isBlockAtOriginalPosition,
    mergeBlocks,
    moveBlockBy,
} from "../Utils/BlockBuilder";
import { WORDKNIT_FIN_GAME_DATA, TILE_EVENTS, SCRAMBLED_BOARD_SIZE, WORDKNIT_INP_GAME_DATA } from "../Utils/constant";
import {
    BlockDataType,
    WordKnitServerDataType,
    GameOverWordKnitComplDataType,
    TempWordKnitDataType,
    TileBorderType,
    TileDataType,
    TileId,
    tileCmd,
    uiHandlerFun,
} from "../Utils/type";
import { Tile } from "./Tile";
import ReactGA from "react-ga4";

export const BOARD_MAX = 15;
export const NUMBER_OF_BLOCK = 10;
const EACH_TILE_SCORE = 5;

let UI_TILE_HANDLER: { [tileID: number]: uiHandlerFun } = {};

let WORDS_FOUND: string[] = [];

export const ScrambledBoard: React.FC<
    PropsWithChildren<{
        updateGameOverData: (game_cmp_data: GameOverWordKnitComplDataType) => void;
    }>
> = ({ updateGameOverData }) => {
    const [blockInfo, setBlockInfo] = useState<BlockDataType[]>([]);
    const boardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onWordKnitInit = async () => {
            //reset move handler
            UI_TILE_HANDLER = {};

            let block_info: BlockDataType[] = [];
            let words_found: string[] = [];

            const completed_data = await lclStorage.getItem(WORDKNIT_FIN_GAME_DATA);
            if (completed_data) {
                ReactGA.event({
                    category: "game_over",
                    action: "revisited",
                    label: "wordknit",
                    value: 1,
                });
                let parse_cmpleted_data: GameOverWordKnitComplDataType = JSON.parse(completed_data);

                block_info = parse_cmpleted_data.blockInfo;
                words_found = parse_cmpleted_data.wordsFound;
                updateGameOverData(parse_cmpleted_data);
            } else {
                const temp_data = await lclStorage.getItem(WORDKNIT_INP_GAME_DATA);
                if (temp_data) {
                    ReactGA.event({
                        category: "game_start",
                        action: "resume",
                        label: "wordknit",
                        value: 1,
                    });
                    let parse_temp_data: TempWordKnitDataType = JSON.parse(temp_data);
                    block_info = parse_temp_data.blockInfo;
                    words_found = parse_temp_data.wordsFound;
                } else {
                    ReactGA.event({
                        category: "game_start",
                        action: "new_game",
                        label: "wordknit",
                        value: 1,
                    });
                    //api call
                    const server_data: WordKnitServerDataType = dailyWordPuzzleDataAPI.getWordKnitData()!;

                    //build block
                    block_info = buildBlock(server_data, NUMBER_OF_BLOCK, BOARD_MAX).scrambledBlock
                }
            }
            WORDS_FOUND = words_found;
            setBlockInfo(block_info);
        };
        onWordKnitInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (blockInfo.length) {
            //game over condition
            if (blockInfo.length === 1) {
                ReactGA.event({
                    category: "game_over",
                    action: "finished_game",
                    label: "wordknit",
                    value: 1,
                });
                const cmp_data: GameOverWordKnitComplDataType = setGameCompletedDataToLclStg(blockInfo);
                lclStorage.resetItem(WORDKNIT_INP_GAME_DATA);
                updateGameOverData(cmp_data);
            } else {
                //temp save data
                setTempDataToLclStg(blockInfo);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockInfo]);

    const setGameCompletedDataToLclStg = (block_info: BlockDataType[]): GameOverWordKnitComplDataType => {
        const cmplt_data: GameOverWordKnitComplDataType = {
            blockInfo: block_info,
            isGameOver: true,
            score: getScore(block_info),
            wordsFound: WORDS_FOUND,
            tstmp: Math.floor(Date.now() / 1000),
        };
        lclStorage.setItem(WORDKNIT_FIN_GAME_DATA, JSON.stringify(cmplt_data));
        return cmplt_data;
    };

    const setTempDataToLclStg = (block_info: BlockDataType[]) => {
        const temp_data: TempWordKnitDataType = {
            blockInfo: block_info,
            wordsFound: WORDS_FOUND,
            tstmp: Math.floor(Date.now() / 1000),
        };
        lclStorage.setItem(WORDKNIT_INP_GAME_DATA, JSON.stringify(temp_data));
    };

    const parentFunTriggerFrmChild = (cmdRelay: tileCmd) => {
        let { action, tileId, uiHandlerFun, offset, movement } = cmdRelay;

        let curr_block: BlockDataType = fetchBlkInfoUsingTileID(tileId);

        //update handler if not present
        if (action === TILE_EVENTS.EVT_ON_INIT && !UI_TILE_HANDLER[tileId]) {
            if (uiHandlerFun) {
                UI_TILE_HANDLER[tileId] = uiHandlerFun;
            }
        }
        //update any offset if present
        if (action === TILE_EVENTS.EVT_ON_DRAG && offset) {
            updatePosOfBlock(curr_block, offset);
        }
        //handle drag event completed with displacement in pix
        if (action === TILE_EVENTS.EVT_ON_DRAG_END && movement) {
            onBlockDragCmpt(curr_block, getBlockDisplacement(movement));
        }
    };

    const onBlockDragCmpt = (curr_block: BlockDataType, displacement: number[]) => {
        let is_blk_movable: boolean = false;
        let is_blk_megable: boolean = false;
        let updated_blk_info: BlockDataType[] = blockInfo;

        if (displacement[0] || displacement[1]) {
            //try to move block
            const move_info: {
                updated: boolean;
                blocks: BlockDataType[];
            } = moveBlockBy(blockInfo, BOARD_MAX, curr_block.bid, displacement);
            is_blk_movable = move_info.updated;

            //if block successfully move
            if (is_blk_movable) {
                updated_blk_info = move_info.blocks;
                let curr_blk_id: number = curr_block.bid;

                getAdjacentBlocks(move_info.blocks, BOARD_MAX, curr_blk_id).forEach((blk_id) => {
                    let merge_info = mergeBlocks(updated_blk_info, BOARD_MAX, curr_blk_id, blk_id, [0, 0]);
                    if (merge_info.updated) {
                        WORDS_FOUND.push(...getMergedWord(updated_blk_info, curr_blk_id, blk_id, BOARD_MAX));
                        is_blk_megable = true;
                        updated_blk_info = merge_info.blocks;
                        curr_blk_id = blk_id;
                    }
                });
            }
        }

        if (is_blk_megable || is_blk_movable) {
            deleteTileMoveHandler(curr_block);
            setBlockInfo(updated_blk_info);
            if (is_blk_megable) {
                SndMngr.playSound(SoundType.SHUFFLE);
            }
        } else {
            //reset position of blk
            updatePosOfBlock(curr_block, [0, 0]);
        }
    };

    const fetchBlkInfoUsingTileID = (tileID: TileId): BlockDataType => {
        let single_blk: BlockDataType;
        single_blk = blockInfo.find(({ blk }) => blk.find(({ id }) => id === tileID))!;
        if (single_blk) {
            single_blk = JSON.parse(JSON.stringify(single_blk));
        }
        return single_blk;
    };

    const updatePosOfBlock = (curr_block: BlockDataType, position: number[]) => {
        const tiles_id: number[] | undefined = curr_block.blk.map(({ id }) => id);
        if (tiles_id) {
            tiles_id.forEach((id) => {
                const tileUIHandler: uiHandlerFun = UI_TILE_HANDLER[id];
                const cmd_relay: tileCmd = {
                    action: TILE_EVENTS.EVT_ON_DRAG,
                    tileId: id,
                    offset: [position?.[0], position?.[1]],
                };
                tileUIHandler(cmd_relay);
            });
        }
    };

    const deleteTileMoveHandler = (block_info: BlockDataType) => {
        const tile_ids: number[] | undefined = block_info.blk.map(({ id }) => id);
        if (tile_ids) {
            tile_ids.forEach((id) => {
                delete UI_TILE_HANDLER[id];
            });
        }
    };

    const getScore = (block_info: BlockDataType[]): number => {
        let score = 0;
        block_info.forEach(({ merged }) => {
            if (merged) {
                merged.forEach((tile_ids) => {
                    score = score + tile_ids.filter((tile_id) => tile_id).length;
                });
            }
        });
        return score * EACH_TILE_SCORE;
    };

    const getBlockDisplacement = (distanceCovered: number[]): number[] => {
        let displacement: number[] = [0, 0];
        const size_of_tile = getLengthOfSingleTile();
        let y_displace: number = Math.round(distanceCovered[0] / size_of_tile);
        let x_displace: number = Math.round(distanceCovered[1] / size_of_tile);
        if ((x_displace && y_displace === 0) || (y_displace && x_displace === 0) || (x_displace && y_displace)) {
            displacement = [x_displace, y_displace];
        }
        return displacement;
    };

    const getLengthOfSingleTile = (): number => {
        let tile_len: number = 0;
        const board_measurement = boardRef?.current?.getBoundingClientRect();
        if (board_measurement?.width) {
            tile_len = board_measurement.width / BOARD_MAX;
        }
        return tile_len;
    };

    const getBorderOfTiles = (
        tiles: TileDataType[],
        curr_tile_id: number,
        curr_tile_row: number,
        curr_tile_col: number,
        merged_tiles?: number[][]
    ): TileBorderType => {
        let border_details: TileBorderType = {
            left: false,
            right: false,
            top: false,
            bottom: false,
        };
        const tile_grp: number[] | undefined = merged_tiles?.find((id_grp) => id_grp.includes(curr_tile_id));

        const left_tile = tiles.find(
            (temp_tile) => temp_tile.id !== curr_tile_id && curr_tile_row === temp_tile.rw && curr_tile_col - 1 === temp_tile.cl
        );
        const right_tile = tiles.find(
            (temp_tile) => temp_tile.id !== curr_tile_id && curr_tile_row === temp_tile.rw && curr_tile_col + 1 === temp_tile.cl
        );
        const top_tile = tiles.find(
            (temp_tile) => temp_tile.id !== curr_tile_id && curr_tile_col === temp_tile.cl && curr_tile_row - 1 === temp_tile.rw
        );
        const bottom_tile = tiles.find(
            (temp_tile) => temp_tile.id !== curr_tile_id && curr_tile_col === temp_tile.cl && curr_tile_row + 1 === temp_tile.rw
        );
        if (!left_tile) {
            border_details.left = true;
        } else if (tile_grp && !tile_grp.includes(left_tile.id)) {
            border_details.left = true;
        }

        if (!right_tile) {
            border_details.right = true;
        } else if (tile_grp && !tile_grp.includes(right_tile.id)) {
            border_details.right = true;
        }

        if (!top_tile) {
            border_details.top = true;
        } else if (tile_grp && !tile_grp.includes(top_tile.id)) {
            border_details.top = true;
        }

        if (!bottom_tile) {
            border_details.bottom = true;
        } else if (tile_grp && !tile_grp.includes(bottom_tile.id)) {
            border_details.bottom = true;
        }

        return border_details;
    };

    const renderTiles = (): JSX.Element[][] => {
        let tiles: JSX.Element[][] = Array(BOARD_MAX)
            .fill("")
            .map((val, row) =>
                Array(BOARD_MAX)
                    .fill("")
                    .map((ele, col) => <div key={`${row}-${col}`} className="aspect-square border border-transparent"></div>)
            );

        //injecting tile from boardInfo
        blockInfo.forEach(({ blk, bid, merged }) => {
            blk.forEach(({ rw, cl, ch, id }) => {
                const tile_border_details: TileBorderType = getBorderOfTiles(blk, id, rw, cl, merged);
                tiles[rw][cl] = (
                    <div key={`${rw}-${cl}-${ch}-${id}`} className={`aspect-square`}>
                        <Tile
                            letter={ch}
                            tileID={id}
                            blockID={bid}
                            cmdHandler={parentFunTriggerFrmChild}
                            renderBorder={tile_border_details}
                            isOrgPos={isBlockAtOriginalPosition(blockInfo, bid)}
                        />
                    </div>
                );
            });
        });
        return tiles;
    };

    return (
        <div
            className={`w-full h-full ${SCRAMBLED_BOARD_SIZE.maxHeight} ${SCRAMBLED_BOARD_SIZE.minHeight} ${SCRAMBLED_BOARD_SIZE.maxWidth} ${SCRAMBLED_BOARD_SIZE.minWidth}`}
            ref={boardRef}
        >
            <div
                className="bg-contain bg-[url('./Pages/WordKnit/ScrambledBoard/ScrambledBoard.svg')]"
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${BOARD_MAX}, minmax(0, 1fr)`,
                    gridAutoFlow: "row",
                }}
            >
                {renderTiles()}
            </div>
        </div>
    );
};
