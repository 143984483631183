export const ConvertStyle = (
    style: { [key: string]: string } | Array<string>
): string => {
    if (style instanceof Array) {
        return style.join(" ");
    } else {
        let styles: Array<string> = [];
        for (let key in style) {
            styles.push(style[key]);
        }
        return styles.join(" ");
    }
};
