export const titleWordSpider = "Word Spider";
export const InitialTilesUSUK =
   'AAAAAAAABBCCDDDEEEEEEEEEEEFFGGHHIIIIIIIIJKLLLMMNNNNNOOOOOOOPPQRRRRRSSSTTTTTUUUVVWWXYYYZ';
export const ENGLISH_LETTER_SCORES =
'A,1|B,4|C,4|D,2|E,1|F,5|G,2|H,5|I,1|J,8|K,6|L,1|M,4|N,1|O,1|P,4|Q,12|R,1|S,1|T,2|U,1|V,5|W,5|X,8|Y,5|Z,12'; 
export const TOTAL_COLUMN = 7;
export const TOTAL_ROWS = 5;
export const SUFFLE_PENALTY_POINT = 5;
export const WrdMsg = "Create meaningful word!!";  
export const SHOW_TUTORIAL_ONCE = "SHOW_TUTORIAL_ONCE";
export const WORDSPIDER_INP_GAME_DATA = "wordspider_inp_game_data";
export const WORDSPIDER_FIN_GAME_DATA = "wordspider_fin_game_data";
export const kCompleted = "Completed!";

//-------------------------- PATHS ----------------------------

export const pathWordSpider = "/Pages/WordSpider";

//---------------------------TUTORIAL ------------------------
export const wrdSpiderTutorial1 =
    "Select tiles from top row and create meaningful word";
export const wrdSpiderTutorial2 = "Deselect your selection to delete the wrong word.";
export const wrdSpiderTutorial3 = "Submit your word by pressing the submit button.";
export const wrdSpiderTutorial4 = "Shuffle the existing column of words with this button.";