import { SVGPropType } from "../../Commons/types";
import Bulb from "../../Assets/svg/Bulb.svg";

const BlupIcon = (props: SVGPropType) => {
    return (
        // <div
        //     className={`absolute right-0 transition ease-in-out ${
        //         props.ntfNumber ?? -1 > 0
        //             ? "hover:scale-110 hover:-translate-y-1"
        //             : ""
        //     } `}
        // >
        //     <div
        //         className={`flex w-[15px] h-[15px] absolute bg-[#1F508F] rounded-full justify-center items-center right-[7px] top-[7px]`}
        //     >
        //         <p
        //             className={` text-white font-montserrat  text-[10px] font-bold`}
        //         >
        //             {props.ntfNumber}
        //         </p>
        //     </div>
        <img src={Bulb} className={`${props.style} `} onClick={props.clicked} alt="blupIcon" />
        // </div>
    );
};
export default BlupIcon;
