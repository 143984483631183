import produce from "immer";
import { useEffect, useState } from "react";
import { SndMngr, SoundType } from "../../../Utils/SoundManager";
import {
    HintType,
    // TimerPropType,
    ColumnType,
    MovementType,
    AssemblerFinGameType,
    AssemblerInpGameType,
    AssemblerColumnType,
} from "../../../Commons/types";
import {
    ASSEMBLER_INP_GAME_DATA,
    ASSEMBLER_FIN_GAME_DATA,
} from "../../../Commons/constant";
import lclStorage from "../../../Utils/LocalStorage";
import { dailyWordPuzzleDataAPI } from "../../Introduction/api/DailyWordPuzzleDataAPI";
import { AssemblerColumn } from "./AssemblerColumn";

//-------------------------------------------------------------
export enum MEASUREMENT {
    FIXEDPOSITION = 56,
}
const SCORE_FOR_EACH_LETTER = 5;
//-------------------------------------------------------------

let DEFAULT_LIST_POSITION: MovementType = [];
let ACTIVE_LETTERS: string[] = [];

const ColumnContainer = (props: {
    showHint: HintType;
    words: string[];
    scoreController: (score: number) => void;
    score: number;
    doGameOver: (isGameOver:boolean)=>void;
    isGameOver: boolean;
    onHintClicked: boolean;
    // isTimeEnd: boolean;
    // timer: TimerPropType;
    // remainingTime: TimerPropType;
    getColorCode: (
        Data: Array<Array<{ letter: string; colorCode: string }>>
    ) => ColumnType;
}) => {
    let [columns, setColumns] = useState<ColumnType>([]);
    let [wordsFound, setWordsFound] = useState<Array<string>>([]);

    useEffect(() => {
        const onListOfWordInit = async () => {
            let columns: ColumnType = [];
            let words_found: string[] = [];
            const completed_data = await lclStorage.getItem(
                ASSEMBLER_FIN_GAME_DATA
            );

            if (completed_data) {
                let gameCmpltdData: AssemblerFinGameType =
                    JSON.parse(completed_data);
                if (gameCmpltdData.isGameOver) {
                    words_found = gameCmpltdData.data.wordsFound;
                    columns = props.getColorCode(
                        gameCmpltdData.data.wordsState
                    );
                }
            } else {
                const temp_data = await lclStorage.getItem(ASSEMBLER_INP_GAME_DATA);
                if (temp_data) {
                    let assemblerData: AssemblerInpGameType =
                        JSON.parse(temp_data);
                    words_found = assemblerData.wordsFound;
                    columns = assemblerData.listOfWords;
                    // DEFAULT_LIST_POSITION = assemblerData.defaultlistPosition;
                    // ACTIVE_LETTERS = assemblerData.activeLetters;
                } else {
                    //for first time setup
                    const col_len = props.words[0]?.split("").length;
                    let max_row_len: number = 0;
                    for (let col = 0; col < col_len; col++) {
                        let temp_arr: AssemblerColumnType = [];
                        props.words.forEach((word) => {
                            const lett = word.split("")[col];
                            const isLetterPresent = temp_arr.find(
                                ({ letter, color }) => lett === letter
                            );
                            if (!isLetterPresent) {
                                temp_arr.push({
                                    letter: lett,
                                    color: "bg-danger-900",
                                });
                            }
                        });
                        if (temp_arr.length > max_row_len) {
                            max_row_len = temp_arr.length;
                        }
                        // addExtraSpace(temp_arr);
                        columns.push(temp_arr);
                    }
                }
            }

            setColumns(columns);
            setWordsFound(words_found);
            if (!DEFAULT_LIST_POSITION.length) {
                setDefaultPosition(columns);
            }
            if (!ACTIVE_LETTERS.length) {
                if (words_found.length) {
                    ACTIVE_LETTERS = words_found[words_found.length - 1].trim().split("")
                } else {
                    const index_of_invalid_letters: number[] = getInvalidLettersIndex(columns);
                    index_of_invalid_letters.forEach((letter_index, index) => {
                        let pos: number = DEFAULT_LIST_POSITION[0].lowerBound - letter_index * MEASUREMENT.FIXEDPOSITION;

                        //setting default list position
                        DEFAULT_LIST_POSITION[index].defaultPosition = pos;

                        //setting active letter position
                        ACTIVE_LETTERS.push(columns[index][letter_index].letter);
                    });
                }
            }

            //start movement ....
            let fnd_word = words_found.length
                ? words_found[words_found.length - 1].split("")
                : ACTIVE_LETTERS;

            const index_of_letters = fnd_word.map((lett, index) =>
                columns[index].findIndex(({ letter }) => letter === lett)
            );
            index_of_letters.forEach((item, index) => {
                let pos =
                    DEFAULT_LIST_POSITION[0].lowerBound -
                    item * MEASUREMENT.FIXEDPOSITION;
                DEFAULT_LIST_POSITION[index].defaultPosition = pos;
            });
        };
        onListOfWordInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.onHintClicked) {
            const not_selected_word = getNotSelectedValidWord();
            if (not_selected_word) {
                const index_of_letters = not_selected_word
                    .split("")
                    .map((lett, index) =>
                        columns[index].findIndex(
                            ({ letter }) => letter === lett
                        )
                    );

                index_of_letters.forEach((item, index) => {
                    let pos =
                        DEFAULT_LIST_POSITION[0].lowerBound -
                        item * MEASUREMENT.FIXEDPOSITION;
                    DEFAULT_LIST_POSITION[index].defaultPosition = pos;
                    setActiveLetter(index, pos);
                    if (checkSelectedLetters()) {
                        setTimeout(() => {
                            updateLetterBoxColor();
                        }, 1000);
                    }
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onHintClicked]);

    //update score and timer comming from parent component
    useEffect(() => {
        //checking all letter seleted and update score
        let total_score = 0;
        let isAllLetterSelected: boolean[] = [];
        columns.forEach((column) => {
            column.forEach(({ color }) => {
                if (color.includes("warning-400")) {
                    isAllLetterSelected.push(true);
                    total_score += SCORE_FOR_EACH_LETTER;
                } else {
                    isAllLetterSelected.push(false);
                }
            });
        });
        props.scoreController(total_score);
        const isAnyNotSelected = isAllLetterSelected.find(
            (isSelected) => isSelected === false
        );
        if (
            isAnyNotSelected === undefined &&
            isAllLetterSelected.length &&
            !props.isGameOver
        ) {
            props.doGameOver(true);
            savedGameOverData(columns, props.showHint, total_score);
            lclStorage.resetItem(ASSEMBLER_INP_GAME_DATA);
        } else {
            //setting local storage
            if (columns.length && !props.isGameOver) {
                setAsseblerInpDataToLclStg();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    const savedGameOverData = (
        columns: ColumnType,
        hint_data: HintType,
        score: number
    ) => {
        let _columns = [];
        for (let column of columns) {
            let gData = [];
            for (let ele of column) {
                let codeType = "NFD";
                if (ele.color === "bg-warning-400") {
                    codeType = "FD";
                }
                gData.push({
                    letter: ele.letter,
                    colorCode: codeType,
                });
            }
            _columns.push(gData);
        }

        let gameData: AssemblerFinGameType = {
            isGameOver: true,
            data: {
                wordsState: _columns,
                hintData: hint_data,
                // initialTime: props.timer,
                // cmpltdTime: props.remainingTime,
                score: score,
                wordsFound: wordsFound,
                tstmp: Math.floor(Date.now() / 1000)
            },
        };
        lclStorage.setItem(ASSEMBLER_FIN_GAME_DATA, JSON.stringify(gameData));
    };

    const setAsseblerInpDataToLclStg = () => {
        // let remaining_time =
        //     props.remainingTime.hr * 3600 +
        //     props.remainingTime.min * 60 +
        //     props.remainingTime.sec;
        // if (remaining_time) {
        let temp_data: AssemblerInpGameType = {
            // timer: remaining_time,
            // defaultlistPosition: DEFAULT_LIST_POSITION,
            listOfWords: columns,
            wordsFound: wordsFound,
            // activeLetters: ACTIVE_LETTERS,
            tstmp: Math.floor(Date.now() / 1000)
        };
        lclStorage.setItem(ASSEMBLER_INP_GAME_DATA, JSON.stringify(temp_data));
        // }
    };

    // useEffect(() => {
    //     if (wordsState.length > 0) {
    //         savedGameOverData(wordsState);
    //         lclStorage.resetItem(ASSEMBLER_INP_GAME_DATA);
    //     }
    // }, [props.isTimeEnd]);

    const getNotSelectedValidWord = (): string | undefined => {
        let not_selected_word: string | undefined;
        props.words.some((word) => {
            word.split("").some((lett, index) => {
                const is_found = columns[index]?.find(
                    ({ letter, color }) =>
                        letter === lett && !color.includes("bg-warning-400")
                );
                return is_found ? (not_selected_word = word) : null;
            });
            return not_selected_word;
        });
        return not_selected_word;
    };

    const getInvalidLettersIndex = (columns: ColumnType): number[] => {
        let position: number[] = [];

        //selecting random letter
        columns.forEach((column) => {
            const random_number = Math.floor(Math.random() * column.length);
            position.push(random_number);
        });

        const invalid_word: string = position
            .map((position, index) => {
                return columns[index][position].letter;
            })
            .join();

        const is_valid_word: string | undefined = props.words.find(
            (word) => word === invalid_word
        );

        if (is_valid_word) {
            return getInvalidLettersIndex(columns);
        } else {
            return position;
        }
    };

    const updateLetterBoxColor = () => {
        setColumns(
            produce((columnContainer) => {
                columnContainer.forEach((word, index) => {
                    let letter_postion = word.findIndex(
                        ({ letter }) => letter === ACTIVE_LETTERS[index]
                    );
                    word[letter_postion].color = "bg-warning-400";
                });
            })
        );
        SndMngr.playSound(SoundType.USERWINGAME);
    };

    const setActiveLetter = (index: number, position: number) => {
        //calculate active letter position
        const max_word_list_size = columns.reduce(
            (total, currValue) =>
                total < currValue.length ? currValue.length : total,
            0
        );
        let active_position =
            (Math.round(max_word_list_size / 2) - 1) *
            MEASUREMENT.FIXEDPOSITION;

        let box_moved = 0;
        let curr_distance = position;
        if (curr_distance > active_position) {
            while (curr_distance > active_position) {
                curr_distance = curr_distance - MEASUREMENT.FIXEDPOSITION;
                box_moved++;
            }
        } else if (curr_distance < active_position) {
            while (curr_distance < active_position) {
                curr_distance = curr_distance + MEASUREMENT.FIXEDPOSITION;
                box_moved++;
            }
        }

        let column = columns[index];
        let selected_lettter = column[box_moved];
        ACTIVE_LETTERS[index] = selected_lettter?.letter;
    };

    const checkSelectedLetters = (): boolean => {
        let active_word = ACTIVE_LETTERS.join("");
        let is_active_word = dailyWordPuzzleDataAPI.isWordValidate(active_word);
        setWordsFound(
            produce((words_found) => {
                if (
                    is_active_word &&
                    !words_found.find((word) => word === active_word)
                ) {
                    words_found.push(active_word);
                }
            })
        );
        return is_active_word ? true : false;
    };

    const onDragComplete = (column_index: number, current_position: number) => {
        setActiveLetter(column_index, current_position);
        if (checkSelectedLetters()) {
            updateLetterBoxColor();
        }
    };

    const setDefaultPosition = (columns: ColumnType) => {
        let max_length_arr = columns.reduce(
            (total, currValue) =>
                total < currValue.length ? currValue.length : total,
            0
        );
        let mid_point_max_length =
            max_length_arr % 2 === 0
                ? Math.floor(max_length_arr / 2)
                : Math.floor(max_length_arr / 2) + 1;
        columns.forEach((column) => {
            //calculate starting position
            let defaultPosition = 0;
            if (max_length_arr !== column.length) {
                let box_to_cover =
                    mid_point_max_length -
                    (column.length - Math.floor(column.length / 2));

                while (box_to_cover !== 0) {
                    defaultPosition =
                        defaultPosition + MEASUREMENT.FIXEDPOSITION;
                    box_to_cover--;
                }
            }

            //calculate maxPos and minPos
            let upperBound =
                column.length % 2 !== 0
                    ? (column.length - 1) / 2
                    : column.length / 2;
            let lowerBound =
                column.length % 2 !== 0
                    ? (column.length - 1) / 2
                    : column.length - (column.length / 2 + 1);

            upperBound =
                defaultPosition - upperBound * MEASUREMENT.FIXEDPOSITION;
            lowerBound =
                defaultPosition + lowerBound * MEASUREMENT.FIXEDPOSITION;

            DEFAULT_LIST_POSITION.push({
                upperBound,
                lowerBound,
                defaultPosition,
            });
        });
    };

    const renderSelector = () => {
        const width: number = columns.length * MEASUREMENT.FIXEDPOSITION + 20;
        const max_list_length: number = columns.reduce(
            (total, currValue) =>
                total < currValue.length ? currValue.length : total,
            0
        );
        let top_position: number =
            (Math.round(max_list_length / 2) - 1.25) *
            MEASUREMENT.FIXEDPOSITION;
        return (
            <div
                className={`flex absolute `}
                style={{ columnGap: `${width}px`, top: `${top_position}px` }}
            >
                <p className={style.vertbar}>|</p>
                <p className={style.vertbar}>|</p>
            </div>
        );
    };

    const renderColumns = (): JSX.Element => {
        return (
            <>
                {columns.map((column, index) => (
                    <AssemblerColumn
                        column={column}
                        key={index}
                        columnIndex={index}
                        upperBound={DEFAULT_LIST_POSITION[index]?.upperBound}
                        lowerBound={DEFAULT_LIST_POSITION[index]?.lowerBound}
                        defaultPosition={
                            DEFAULT_LIST_POSITION[index]?.defaultPosition
                        }
                        onDragComplete={onDragComplete}
                    />
                ))}
            </>
        );
    };

    return (
        <div className="flex flex-row place-content-center gap-x-0.5 relative">
            {renderSelector()}
            {renderColumns()}
        </div>
    );
};

const style = {
    text: "text-secondary-200 text-center text-[50px]",
    vertbar: "text-[#626262] text-center text-[50px]",
};

export default ColumnContainer;
