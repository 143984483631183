import React from "react";
import { SVGPloyLinePropType } from "../../Commons/types";

const SVGPloyLine: React.FC<SVGPloyLinePropType> = (props) => {
    let points: Array<string> = [];
    props.points.forEach(({ x, y }) => {
        points.push(`${x},${y}`);
    });

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.style}
            fill="none"
            stroke="currentColor"
            strokeWidth={7}
            onClick={props.clicked}
        >
            <polyline points={points.join(" ")} />
        </svg>
    );
};
export default SVGPloyLine;
