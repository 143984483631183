export const titleWordAlike = "Word Alike";

export const WORD_ALIKE_BOARD_SIZE = {
    minWidth: "min-w-[100px]",
    minHeight: "min-h-[100px]",
    maxWidth: "max-w-[250px]",
    maxHeight: " max-h-[250px]",
};

export const CLUE_TITLE: string = "Find synonym of";

export const WORDALIKE_INP_GAME_DATA = "wordalike_inp_game_data";
export const WORDALIKE_FIN_GAME_DATA = "wordalike_fin_game_data";

export const pathWordAlike = "/Pages/WordAlike";

export const wordAlikeTutPage1Title = "Select tiles to create synonym of given word"
export const wordAlikeTutPage2Title = "Shuffle the tiles using this button"
export const wordAlikeTutPage3Title = "Hover over it to see which words have been made already"
