import { SpringValue, animated } from "react-spring";

export const WordAlikeTile = (props: {
    letter: string;
    isSelected: boolean;
    animationValue: { x: SpringValue<number>; y: SpringValue<number> };
    letterLeft: number;
}) => {
    return (
        <animated.div
            className={`z-10 flex aspect-square cursor-pointer touch-none flex-col items-center justify-center rounded ${
                props.letterLeft === 0 ? "bg-secondary-250" : props.isSelected ? "bg-info-400 " : "bg-primary-700"
            } relative`}
            style={props.animationValue}
            id={props.letter}
        >
            <p
                className={`text-center font-roboto text-[20px] font-medium uppercase leading-6 ${
                    props.letterLeft === 0 ? "text-secondary-750" : "text-secondary-75"
                }`}
                id={props.letter}
            >
                {props.letter}
            </p>
            <p
                className={`absolute top-0 right-1 font-roboto ${
                    props.letterLeft === 0 ? "text-secondary-750" : props.isSelected ? "text-primary-700" : "text-info-400 "
                }  text-center text-[10px] font-medium`}
                id={props.letter}
            >
                {props.letterLeft}
            </p>
        </animated.div>
    );
};
