import {
    kHelpDesk,
    // kHint,
    // kHome,
    // kInformation,
    // kStatistic,
    // pathIndexOfGame,
} from "../../Commons/constant";
// import { useNavigate } from "react-router-dom";
import { Modal } from "../Modal/Modal";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
// import { useState } from "react";

export const Menu = (props: {
    setShowMenu?: React.Dispatch<React.SetStateAction<boolean>>;
    setMenuIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    onMenuHintClicked?: () => void;
    onMenuStatsClicked?: () => void;
    onMenuHelpClicked?: () => void;
}) => {
    // const navigate = useNavigate();

    // const onHomeButtonClick = (data: string) => {
    //     SndMngr.playSound(SoundType.CLICKBTN);
    //     navigate(pathIndexOfGame);
    //     closeModal();
    // };

    // const onInfoButtonClick = (data: string) => {
    //     SndMngr.playSound(SoundType.CLICKBTN);
    //     if (props.setMenuIsLoading) {
    //         props.setMenuIsLoading(true);
    //     }
    //     closeModal();
    // };

    const onHelpButtonClick = (data: string) => {
        SndMngr.playSound(SoundType.CLICKBTN);
        if (props.onMenuHelpClicked) {
            props.onMenuHelpClicked();
        }
        closeModal();
    };

    // const onStatsButtonClick = (data: string) => {
    //     SndMngr.playSound(SoundType.CLICKBTN);
    //     if (props.onMenuStatsClicked) {
    //         props.onMenuStatsClicked();
    //     }
    //     closeModal();
    // };

    // const onHintButtonClick = (data: string) => {
    //     SndMngr.playSound(SoundType.CLICKBTN);
    //     if (props.onMenuHintClicked) {
    //         props.onMenuHintClicked();
    //     }
    //     closeModal();
    // };

    const closeModal = () => {
        if (props.setShowMenu) {
            props.setShowMenu(false);
        }
    };

    return (
        <Modal
            closeModal={closeModal}
            isMenuType={true}
            style={`absolute top-1 right-1`}
        >
            <div className={style.mainContainer}>
                {/* <div
                    onClick={() => {
                        onHomeButtonClick(kHome);
                    }}
                    className={style.eachButton}
                >
                    <p className={style.text + style.borderstyle}>{kHome}</p>
                </div>
                <div
                    onClick={() => {
                        onInfoButtonClick(kInformation);
                    }}
                    className={style.eachButton}
                >
                    <p className={style.text + style.borderstyle}>
                        {kInformation}
                    </p>
                </div> */}
                <div
                    onClick={() => {
                        onHelpButtonClick(kHelpDesk);
                    }}
                    className={style.eachButton}
                >
                    <p className={style.text }>
                        {kHelpDesk}
                    </p>
                </div>
                {/* <div
                    onClick={() => {
                        onStatsButtonClick(kStatistic);
                    }}
                    className={style.eachButton}
                >
                    <p className={style.text + style.borderstyle}>
                        {kStatistic}
                    </p>
                </div>
                <div
                    onClick={() => {
                        onHintButtonClick(kHint);
                    }}
                    className={style.eachButton}
                >
                    <p className={style.text}>{kHint}</p>
                </div> */}
            </div>
        </Modal>
    );
};
const style = {
    mainContainer:
        "flex flex-col bg-secondary-50 borde-b-2 rounded-b-md shadow-[-4px_5px_5px_0px_rgba(0,0,0,0.5)]",
    eachButton: "pl-4",
    text: "text-base font-roboto tracking-widest leading-5 font-medium text-right cursor-pointer  py-4 pr-5 pl-14 text-overlay-500",
    // borderstyle: " border-b-2 border-slate-500",
};
