export type RJWordSearchPoint = {
    x: number;
    y: number;
};

export type RJWordSearchGridPoint = {
    r: number;
    c: number;
};

export type RJWordSearchGridLine = {
    from: RJWordSearchGridPoint;
    to: RJWordSearchGridPoint;
};

export type RJWordSearchLine = {
    from: RJWordSearchPoint;
    to: RJWordSearchPoint;
};

export type RJWordSearchLineProps = {
    line: RJWordSearchLine;
    style: { [key: string]: string };
    width: number;
};

export type RJNotificationData = {
    action: RJNtfActions;
    data: RJNtfWordFoundData;
};

export type RJNtfWordFoundData = {
    from: RJWordSearchGridPoint;
    to: RJWordSearchGridPoint;
    word: string;
};

export type RJWordSearchBoardData = {
    board: Array<Array<string>>;
    valword: Array<string>;
    numbonus: number;
    linesOnBoard: Array<RJWordSearchGridLine>;
    wordsFound: Array<string>;
    score: number;
    gameOverReason: gameOverReason;
};

export type RJWordSearchCallBackHandler = {
    callBack: (data: RJNotificationData) => void;
};

export type RJWordSearchGameData = RJWordSearchBoardData;

export enum LineDirection {
    NONE,
    HORIZONTAL,
    VERTICAL,
    DIAGONAL,
}
export enum gameOverReason {
    NONE,
    NORMAL,
}

export enum RJNtfActions {
    WORDFOUND = "WORDFOUND",
}

export type RJWordSearchInpGameType = {
    linesOnBoard: Array<RJWordSearchGridLine>;
    wordsFound: Array<string>;
    score: number;
    gameOverReason: gameOverReason;
    tstmp: number;
};