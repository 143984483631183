import { kPlayGame, titleIntro, pathIndexOfGame } from "../../Commons/constant";
import { useNavigate } from "react-router-dom";
import { WarningButton } from "../../Component/Buttons/WarningButton";
import { SndMngr, SoundType } from "../../Utils/SoundManager";
import Header from "../../Component/Header/Header";
import SWG from "../../Assets/svg/SWG.svg";
import { dailyWordPuzzleDataAPI } from "../Introduction/api/DailyWordPuzzleDataAPI";
import { useState, useEffect } from "react";

export const Introduction = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        dailyWordPuzzleDataAPI.onAppInit().then(() => setLoading(false));
    }, []);

    const checkAlreadyPlayed = () => {
        navigate(pathIndexOfGame, { replace: true });
    };
    const renderLoading = () => {
        return (
            <div className={styles.subContainer}>
                {/* aspect-ratio-[688/630] derived from splash image viewBox */}
                <div className="flex justify-center items-center flex-grow aspect-[688/630]">
                    <img src={SWG} alt="SWG" />
                </div>
                <WarningButton
                    title={kPlayGame}
                    clicked={() => {}}
                    style={` text-[24px] font-medium font-secular tracking-[2px] mb-[20px] px-20`}
                />
            </div>
        );
    };
    const renderAfterLoading = () => {
        return (
            <div
                onClick={() => {
                    SndMngr.playSound(SoundType.CLICKBTN);
                    checkAlreadyPlayed();
                }}
                className={styles.subContainer}
            >
                {/* aspect-ratio-[688/630] derived from splash image viewBox */}
                <div className="flex justify-center items-center flex-grow aspect-[688/630]">
                    <img src={SWG} alt="SWG" />
                </div>
                <WarningButton
                    title={kPlayGame}
                    clicked={() => {}}
                    style={` text-[24px] font-medium font-secular tracking-[2px] mb-[20px] px-20`}
                />
            </div>
        );
    };

    return (
        <div className={styles.mainContainer}>
            <Header title={titleIntro} showOnlyTitle={true} />
            {loading ? renderLoading() : renderAfterLoading()}
        </div>
    );
};

const styles = {
    mainContainer: " flex flex-col w-full h-full",
    subContainer:
        "flex flex-col gap-y-8 h-full justify-center items-center m-2 overflow-hidden",
    text: "text-center text-dark ",
};
